import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Tooltip,
  CircularProgress,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
} from '@mui/material';
import { Interweave } from 'interweave';

import { Button } from 'src/components/Button';
import { Trans, useTranslation } from 'react-i18next';
import { BUTTON_VARIANT, BUTTON_SIZE, BUTTON_COLOR } from '../Button/types';
import { AVATAR_SHADOW, AVATAR_SIZE, AVATAR_VARIANT } from '../Avatar/types';
import { Avatar } from '../Avatar';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { ModalChatGPTProps } from './types';
import { convertTextToHTML } from 'src/utils/convertTextToHTML';

const ModalChatGPT: React.FC<ModalChatGPTProps> = ({
  shouldOpen,
  textContents,
  imageContents,
  handleClose,
  handleGeneration,
  textGenerationLoading,
  imageGenerationLoading,
}) => {
  const { t } = useTranslation();
  const [tooltipText, setTooltipText] = useState<string>(t('jobPage.GenerationModal.copy'));

  const htmlContent = textContents && convertTextToHTML(textContents);

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text).then(() => {
      setTooltipText(t('jobPage.GenerationModal.copied'));
      setTimeout(() => setTooltipText(t('jobPage.GenerationModal.copy')), 2000);
    });
  };

  return (
    <Box>
      <Grid item xs={12}>
        <Dialog
          open={shouldOpen}
          onClose={handleClose}
          fullWidth={true}
          keepMounted
          PaperProps={{
            style: {
              width: '100%',
              maxWidth: '750px',
              padding: '10px',
            },
          }}>
          <DialogTitle>
            <Box display="flex" justifyContent="center" sx={{ padding: '0.5rem' }}>
              {' '}
              <Typography fontWeight={TYPOGRAPHY_WEIGHT.REGULAR} sx={{ fontSize: 19 }}>
                <Trans>jobPage.GenerationModal.title</Trans>
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Card variant="outlined" sx={{ marginBottom: '20px' }}>
              <CardHeader sx={{ display: 'block', paddingLeft: '10px' }} />
              <CardContent>
                {textGenerationLoading ? (
                  <Box sx={{ display: 'block', marginBottom: '10px' }}>
                    <Typography
                      variant="overline"
                      sx={{ fontSize: 12 }}
                      fontWeight={TYPOGRAPHY_WEIGHT.LIGHT}
                      color={TYPOGRAPHY_COLORS.TEXT}>
                      <Trans>jobPage.GenerationModal.text.generating</Trans>
                      <CircularProgress size={12} color="inherit" />
                    </Typography>
                  </Box>
                ) : (
                  <Typography
                    variant="subtitle2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    sx={{ fontSize: 14 }}>
                    <Interweave content={htmlContent} allowList={['br']} />
                  </Typography>
                )}

                {imageGenerationLoading ? (
                  <Box sx={{ display: 'block', marginBottom: '10px' }}>
                    <Typography
                      variant="overline"
                      sx={{ fontSize: 12 }}
                      fontWeight={TYPOGRAPHY_WEIGHT.LIGHT}
                      color={TYPOGRAPHY_COLORS.TEXT}>
                      <Trans>jobPage.GenerationModal.image.generating</Trans>
                      <CircularProgress size={12} color="inherit" />
                    </Typography>
                  </Box>
                ) : (
                  <Grid item>
                    <Box mt={2} mb={1}>
                      {imageContents ? (
                        <Link>
                          <Tooltip
                            title={<Trans>jobPage.GenerationModal.image.higherResolution</Trans>}
                            placement="right">
                            <Avatar
                              alt="Generated image"
                              src={imageContents}
                              variant={AVATAR_VARIANT.ROUNDED}
                              size={AVATAR_SIZE.XXL}
                              shadow={AVATAR_SHADOW.SM}
                              onClick={() => {
                                window.open(imageContents, '_blank', 'noopener noreferrer');
                              }}
                              sx={{
                                cursor: 'pointer',
                                userSelect: 'none',
                                transition: 'transform 0.4s',
                                '&:hover': {
                                  transform: 'scale(1.04)',
                                },
                              }}
                            />
                          </Tooltip>
                        </Link>
                      ) : (
                        <Typography
                          variant="overline"
                          sx={{ fontSize: 14 }}
                          fontWeight={TYPOGRAPHY_WEIGHT.LIGHT}
                          color={TYPOGRAPHY_COLORS.TEXT}>
                          <Trans>jobPage.GenerationModal.image.empty</Trans>
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                )}
              </CardContent>

              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ padding: '0.5rem 1.5rem 1.5rem' }}>
                {textContents ? (
                  <Tooltip title={tooltipText} arrow>
                    <Button
                      variant={BUTTON_VARIANT.GRADIENT}
                      size={BUTTON_SIZE.SMALL}
                      color={BUTTON_COLOR.INFO}
                      onClick={() => handleCopy(textContents)}>
                      <Trans>jobPage.GenerationModal.copy</Trans>
                    </Button>
                  </Tooltip>
                ) : null}
              </Box>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              size={BUTTON_SIZE.SMALL}
              color={BUTTON_COLOR.INFO}
              onClick={handleGeneration}>
              {textGenerationLoading ? (
                <CircularProgress size={12} color="inherit" />
              ) : (
                <Trans>jobPage.GenerationModal.reGeneration</Trans>
              )}
            </Button>

            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              size={BUTTON_SIZE.SMALL}
              color={BUTTON_COLOR.INFO}
              onClick={handleClose}>
              <Trans>jobPage.GenerationModal.cancel</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
};

export default ModalChatGPT;
