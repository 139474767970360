import React from 'react';

// @mui material components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';

// Custom styles for the SidenavCard
import { card, cardContent, cardIconBox, cardIcon } from './styles/sideNavCard';

import { UIController } from 'src/context';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { Trans } from 'react-i18next';

function SidenavCard() {
  const [controller]: any = UIController();
  const { miniSidenav, sidenavColor } = controller;

  return (
    <Card sx={(theme: any) => card(theme, { miniSidenav })}>
      <CardContent sx={(theme: any): any => cardContent(theme, { sidenavColor })}>
        <Box
          bgColor="white"
          width="2rem"
          height="2rem"
          borderRadius="md"
          shadow="md"
          mb={2}
          sx={cardIconBox}>
          <Icon fontSize="medium" sx={theme => cardIcon(theme, { sidenavColor })}>
            star
          </Icon>
        </Box>
        <Box lineHeight={1}>
          <Typography variant="h6" color={TYPOGRAPHY_COLORS.WHITE}>
            <Trans>supportScreen.headerTitle</Trans>
          </Typography>
          <Box>
            <Typography
              sx={() => ({ whiteSpace: 'pre-line', letterSpacing: 'unset', marginTop: 10 })}
              variant="caption"
              color={TYPOGRAPHY_COLORS.WHITE}
              fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
              <Trans>supportScreen.helperText</Trans>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default SidenavCard;
