import React from 'react';
// @mui material components
import Grid from '@mui/material/Grid';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { PageLayout, PAGE_LAYOUT_BG } from 'src/components/layouts/LayoutContainers/PageLayout';

// Images
import pattern from 'src/assets/svg/pattern-lines.svg';

// Types
import { SignInLayoutProps, SIGN_IN_LAYOUT_COLORS } from './types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { BoxVariants } from '../Box/types';

function SigninLayout({
  color = SIGN_IN_LAYOUT_COLORS.INFO,
  header = '',
  title = '',
  description = '',
  illustration = {},
  children,
}: SignInLayoutProps) {
  return (
    <PageLayout background={PAGE_LAYOUT_BG.WHITE}>
      <Grid container height="100vh">
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={6}
          xl={4}
          sx={{
            mx: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box pt={3} px={3}>
              {!header ? (
                <>
                  <Box mb={1}>
                    <Typography variant="h4" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
                      {title}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.TEXT}>
                    {description}
                  </Typography>
                </>
              ) : (
                header
              )}
            </Box>
            <Box p={3}>{children}</Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            display={{ xs: 'none', lg: 'flex' }}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="calc(100vh - 2rem)"
            position="fixed"
            left="50%"
            right={0}
            borderRadius="lg"
            textAlign="center"
            bgColor={color}
            variant={BoxVariants.GRADIENT}
            m={2}
            px={13}
            sx={{ overflow: 'hidden' }}>
            <Box
              component="img"
              src={pattern}
              alt="pattern-lines"
              width="120rem"
              position="absolute"
              topl={0}
              left={0}
              opacity={0.4}
            />
            {illustration.image && (
              <Box
                component="img"
                src={illustration.image}
                alt="chat-illustration"
                width="100%"
                maxWidth="10rem"
              />
            )}
            {illustration.title && (
              <Box mt={6} mb={1}>
                <Typography
                  variant="h4"
                  color={TYPOGRAPHY_COLORS.WHITE}
                  fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
                  {illustration.title}
                </Typography>
              </Box>
            )}
            {illustration.description && (
              <Box mb={1}>
                <Typography variant="body2" color={TYPOGRAPHY_COLORS.WHITE}>
                  {illustration.description}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export { SigninLayout };
