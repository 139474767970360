import React from 'react';

// @mui material components
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';

import { Box } from 'src/components/Box';

// Custom styles for the SidenavItem
import { item, itemContent, itemArrow } from './styles/sideNavItem';

import { UIController } from 'src/context';
import { SideNavItemProps } from './types';

function SidenavItem({
  name,
  active = false,
  nested = false,
  children = false,
  open = false,
  ...rest
}: SideNavItemProps) {
  const [controller]: any = UIController();
  const { miniSidenav } = controller;

  return (
    <>
      <ListItem {...rest} component="li" sx={item}>
        <Box sx={(theme: any) => itemContent(theme, { active, miniSidenav, name, nested })}>
          <ListItemText primary={name} />
          {children && (
            <Icon component="i" sx={theme => itemArrow(theme, { open, miniSidenav })}>
              expand_less
            </Icon>
          )}
        </Box>
      </ListItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

export default SidenavItem;
