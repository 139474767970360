import { AxiosError } from 'axios';
import { useState } from 'react';

import { useLogout } from 'src/hooks/useLogout';
import { useAxios } from './useAxios';
import { UseApiProps, UseApiObjectProps } from './useApi.types';

export const useApi: UseApiProps = <ResponseDTO>(hookParams?: UseApiObjectProps<ResponseDTO>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>();
  const [response, setResponse] = useState<ResponseDTO | undefined>();

  const { logout } = useLogout();
  const { get, post } = useAxios();

  const getRequest = <T>(url: string, params?: T) => {
    setLoading(true);
    get<ResponseDTO>(url, params)
      .then(axiosResponse => {
        setResponse(axiosResponse.data);
        if (hookParams && hookParams.onComplete) {
          hookParams.onComplete(axiosResponse.data);
        }
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        if (hookParams && hookParams.onError) {
          hookParams.onError(err);
        }

        setLoading(false);

        if (err?.response?.data && typeof err.response.data === 'string') {
          const isTokenInvalid =
            err.response.data.includes('missing or invalid token') || err.response?.status === 401;
          isTokenInvalid && logout();
        }
      });
  };

  const postRequest = <T>(url: string, data?: T) => {
    setLoading(true);
    post<ResponseDTO>(url, data)
      .then(axiosResponse => {
        setResponse(axiosResponse.data);
        if (hookParams && hookParams.onComplete) {
          hookParams.onComplete(axiosResponse.data);
        }
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        if (hookParams && hookParams.onError) {
          hookParams.onError(err);
        }

        setLoading(false);

        if (err?.response?.data && typeof err.response.data === 'string') {
          const isTokenInvalid =
            err.response.data.includes('missing or invalid token') || err.response?.status === 401;
          isTokenInvalid && logout();
        }
      });
  };

  return [
    { getRequest, postRequest },
    { loading, data: response, error },
  ];
};
