import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useStoreActions } from 'src/stores';
import { useMe } from 'src/api/hooks/useAuth';

export interface TokenAuthParams {
  token: string;
}

export const TokenAuth: React.FC = () => {
  const history = useHistory();

  const updateUser = useStoreActions(actions => actions.user.update);
  const { token } = useParams<TokenAuthParams>();

  const [getMe] = useMe({
    onComplete: res => {
      if (res && res.managerProfile) {
        if (res.companies.length === 0) {
          history.push('/register');
          return;
        }

        updateUser({
          ...res.managerProfile,
          isLogedIn: true,
        });

        history.push('/');

        return;
      } else {
        updateUser({
          id: '',
          tokenExpire: new Date(''),
          token: '',
          isLogedIn: false,
        });
        history.push('/');
      }
    },
    onError: () => {
      alert('FAILED');
    },
  });

  useEffect(() => {
    if (token) {
      updateUser({
        id: '',
        tokenExpire: new Date(''),
        token: token,
        isLogedIn: false,
      });

      setTimeout(() => {
        getMe();
      }, 2000);
    }
  }, []);

  return <>redirect...wait...</>;
};
