import { action, Action } from 'easy-peasy';

const initialState = {
  email: '',
  registrationFormData: {
    email: '',
    lastName: '',
    firstName: '',
    birthDay: '',
  },
};

export interface AuthStore {
  email: string;
  setAuthEmail: Action<AuthStore, string>;
  reset: Action<AuthStore>;
}

export const authStore: AuthStore = {
  ...initialState,
  setAuthEmail: action((state, payload) => {
    // removing possible spaces from pone number input
    state.email = payload.replace(/\s/g, '');
  }),
  reset: action(() => ({
    ...initialState,
  })),
};
