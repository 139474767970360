import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  MenuItem,
  Select,
  Icon,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { Trans, useTranslation } from 'react-i18next';
import { useCreateJobOffer } from 'src/api/hooks/useJob';
import { useStoreActions, useStoreState } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';

import { Typography } from 'src/components/Typography';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { CreateJobOfferModalProps } from './CreateJobForm.types';
import { useGetInvitableWorkers } from 'src/api/hooks/useWorker';
import { InviteWorkersForm } from 'src/pages/Workers/components/InviteWorkersForm';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_TRANSFORM, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { useModalStyles } from './useModalStyles';
import { JOB_STATUS } from 'src/DTO/JobStatus.enum';

export const CreateJobOfferModal: React.FC<CreateJobOfferModalProps> = ({
  isOpen,
  handleClose,
  jobId,
  jobStatus,
}) => {
  const { t } = useTranslation();
  const customStyles = useModalStyles();

  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [selectedWorkerId, setSelectedWorkerId] = useState<string>('');
  const [newlyAddedSelectedWorkerId, setnewlyAddedSelectedWorkerId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const invitedWorkers = useStoreState(state => state.workers.invitedWorkers);

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);
  const setInvitedWorkers = useStoreActions(action => action.workers.setInvitedWorkers);

  const [getInvitableWorkers] = useGetInvitableWorkers({
    onComplete: res => {
      if (res) {
        setInvitedWorkers(res);

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    },
    onError: res => {
      console.log('failed', res);
    },
  });

  const [createJobOffer, { loading }] = useCreateJobOffer({
    onComplete: res => {
      if (res) {
        handleClose();
        setSelectedWorkerId('');
        setnewlyAddedSelectedWorkerId('');
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: `${
            jobStatus === JOB_STATUS.ACTIVE
              ? t('jobPage.createJobOfferModal.createJobOffer.success')
              : t('jobPage.createJobOfferModal.createJobOffer.inactiveJobSuccess')
          }`,
        });
      }
    },
    onError: () => {
      handleClose();
      setSelectedWorkerId('');
      setnewlyAddedSelectedWorkerId('');
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('jobPage.createJobOfferModal.createJobOffer.error'),
      });
    },
  });

  useEffect(() => {
    getInvitableWorkers({ variables: { jobId } });
  }, []);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} classes={{ paper: customStyles.paper }}>
        <DialogTitle>
          {isInviteDialogOpen && <Trans>workersScreen.inviteWorkers</Trans>}
          {!isInviteDialogOpen && <Trans>jobPage.createJobOfferModal.title</Trans>}
        </DialogTitle>
        <DialogContent>
          {isInviteDialogOpen && (
            <Box pt={3} pb={3}>
              <InviteWorkersForm
                onCancel={() => setIsInviteDialogOpen(false)}
                onSuccess={res => {
                  setIsLoading(true);
                  setnewlyAddedSelectedWorkerId(res.id);
                  getInvitableWorkers({ variables: { jobId } });
                }}
                onError={() => {
                  getInvitableWorkers();
                }}
              />
            </Box>
          )}

          {!isInviteDialogOpen && (
            <Box pt={3} pb={3}>
              <Box mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <Typography
                  component="label"
                  variant="caption"
                  fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
                  textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
                  <Trans>jobPage.createJobOfferModal.workerSelectTitle</Trans>
                </Typography>
              </Box>
              {!isLoading && (
                <Select
                  input={
                    <Input
                      icon={{
                        component: <Icon>expand_more</Icon>,
                        direction: 'right',
                      }}
                    />
                  }
                  defaultValue={newlyAddedSelectedWorkerId}
                  onChange={event => {
                    setnewlyAddedSelectedWorkerId('');
                    setSelectedWorkerId(event.target.value as string);
                  }}>
                  <MenuItem onClick={() => setIsInviteDialogOpen(true)} sx={{ marginBottom: 1 }}>
                    <Add color="primary" sx={{ marginRight: 1 }} />
                    <Trans>generic.add</Trans>
                  </MenuItem>
                  {invitedWorkers && invitedWorkers.length ? (
                    invitedWorkers.map(worker => (
                      <MenuItem key={worker.id} value={worker.id} sx={{ marginBottom: 1 }}>
                        {worker.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <Trans>jobPage.createJobOfferModal.noWorker</Trans>
                    </MenuItem>
                  )}
                </Select>
              )}
            </Box>
          )}
        </DialogContent>
        {!isInviteDialogOpen && (
          <DialogActions>
            <Button
              onClick={() => {
                setnewlyAddedSelectedWorkerId('');
                setSelectedWorkerId('');
                handleClose();
              }}
              disabled={loading}>
              <Trans>generic.cancel</Trans>
            </Button>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              size={BUTTON_SIZE.SMALL}
              color={BUTTON_COLOR.INFO}
              disabled={!selectedWorkerId && !newlyAddedSelectedWorkerId}
              onClick={() =>
                createJobOffer({
                  variables: { workerId: selectedWorkerId || newlyAddedSelectedWorkerId, jobId },
                })
              }>
              <Trans>jobPage.createJobOfferModal.invite</Trans>
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
