import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, Card, CircularProgress } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { DashboardLayout } from 'src/components/layouts/LayoutContainers/DashboardLayout';
import { Header } from 'src/pages/Profile/components/Header';
import { Footer } from 'src/components/Footer';
import { WorkerProfileInfoCard } from 'src/components/Cards/WorkerProfileInfoCard';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { useGetWorkerById, useResendInviteRequest } from 'src/api/hooks/useWorker';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useGetInterviewHash } from 'src/api/hooks/useInterview';
import { HashAttributes } from '../Job/components/EmptyJobView';
import { TYPOGRAPHY_TRANSFORM, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { getWorkerNameWithAge } from 'src/utils/getWorkerNameWithAge';

export const WorkerProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const [workerProfile, setWorkerProfile] = useState<WorkerProfile | null>(null);
  const [hashAttributes, setHashAttributes] = useState<HashAttributes>();

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getWorker, { loading: getWorkerLoading }] = useGetWorkerById({
    onComplete: res => {
      if (res) {
        setWorkerProfile(res.worker);
      }
    },
  });

  const [resendWorkerInvite, { loading }] = useResendInviteRequest({
    onComplete: res => {
      if (res) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('workersScreen.invitedWorkerList.resendInvite.success'),
        });
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersScreen.invitedWorkerList.resendInvite.error'),
      });
    },
  });

  const [getInterviewHash] = useGetInterviewHash({
    onComplete: res => {
      if (res) {
        setHashAttributes(res);
      }
    },
  });

  const handleResendWorkerInvite = useCallback(() => {
    resendWorkerInvite({ variables: { workerId: id } });
  }, []);

  const handleWorkerDocumentsNavigation = (workerData: WorkerProfile) => {
    history.push({
      pathname: `/worker/${workerData.id}/documents`,
      state: { workerId: workerData.id },
    });
  };

  useEffect(() => {
    getInterviewHash();
  }, []);

  useEffect(() => {
    getWorker({ variables: { id } });
  }, [id]);

  if (getWorkerLoading) {
    return (
      <Grid item xs={12}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <DashboardLayout>
      {workerProfile && (
        <Header
          imageUrls={workerProfile?.imageUrls}
          title={getWorkerNameWithAge(workerProfile)}
          subTitle={workerProfile ? workerProfile.phoneNumber : ''}
          loading={loading}
          actions={[
            {
              label: t('workerProfile.resend'),
              action: handleResendWorkerInvite,
            },
            {
              label: t('workerProfile.documents.documents.title'),
              action: () => handleWorkerDocumentsNavigation(workerProfile),
            },
          ]}
          style={{ marginInline: '15px' }}
        />
      )}
      <Box mt={5} mb={3}>
        {workerProfile && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <WorkerProfileInfoCard worker={workerProfile} />
            </Grid>
            {!!workerProfile.interviewId && hashAttributes && (
              <Grid item xs={12} md={6}>
                <Card>
                  <Box pt={2} px={2}>
                    <Typography
                      variant="h6"
                      fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
                      textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
                      <Trans>workerProfile.interview</Trans>
                    </Typography>
                  </Box>
                  <Box height="400px">
                    <iframe
                      width="100%"
                      height="100%"
                      allowFullScreen
                      src={`https://embed.myinterview.com/player.v3.html?apiKey=xkqnejs448zhbgzqnuqljpm5&hashTimestamp=${hashAttributes.timestamp}&hash=${hashAttributes.hash}&video=${workerProfile.interviewId}&fs=0`}></iframe>
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      <Footer />
    </DashboardLayout>
  );
};
