import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { UIController, setLayout } from 'src/context';

import { Box } from 'src/components/Box';
import { AppBar } from './AppBar';
import { Sidenav } from './SideNav';

import { routes } from 'src/router/routes';

const MainLayout: React.FC = ({ children }) => {
  const [controller, dispatch]: any = UIController();
  const { miniSidenav, sidenavColor } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
  }, [pathname]);

  return (
    <>
      <Sidenav color={sidenavColor} brandName="Della Manager" routes={routes()} />
      <Box
        sx={({ breakpoints, transitions, functions: { pxToRem } }: any) => ({
          p: 3,
          position: 'relative',

          [breakpoints.up('xl')]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(['margin-left', 'margin-right'], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}>
        <AppBar />
        {children}
      </Box>
    </>
  );
};

export { MainLayout };
