import colors from 'src/theme/base/colors';
import rgba from 'src/theme/functions/rgba';

const { info } = colors;

export const dialogTitleStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
};

export const dialogPaperStyles = {
  width: '100%',
  maxWidth: '500px',
  maxHeight: 'auto',
  padding: '24px',
  borderRadius: '12px',
  '@media (max-width: 600px)': {
    maxWidth: '90vw',
    padding: '16px',
  },
};

export const dialogContentStyles = {
  borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  '&:last-child': {
    borderBottom: 'none',
  },
  overflow: 'clip',
};

export const dialogActionStyles = {
  justifyContent: 'right',
  padding: 'inherit',
};

export const inputFieldStyles = {
  marginBottom: '50px',
  padding: 'inherit',
};

export const closeIconStyles = {
  '&:hover': {
    color: `${rgba(info.main, 1)}`,
  },
};
