import React, { forwardRef } from 'react';

// @mui material components
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';

// custom styles for the NotificationItem
import { menuItem } from './styles';
import { NotificationItemProps } from './types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_TRANSFORM, TYPOGRAPHY_WEIGHT } from '../Typography/types';

const NotificationItem = forwardRef(
  ({ color, title, date, ...rest }: NotificationItemProps, ref) => (
    <MenuItem {...(rest as any)} ref={ref as any} sx={theme => menuItem(theme) as any}>
      <Box>
        <Typography
          variant="button"
          textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}
          fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
          sx={() => ({ whiteSpace: 'pre-line' })}>
          {title}
        </Typography>
        <Typography
          variant="caption"
          color={TYPOGRAPHY_COLORS.SECONDARY}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 0.5,
          }}>
          <Typography variant="button" color={TYPOGRAPHY_COLORS.SECONDARY}>
            <Icon
              sx={{
                lineHeight: 1.2,
                mr: 0.5,
              }}>
              watch_later
            </Icon>
          </Typography>
          {date}
        </Typography>
      </Box>
    </MenuItem>
  ),
);

export default NotificationItem;
