import React from 'react';
import { forwardRef } from 'react';

// Custom styles for SuiButton
import ButtonRoot from './ButtonRoot';
import { ButtonProps, BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from './types';

const Button = forwardRef(
  (
    {
      color = BUTTON_COLOR.WHITE,
      variant = BUTTON_VARIANT.CONTAINED,
      size = BUTTON_SIZE.MEDIUM,
      circular = false,
      iconOnly = false,
      children,
      ...rest
    }: ButtonProps,
    ref,
  ) => (
    <ButtonRoot
      {...(rest as any)}
      ref={ref as any}
      color="primary"
      variant={variant === 'gradient' ? 'contained' : variant}
      size={size}
      ownerState={{ color, variant, size, circular, iconOnly }}>
      {children}
    </ButtonRoot>
  ),
);

export { Button };
