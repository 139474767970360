import { useEffect } from 'react';
import Axios from 'axios';
import { ENVIROMENT, getConfig, getEnviroment } from '../../configs/config';
import { useStoreState } from '../../stores';
import { useAxiosResponse } from './useAxios.types';

export const useAxios = (): useAxiosResponse => {
  const token = useStoreState(data => data.user.getToken);

  const renderInterceptors = () => {
    if (getEnviroment() === ENVIROMENT.PRODUCTION) return;

    Axios.interceptors.request.use(x => {
      console.log('axios-log', {
        baseUrl: x.baseURL,
        url: x.url,
        data: x.data,
        hasToken: x.headers && x.headers.Authorization && x.headers.Authorization.length > 10,
      });
      return x;
    });

    Axios.interceptors.response.use(
      res => {
        return res;
      },
      err => {
        return Promise.reject(err);
      },
    );
  };

  const renderAxiosDefaults = () => {
    Axios.defaults.baseURL = getConfig().url;
    Axios.defaults.timeout = 1000 * 30;
  };

  const renderAxiosHeaders = () => {
    Axios.defaults.headers = {
      'Access-Control-Allow-Origin': '*',
      Authorization: token,
      appVersion: getConfig().version,
    };
  };

  useEffect(() => {
    if (!Axios.defaults.baseURL) {
      renderAxiosDefaults();
      renderAxiosHeaders();
      renderInterceptors();
      console.log('Initialize Axios');
    }
  }, []);

  useEffect(() => {
    if (token !== Axios.defaults.headers.Authorization) {
      console.log('detach/attach token on Axios');
      renderAxiosHeaders();
    }
  }, [token]);

  const get = async <T>(url: string, params?: Record<any, any>) =>
    await Axios.get<T>(url, {
      params,
    });

  const post = async <T>(url: string, data?: Record<any, any>) => await Axios.post<T>(url, data);

  return { get, post };
};
