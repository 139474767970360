import { useApi } from './useApi';
import { BaseHookRequest, BaseRequest } from './useApi.types';
import { GetInterviewHashPayload } from './useInterview.types';

export const useGetInterviewHash: BaseHookRequest<null, GetInterviewHashPayload> = params => {
  const [{ getRequest }, state] = useApi(params);
  const getInterviewHash: BaseRequest<GetInterviewHashPayload> = () =>
    getRequest<GetInterviewHashPayload>('/manager/application/hash/interview');
  return [getInterviewHash, state];
};
