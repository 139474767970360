import React from 'react';
import { Button } from 'src/components/Button';
import { WorkerActionsButtonProps } from './types';

export const WorkerActionsButton: React.FC<WorkerActionsButtonProps> = ({
  label,
  variant,
  color,
  onClick,
  disabled = false,
}) => (
  <Button
    sx={{ width: '15rem', height: 45, fontSize: 12 }}
    variant={variant}
    color={color}
    onClick={onClick}
    disabled={disabled}>
    {label}
  </Button>
);
