export enum SIGN_IN_LAYOUT_COLORS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  DARK = 'dark',
}

export type SignInLayoutProps = {
  color?: SIGN_IN_LAYOUT_COLORS;
  header?: any;
  title?: string;
  description?: string;
  children: any;
  illustration?: any;
};
