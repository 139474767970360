import React from 'react';
import { Trans } from 'react-i18next';

import { Button } from 'src/components/Button';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';

type ApproveConfirmationCellProps = {
  onApprove: () => void;
};

export function ApproveConfirmationCell({ onApprove }: ApproveConfirmationCellProps) {
  return (
    <Button variant={BUTTON_VARIANT.CONTAINED} color={BUTTON_COLOR.SUCCESS} onClick={onApprove}>
      <Trans>confirmationScreen.confirmationCard.accept</Trans>
    </Button>
  );
}
