import {
  RegisterCompanyPayload,
  RegisterCompanyResponse,
  MeUserResponse,
  MeUserPayload,
  LoginResponse,
  LoginPayload,
  PasswordResetResponse,
  PasswordResetPayload,
} from './useAuth.types';
import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';

export const useLoginAuth: BaseHookRequest<LoginResponse, LoginPayload> = params => {
  const [{ postRequest }, state] = useApi<LoginResponse>(params);
  const login: BaseRequest<LoginPayload> = details =>
    postRequest<LoginPayload>('/manager/auth/login', details?.variables);

  return [login, state];
};

export const usePasswordReset: BaseHookRequest<PasswordResetResponse, PasswordResetPayload> =
  params => {
    const [{ postRequest }, state] = useApi<PasswordResetResponse>(params);
    const reset: BaseRequest<PasswordResetPayload> = details =>
      postRequest<PasswordResetPayload>('/manager/auth/passwordReset', details?.variables);

    return [reset, state];
  };

export const useRegisterManagerWithCompany: BaseHookRequest<
  RegisterCompanyResponse,
  RegisterCompanyPayload
> = params => {
  const [{ postRequest }, state] = useApi<RegisterCompanyResponse>(params);
  const registerUser: BaseRequest<RegisterCompanyPayload> = details =>
    postRequest<RegisterCompanyPayload>('/manager/company/registerWithManager', details?.variables);
  return [registerUser, state];
};

export const useMe: BaseHookRequest<MeUserResponse, MeUserPayload> = params => {
  const [{ getRequest }, state] = useApi<MeUserResponse>(params);
  const registerUser: BaseRequest<MeUserPayload> = () =>
    getRequest<MeUserPayload>('/manager/auth/me');
  return [registerUser, state];
};
