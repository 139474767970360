import { LanguageKeys } from 'src/components/LanguageSelectorContext/types';

export const names: Record<LanguageKeys, string> = {
  en: 'Angol',
  hu: 'Magyar',
};

export const getSelectedLangKeys = (
  selectedLanguages: Record<LanguageKeys, boolean>,
): LanguageKeys[] =>
  Object.keys(selectedLanguages).filter(
    key => selectedLanguages[key as LanguageKeys],
  ) as LanguageKeys[];

export const getLangNames = (langKeys: LanguageKeys[]): string[] => langKeys.map(key => names[key]);
