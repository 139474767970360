import { useTranslation } from 'react-i18next';
import { POOL_STATUS } from 'src/DTO/PoolStatus.enum';
import { Labels } from './useLabels.types';

export const useLabels = () => {
  const { t } = useTranslation();

  const statusLabels: Labels<POOL_STATUS> = {
    [POOL_STATUS.NEW_APPLICATION]: t('workersPage.status.newApplication'),
    [POOL_STATUS.PENDING_OFFER]: t('workersPage.status.pendingOffer'),
    [POOL_STATUS.ACTIVE]: t('workersPage.status.active'),
    [POOL_STATUS.INACTIVE]: t('workersPage.status.inActive'),
    [POOL_STATUS.WORKER_UNDER_REVIW]: t('workersPage.status.underReview'),
  };

  return { statusLabels };
};
