import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Checkbox, Select, MenuItem, Icon } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Add } from '@mui/icons-material';
import { Trans } from 'react-i18next';

import { Input } from 'src/components/Input';
import { Typography } from 'src/components/Typography';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { useStoreState, useStoreActions } from 'src/stores';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { NewJobData } from 'src/api/hooks/useJob.types';
import { useGetInvitedWorkerRequest } from 'src/api/hooks/useWorker';
import { InviteWorkersForm } from 'src/pages/Workers/components/InviteWorkersForm';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';

export interface InviteWorkersToJobProps {
  handleNextButtonClick: (newJobData: NewJobData) => void;
  handlePreviousButtonClick: () => void;
  selectedWorkers: WorkerProfile[];
  setSelectedWorkers: (workers: WorkerProfile[]) => void;
  newJobData: NewJobData;
}

export const InviteWorkersToJob: React.FC<InviteWorkersToJobProps> = ({
  handleNextButtonClick,
  handlePreviousButtonClick,
  selectedWorkers,
  setSelectedWorkers,
  newJobData,
}) => {
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState<boolean>(false);
  const [recruitmentNeeded, setRecruitmentNeeded] = useState<boolean>(
    newJobData?.recruitmentNeeded || true,
  );

  const workers = useStoreState(state => state.workers.invitedWorkers);
  const setInvitedWorkers = useStoreActions(action => action.workers.setInvitedWorkers);

  const handleRecruitmentChange = (
    _event: React.MouseEvent<HTMLElement>,
    isRecruitmentNeeded: boolean,
  ) => {
    setRecruitmentNeeded(isRecruitmentNeeded);
  };

  const [getInvitedWorkers] = useGetInvitedWorkerRequest({
    onComplete: res => {
      if (res) {
        setInvitedWorkers(res);
      }
    },
    onError: res => {
      console.log('failed', res);
    },
  });

  useEffect(() => {
    getInvitedWorkers();
  }, []);

  const handleToggle = (worker: WorkerProfile) => {
    const existingWorker = selectedWorkers.find(selectedWorker => selectedWorker.id === worker.id);

    if (existingWorker) {
      setSelectedWorkers(
        selectedWorkers.filter(selectedWorker => selectedWorker.id !== existingWorker.id),
      );
    } else {
      setSelectedWorkers([...selectedWorkers, worker]);
    }
  };

  const selectedWorkersValue = useMemo(() => {
    return () => selectedWorkers.map(worker => worker.name).join(', ');
  }, [selectedWorkers]);

  const onSubmit = () => {
    const submitData = {
      ...newJobData,
      recruitmentNeeded,
    };

    handleNextButtonClick(submitData);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box mt={2} mb={2} display="flex" justifyContent="center">
            <ToggleButtonGroup
              color="info"
              value={recruitmentNeeded}
              exclusive
              onChange={handleRecruitmentChange}>
              <ToggleButton value={true}>
                <Trans>jobPage.createJobModal.recruitmentNeededLabel</Trans>
              </ToggleButton>
              <ToggleButton value={false}>
                <Trans>jobPage.createJobModal.hiddenPositionLabel</Trans>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box mb={4} display="flex" justifyContent="center">
            <Typography
              variant="body2"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              <Trans>
                {recruitmentNeeded
                  ? 'jobPage.createJobModal.recruitmentNeeded'
                  : 'jobPage.createJobModal.hiddenPosition'}
              </Trans>
            </Typography>
          </Box>
          <Box mb={2} mt={2}>
            <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
              <Trans>jobPage.createJobForm.inviteTitle</Trans>
            </Typography>
            <Typography
              variant="body2"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              <Trans>jobPage.createJobModal.helperText3</Trans>
            </Typography>
          </Box>

          {!isInviteDialogOpen && (
            <>
              <Typography variant="h6">
                <Trans>jobPage.createJobForm.selectedWorkers</Trans> {selectedWorkers.length}
              </Typography>

              <Select
                sx={{ width: '100%' }}
                multiple
                value={selectedWorkers}
                input={<Input icon={{ component: <Icon>expand_more</Icon>, direction: 'right' }} />}
                renderValue={selectedWorkersValue}>
                <MenuItem onClick={() => setIsInviteDialogOpen(true)} sx={{ marginBottom: 1 }}>
                  <Add color="primary" sx={{ marginRight: 1 }} />
                  <Trans>generic.add</Trans>
                </MenuItem>
                {workers &&
                  workers.map((worker, index) => (
                    <MenuItem
                      key={`${index}-${worker.id}`}
                      value={worker.name}
                      onClick={() => handleToggle(worker)}>
                      <Checkbox
                        checked={
                          !!selectedWorkers.find(selectedWorker => selectedWorker.id === worker.id)
                        }
                      />
                      {worker.name}
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}

          {isInviteDialogOpen && (
            <InviteWorkersForm
              onCancel={() => setIsInviteDialogOpen(false)}
              onSuccess={handleToggle}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={4}>
            <Box mr={3}>
              <Button onClick={handlePreviousButtonClick}>
                <Trans>generic.previous</Trans>
              </Button>
            </Box>
            <Box ml={3}>
              <Button
                variant={BUTTON_VARIANT.GRADIENT}
                color={BUTTON_COLOR.DARK}
                type="submit"
                onClick={onSubmit}>
                <Trans>jobPage.createRoleForm.submitButtonLabel</Trans>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
