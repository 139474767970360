import { POOL_STATUS } from 'src/DTO/PoolStatus.enum';

export enum ColumnType {
  STATUS = 'status',
  ACTION = 'action',
  JOB = 'job',
  WORKER_NAME = 'name',
  PHONE_NUMBER = 'phoneNumber',
}

export interface Column {
  filterValue: string | undefined;
  setFilter: (value: string | undefined) => void;
  id: string;
  preFilteredRows: Array<{ values: Record<string, keyof typeof POOL_STATUS> }>;
}

export interface DropDownFilterProps {
  column: Column;
  columnType: ColumnType;
}
