import pxToRem from 'src/theme/functions/pxToRem';
import borders from 'src/theme/base/borders';
import colors from 'src/theme/base/colors';
import rgba from 'src/theme/functions/rgba';

const { borderWidth } = borders;
const { transparent, dark, secondary } = colors;

const customButtonStyles = {
  width: pxToRem(150),
  height: pxToRem(120),
  borderWidth: borderWidth[2],
  mb: 1,
  ml: 0.5,

  '&.MuiButton-contained, &.MuiButton-contained:hover': {
    boxShadow: 'none',
    border: `${borderWidth[2]} solid ${transparent.main}`,
  },

  '&.MuiButton-contained:hover svg': {
    fill: rgba(dark.main, 0.75),
  },

  '& svg': {
    fontSize: '100px!important',
  },

  '&:hover': {
    backgroundColor: `${transparent.main} !important`,
    border: `${borderWidth[2]} solid ${secondary.main} !important`,

    '& svg g': {
      fill: rgba(dark.main, 0.75),
    },
  },
};

export default customButtonStyles;
