import React from 'react';
import { forwardRef } from 'react';

// Custom styles for Box
import BoxRoot from './BoxRoot';
import { BoxRootProps } from './types';

const Box = forwardRef(
  ({ variant, bgColor, color, opacity, borderRadius, shadow, ...rest }: BoxRootProps, ref) => (
    <BoxRoot
      {...(rest as any)}
      ref={ref}
      ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow }}
    />
  ),
);

export { Box };
