import React, { useEffect, useState } from 'react';
import { Dialog, AppBar, Toolbar, Container, Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from 'src/components/Typography';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { CONTRACT_TYPE } from 'src/api/hooks/useContract.types';
import { useStoreState, useStoreActions } from 'src/stores';
import { usePregenerateContract, useProduceContract } from 'src/api/hooks/useContract';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from '../Button/types';

export interface ContractDialogState {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const noIp = 'nem meghatározható';

export function StudentOrgContractDialog({ open, setOpen }: ContractDialogState) {
  const { t } = useTranslation();
  const [html, setHtml] = useState<string>();

  const user = useStoreState(state => state.user.data);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [pregenerateContract] = usePregenerateContract({
    onComplete: contractHtmlData => {
      setHtml(contractHtmlData?.html);
    },
  });

  const [produceContract, { loading }] = useProduceContract({
    onComplete: res => {
      if (res?.isSuccess) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.signContract.success'),
        });
        setOpen(false);
      } else {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.error,
          message: t('alerts.signContract.error'),
        });
      }
    },
  });

  useEffect(() => {
    pregenerateContract({
      variables: {
        type: CONTRACT_TYPE.STUDENT_ORG_WITH_COMPANY,
      },
    });
  }, []);

  const sendSignContractRequest = () => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(ipData => {
        produceContract({
          variables: {
            type: CONTRACT_TYPE.STUDENT_ORG_WITH_COMPANY,
            ipAddress: ipData.ip || noIp,
          },
        });
      })
      .catch(err => {
        console.error('IP error', err);
        produceContract({
          variables: {
            type: CONTRACT_TYPE.STUDENT_ORG_WITH_COMPANY,
            ipAddress: noIp,
          },
        });
      });
  };

  return (
    <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
      <AppBar>
        <Toolbar>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box>
              <Typography variant="h5">
                <Trans>generic.studetnOrg</Trans> - {!!user.companies && user?.companies[0]?.name}{' '}
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={sendSignContractRequest}
                color={BUTTON_COLOR.INFO}
                variant={BUTTON_VARIANT.CONTAINED}
                size={BUTTON_SIZE.LARGE}
                disabled={loading}>
                <Trans>{loading ? 'generic.loading' : 'generic.sign'}</Trans>
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Container>
        <Grid item xs={10}>
          <Box mt={12} mb={5}>
            {!!html && <Box dangerouslySetInnerHTML={{ __html: html }}></Box>}
          </Box>
        </Grid>
      </Container>
    </Dialog>
  );
}
