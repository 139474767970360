/* eslint-disable @typescript-eslint/no-empty-function */
import { SelectChangeEvent } from '@mui/material';
import React, { createContext, useState, ReactNode } from 'react';
import { ContextType, LanguageKeys } from 'src/components/LanguageSelectorContext/types';

const defaultSelectedLanguages: Record<LanguageKeys, boolean> = {
  hu: true,
  en: false,
};

export const LangContext = createContext<ContextType>({
  selectedLanguages: defaultSelectedLanguages,
  handleCheckboxChange: () => {},
  onChange: () => {},
});

export const LangContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedLanguages, setSelectedLanguages] = useState(defaultSelectedLanguages);
  const [lastKey, setLastKey] = useState<LanguageKeys | null>('hu');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setSelectedLanguages(prevSelectedLanguages => ({
      ...prevSelectedLanguages,
      [name]: checked,
    }));
  };

  const onChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target as HTMLInputElement;

    const newSelectedLanguages: Record<LanguageKeys, boolean> = { hu: false, en: false };

    if (Array.isArray(value)) {
      value.forEach((key: LanguageKeys) => {
        newSelectedLanguages[key] = true;
      });
    }

    if (Array.isArray(value)) {
      if (value.length === 1) {
        setLastKey(value[0]);
      }
      if (value.length === 0 && lastKey) {
        newSelectedLanguages[lastKey] = true;
      }
    }

    setSelectedLanguages(newSelectedLanguages);
  };
  return (
    <LangContext.Provider value={{ selectedLanguages, handleCheckboxChange, onChange }}>
      {children}
    </LangContext.Provider>
  );
};
