import { ManagerProfile } from './ManagerProfile.type';
import { WorkerProfile } from './WorkerProfile.type';

export enum DocumentType {
  StudentId = 'StudentId',
  DoctorsPaper = 'DoctorsPaper',
  StudentStatusVerification = 'StudentStatusVerification',
}

export enum DocumentStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Declined = 'Declined',
}

export interface Document {
  id: string;
  type: DocumentType;
  airtableId: string;
  documentUrl: string[];
  expires: Date | null;
  status: DocumentStatus;
  worker?: WorkerProfile;
  manager?: ManagerProfile;
  createdAt: Date;
  updatedAt: Date;
}

export interface Contract {
  createdAt: Date;
  title: string;
  url: string;
}
