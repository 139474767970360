import React, { FormEvent, useState } from 'react';

// react-router-dom components
import { Link } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { Input } from 'src/components/Input';
import { Button } from 'src/components/Button';

// Authentication layout components
import { SigninLayout } from 'src/components/layouts/SignInLayout';

// Image
import dellaLogo from 'src/assets/images/della-logo-white.png';

import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';
import { INPUT_SIZE } from 'src/components/Input/types';
import { useLoginAuth, useMe, usePasswordReset } from 'src/api/hooks/useAuth';
import { useStoreActions, useStoreState } from 'src/stores';
import { AuthStateProps } from './types';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { PasswordReset } from 'src/components/PasswordReset';

function SignIn() {
  const { t } = useTranslation();
  const history = useHistory();

  /* GLOBAL STATE */
  const authEmail = useStoreState(state => state.auth.email);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [passwordResetEmail, setPasswordResetEmail] = useState<string>('');

  const updateUser = useStoreActions(actions => actions.user.update);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  /* LOCAL STATE */
  const [authData, setAuthData] = useState<AuthStateProps>({
    email: authEmail,
    password: '',
    error: null,
  });

  const [getMe] = useMe({
    onComplete: res => {
      if (res && res.managerProfile) {
        if (res.companies.length === 0) {
          history.push('/register');
          return;
        }

        if (res.invites.length > 0) {
          updateUser({
            ...res.managerProfile,
            isLogedIn: true,
          });

          history.push('/');

          return;
        }

        history.push('/not-invited');

        return;
      }
    },
  });

  const [passwordReset, { loading: passResetLoading }] = usePasswordReset({
    onComplete: res => {
      if (res && res.isSuccess) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.passwordReset.success'),
        });
      } else {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.error,
          message: t('alerts.passwordReset.error'),
        });
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.passwordReset.error'),
      });
    },
  });

  const handleClosePasswordResetDialog = () => {
    setShowModal(false);
  };

  const handleOpenPasswordResetDialog = () => {
    setShowModal(true);
  };

  const handlePasswordResetEmail = (event: FormEvent<HTMLInputElement>) => {
    setPasswordResetEmail(event.currentTarget.value);
  };

  const handlePasswordReset = () => {
    if (passResetLoading) return;
    passwordReset({
      variables: { email: passwordResetEmail },
    });
  };

  const [startLogin, { loading }] = useLoginAuth({
    onComplete: res => {
      if (res && res.token) {
        // TODO implement actual valid expiration date for token
        updateUser({
          id: '',
          tokenExpire: new Date(res.tokenExpire || ''),
          token: res.token,
          isLogedIn: false,
        });

        getMe();
      } else {
        console.log('here');
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.error,
          message: t('alerts.verificationCode.error'),
        });
      }
    },
    onError: error => {
      if (error?.response?.status === 403) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.error,
          message: t('alerts.verificationCode.error'),
        });
      }
    },
  });

  const doLogin = () => {
    const { email, password } = authData;
    if (!email || !password) return;
    startLogin({
      variables: { email, password },
    });
  };

  return (
    <SigninLayout
      title={t('generic.login')}
      illustration={{
        image: dellaLogo,
        title: 'Della Manager',
        description: t('generic.loginUpsell'),
      }}>
      <Box component="form" role="form">
        <Box mb={2}>
          <Input
            type="email"
            disabled={loading}
            placeholder={t('loginScreen.email')}
            size={INPUT_SIZE.LARGE}
            onChange={event =>
              setAuthData({
                ...authData,
                error: null,
                email: event.currentTarget.value,
              })
            }
            value={authData.email}
          />
        </Box>
        <Box mb={2}>
          <Input
            type="password"
            disabled={loading}
            placeholder={t('loginScreen.password')}
            size={INPUT_SIZE.LARGE}
            onChange={event =>
              setAuthData({
                ...authData,
                error: null,
                password: event.currentTarget.value,
              })
            }
            value={authData.password}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            variant="button"
            fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
            onClick={handleOpenPasswordResetDialog}
            sx={{ cursor: 'pointer', userSelect: 'none' }}>
            <Trans>loginScreen.askPasswordReset</Trans>
          </Typography>
        </Box>
        <Box mt={4} mb={1}>
          <Button
            onClick={doLogin}
            variant={BUTTON_VARIANT.GRADIENT}
            color={BUTTON_COLOR.INFO}
            size={BUTTON_SIZE.LARGE}
            fullWidth>
            <Trans>generic.login</Trans>
          </Button>
        </Box>
        <Box mt={3} textAlign="center">
          <Typography
            variant="button"
            color={TYPOGRAPHY_COLORS.TEXT}
            fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
            <Trans>loginScreen.linkToRegister</Trans>{' '}
            <Typography
              component={Link}
              to="/register"
              variant="button"
              color={TYPOGRAPHY_COLORS.INFO}
              fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
              textGradient>
              <Trans>loginScreen.signUp</Trans>
            </Typography>
          </Typography>
        </Box>
        <PasswordReset
          shouldOpen={showModal}
          handleClose={handleClosePasswordResetDialog}
          handlePasswordReset={handlePasswordReset}
          passwordResetEmail={passwordResetEmail}
          handlePasswordResetEmail={handlePasswordResetEmail}
          passResetLoading={passResetLoading}
        />
        <Box mt={5} textAlign="center">
          <Typography
            variant="button"
            color={TYPOGRAPHY_COLORS.TEXT}
            fontWeight={TYPOGRAPHY_WEIGHT.LIGHT}>
            <Trans>loginScreen.supportText</Trans>
          </Typography>
        </Box>
      </Box>
    </SigninLayout>
  );
}

export { SignIn };
