import React from 'react';
import { FormControl, Select, MenuItem, Icon, SelectChangeEvent } from '@mui/material';

import { Input } from '../Input';
import { ColumnType, DropDownFilterProps } from './types';
import { Trans, useTranslation } from 'react-i18next';
import { TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { Typography } from '../Typography';
import { Box } from '../Box';
import typography from 'src/theme/base/typography';
import colors from 'src/theme/base/colors';
import { useLabels } from 'src/hooks/useLabels';
import { getStatusLabel } from 'src/utils/getStatusLabel';
import { POOL_STATUS } from 'src/DTO/PoolStatus.enum';

export const DropDownFilter: React.FC<DropDownFilterProps & { columnType: ColumnType }> = ({
  column: { filterValue, setFilter, id, preFilteredRows },
  columnType,
}) => {
  const { statusLabels } = useLabels();

  const options = Array.from(
    new Set(preFilteredRows.map(row => row.values[id]).filter(item => item !== undefined)),
  ) as string[];

  const columnStatus = columnType === ColumnType.STATUS;
  const columnAction = columnType === ColumnType.ACTION;

  const handleChange = (e: SelectChangeEvent<string>) => {
    setFilter(e.target.value);
  };

  const handleStatusLabel = (value: string): string => {
    return columnStatus ? getStatusLabel(value as POOL_STATUS, statusLabels) : value;
  };

  const renderValue = (value: string) => {
    if (!value) {
      return (
        <Box>
          <Typography
            sx={{ fontSize: typography.size.sm, color: colors.grey[500] }}
            fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
            <Trans>
              {!columnAction
                ? columnStatus
                  ? 'workersPage.filterByStatus'
                  : 'workersPage.filterByJob'
                : 'workersPage.includeAll'}
            </Trans>
          </Typography>
        </Box>
      );
    }
    return handleStatusLabel(value);
  };

  return (
    <Box width="15rem">
      <FormControl fullWidth>
        <Select
          disabled={columnAction ? true : false}
          displayEmpty
          value={filterValue || ''}
          onChange={handleChange}
          renderValue={renderValue}
          input={
            <Input
              icon={{
                component: <Icon>expand_more</Icon>,
                direction: 'right',
              }}
            />
          }>
          <MenuItem value="">
            <Trans>workersPage.includeAll</Trans>
          </MenuItem>
          {options.map((option: string, index: number) => (
            <MenuItem key={index} value={option}>
              {handleStatusLabel(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export const SearchFilter: React.FC<DropDownFilterProps & { columnType: ColumnType }> = ({
  column: { filterValue, setFilter },
  columnType,
}) => {
  const { t } = useTranslation();

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const selectedOption = e.currentTarget.value;
    setFilter(selectedOption || undefined);
  };

  const isColumnPhoneNumber = columnType === ColumnType.PHONE_NUMBER;

  return (
    <Box width="15rem">
      <FormControl fullWidth>
        <Input
          type={isColumnPhoneNumber ? 'number' : 'text'}
          placeholder={
            isColumnPhoneNumber
              ? t('workersPage.filterByPhoneNumber')
              : t('workersPage.filterByName')
          }
          value={filterValue || ''}
          onChange={handleChange}
        />
      </FormControl>
    </Box>
  );
};
