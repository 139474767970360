import React from 'react';
import { Trans } from 'react-i18next';

import Card from '@mui/material/Card';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';

import {
  TYPOGRAPHY_COLORS,
  TYPOGRAPHY_TRANSFORM,
  TYPOGRAPHY_WEIGHT,
} from 'src/components/Typography/types';

import { RegisterCompanyPayload } from 'src/api/hooks/useAuth.types';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';
import { ORG_TYPE } from 'src/DTO/OrganizationType.enum';

export interface ConfirmDetailsProps {
  registerFormData: RegisterCompanyPayload;
  setIsConfirmView: (isConfirmView: boolean) => void;
  handleSubmit: () => void;
}

export const ConfirmDetails: React.FC<ConfirmDetailsProps> = ({
  registerFormData,
  setIsConfirmView,
  handleSubmit,
}) => {
  return (
    <Card id="delete-account">
      <Box pt={3} px={2}>
        <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
          <Trans>registrationScreen.confirmDetails</Trans>
        </Typography>
      </Box>
      <Box pt={1} pb={2} px={2}>
        <Box component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Box
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor="grey-100"
            borderRadius="lg"
            p={3}
            mb={1}
            mt={2}>
            <Box width="100%" display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" flexDirection="column" mb={2}>
                <Typography
                  variant="button"
                  fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
                  textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
                  {registerFormData.businessCeoName}
                </Typography>

                <Box
                  display="flex"
                  alignItems="center"
                  mt={{ xs: 2, sm: 0 }}
                  ml={{ xs: -1.5, sm: 0 }}>
                  <Box mr={1}>
                    <Button
                      variant={BUTTON_VARIANT.OUTLINED}
                      color={BUTTON_COLOR.INFO}
                      size={BUTTON_SIZE.SMALL}
                      onClick={() => setIsConfirmView(false)}>
                      <Trans>registrationScreen.edit</Trans>
                    </Button>
                  </Box>
                  <Button
                    variant={BUTTON_VARIANT.GRADIENT}
                    color={BUTTON_COLOR.DARK}
                    onClick={handleSubmit}>
                    <Trans>registrationScreen.confirm</Trans>
                  </Button>
                </Box>
              </Box>
              <Box mb={1} lineHeight={0}>
                <Typography variant="caption" color={TYPOGRAPHY_COLORS.TEXT}>
                  <Trans>registrationScreen.nameOfTheBusiness</Trans>:&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="caption"
                    fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
                    textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
                    {registerFormData.businessName}
                  </Typography>
                </Typography>
              </Box>
              <Box mb={1} lineHeight={0}>
                <Typography variant="caption" color={TYPOGRAPHY_COLORS.TEXT}>
                  <Trans>registrationScreen.organizationType</Trans>:&nbsp;&nbsp;&nbsp;
                  <Typography variant="caption" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                    {registerFormData.organizationType === ORG_TYPE.COMPANY ? (
                      <Trans>registrationScreen.organizationCompany</Trans>
                    ) : (
                      <Trans>registrationScreen.organizationStudentOrg</Trans>
                    )}
                  </Typography>
                </Typography>
              </Box>
              <Box mb={1} lineHeight={0}>
                <Typography variant="caption" color={TYPOGRAPHY_COLORS.TEXT}>
                  <Trans>registrationScreen.email</Trans>:&nbsp;&nbsp;&nbsp;
                  <Typography variant="caption" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                    {registerFormData.businessEmail}
                  </Typography>
                </Typography>
              </Box>
              <Box mb={1} lineHeight={0}>
                <Typography variant="caption" color={TYPOGRAPHY_COLORS.TEXT}>
                  <Trans>registrationScreen.phoneNumber</Trans>:&nbsp;&nbsp;&nbsp;
                  <Typography variant="caption" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                    {registerFormData.businessPhoneNumber}
                  </Typography>
                </Typography>
              </Box>
              <Box mb={1} lineHeight={0}>
                <Typography variant="caption" color={TYPOGRAPHY_COLORS.TEXT}>
                  <Trans>registrationScreen.zip</Trans>:&nbsp;&nbsp;&nbsp;
                  <Typography variant="caption" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                    {registerFormData.businessPostCode}
                  </Typography>
                </Typography>
              </Box>
              <Box mb={1} lineHeight={0}>
                <Typography variant="caption" color={TYPOGRAPHY_COLORS.TEXT}>
                  <Trans>registrationScreen.city</Trans>:&nbsp;&nbsp;&nbsp;
                  <Typography variant="caption" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                    {registerFormData.businessCity}
                  </Typography>
                </Typography>
              </Box>
              <Box mb={1} lineHeight={0}>
                <Typography variant="caption" color={TYPOGRAPHY_COLORS.TEXT}>
                  <Trans>registrationScreen.address</Trans>:&nbsp;&nbsp;&nbsp;
                  <Typography variant="caption" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                    {registerFormData.businessAddressLine1}
                  </Typography>
                </Typography>
              </Box>
              <Box mb={1} lineHeight={0}>
                <Typography variant="caption" color={TYPOGRAPHY_COLORS.TEXT}>
                  <Trans>registrationScreen.country</Trans>:&nbsp;&nbsp;&nbsp;
                  <Typography variant="caption" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                    {registerFormData.businessCountry}
                  </Typography>
                </Typography>
              </Box>
              <Box mb={1} lineHeight={0}>
                <Typography variant="caption" color={TYPOGRAPHY_COLORS.TEXT}>
                  <Trans>registrationScreen.registrationNumber</Trans>:&nbsp;&nbsp;&nbsp;
                  <Typography variant="caption" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                    {registerFormData.businessNumber}
                  </Typography>
                </Typography>
              </Box>
              <Typography variant="caption" color={TYPOGRAPHY_COLORS.TEXT}>
                <Trans>registrationScreen.vatNumber</Trans>:&nbsp;&nbsp;&nbsp;
                <Typography variant="caption" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                  {registerFormData.businessVatNumber}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
