import { action, Action } from 'easy-peasy';
import { WorkerProfile } from '../DTO/WorkerProfile.type';

const initialState = {
  invitedWorkers: undefined,
};

export interface WorkerStore {
  invitedWorkers?: WorkerProfile[];
  addInvitedWorker: Action<WorkerStore, WorkerProfile>;
  setInvitedWorkers: Action<WorkerStore, WorkerProfile[]>;
  reset: Action<WorkerStore>;
}

export const workerStore: WorkerStore = {
  ...initialState,
  setInvitedWorkers: action((state, payload) => {
    state.invitedWorkers = payload;
  }),
  addInvitedWorker: action((state, payload) => {
    if (state.invitedWorkers) {
      state.invitedWorkers = [...state.invitedWorkers, payload];
    } else {
      state.invitedWorkers = [payload];
    }
  }),
  reset: action(() => ({
    ...initialState,
  })),
};
