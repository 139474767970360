import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import {
  ArchiveWorkerPayload,
  ArchiveWorkerResponse,
  GetInvitableWorkersPayload,
  GetWorkerByIdPayload,
  GetWorkerByIdResponse,
  GetWorkerContractsPayload,
  GetWorkerContractsResponse,
  GetWorkerDocumentsPayload,
  GetWorkerDocumentsResponse,
  InviteWorkerPayload,
  RemoveWorkerPayload,
  RemoveWorkerResponse,
  ResendWorkerInvitePayload,
  ResendWorkerInviteResponse,
} from './useWorker.types';

export const useInviteWorkerRequest: BaseHookRequest<WorkerProfile, InviteWorkerPayload> =
  params => {
    const [{ postRequest }, state] = useApi<WorkerProfile>(params);
    const inviteWorker: BaseRequest<InviteWorkerPayload> = details => {
      postRequest<InviteWorkerPayload>('/manager/worker/invite/create', details?.variables);
    };

    return [inviteWorker, state];
  };

export const useGetInvitedWorkerRequest: BaseHookRequest<WorkerProfile[], null> = params => {
  const [{ getRequest }, state] = useApi<WorkerProfile[]>(params);
  const getInvitedWorkers = () => {
    getRequest<InviteWorkerPayload>('/manager/worker/invitedWorkers');
  };

  return [getInvitedWorkers, state];
};

export const useGetInvitableWorkers: BaseHookRequest<WorkerProfile[], GetInvitableWorkersPayload> =
  params => {
    const [{ getRequest }, state] = useApi<WorkerProfile[]>(params);
    const getInvitableWorkers: BaseRequest<GetInvitableWorkersPayload> = details => {
      getRequest(`/manager/worker/invitableWorkers/${details?.variables.jobId}`);
    };
    return [getInvitableWorkers, state];
  };

export const useResendInviteRequest: BaseHookRequest<
  ResendWorkerInviteResponse,
  ResendWorkerInvitePayload
> = params => {
  const [{ postRequest }, state] = useApi<ResendWorkerInviteResponse>(params);
  const resendWorkerInvite: BaseRequest<ResendWorkerInvitePayload> = details => {
    postRequest<ResendWorkerInvitePayload>('/manager/worker/invite/resend', details?.variables);
  };

  return [resendWorkerInvite, state];
};

export const useRemoveWorkerRequest: BaseHookRequest<RemoveWorkerResponse, RemoveWorkerPayload> =
  params => {
    const [{ postRequest }, state] = useApi<RemoveWorkerResponse>(params);
    const removeWorker: BaseRequest<RemoveWorkerPayload> = details => {
      postRequest<RemoveWorkerPayload>('/manager/worker/remove', details?.variables);
    };

    return [removeWorker, state];
  };

export const useGetWorkerDocuments: BaseHookRequest<
  GetWorkerDocumentsResponse,
  GetWorkerDocumentsPayload
> = params => {
  const [{ getRequest }, state] = useApi<GetWorkerDocumentsResponse>(params);
  const getWorkerDocuments: BaseRequest<GetWorkerDocumentsPayload> = details =>
    getRequest<GetWorkerDocumentsPayload>(`/manager/document/${details?.variables.id}/documents`);
  return [getWorkerDocuments, state];
};

export const useGetWorkerContracts: BaseHookRequest<
  GetWorkerContractsResponse,
  GetWorkerContractsPayload
> = params => {
  const [{ getRequest }, state] = useApi<GetWorkerContractsResponse>(params);
  const getWorkerContracts: BaseRequest<GetWorkerContractsPayload> = details =>
    getRequest<GetWorkerContractsPayload>(
      `/manager/document/workerContracts/${details?.variables.id}`,
    );
  return [getWorkerContracts, state];
};

export const useGetWorkerById: BaseHookRequest<GetWorkerByIdResponse, GetWorkerByIdPayload> =
  params => {
    const [{ getRequest }, state] = useApi<GetWorkerByIdResponse>(params);
    const getWorkerById: BaseRequest<GetWorkerByIdPayload> = details => {
      getRequest(`/manager/worker/details/${details?.variables.id}`);
    };
    return [getWorkerById, state];
  };

export const useArchiveWorker: BaseHookRequest<ArchiveWorkerResponse, ArchiveWorkerPayload> =
  params => {
    const [{ getRequest }, state] = useApi<ArchiveWorkerResponse>(params);
    const archiveWorker: BaseRequest<ArchiveWorkerPayload> = details =>
      getRequest<ArchiveWorkerPayload>(`/manager/worker/archive/${details?.variables.workerId}`);
    return [archiveWorker, state];
  };
