import React from 'react';
import { useEffect } from 'react';

// react-router-dom components
import { useLocation } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { UIController, setLayout } from 'src/context';

export enum PAGE_LAYOUT_BG {
  WHITE = 'white',
  LIGHT = 'light',
  DEFAULT = 'default',
}

function PageLayout({ background, children }: { background: PAGE_LAYOUT_BG; children: any }) {
  const [, dispatch]: any = UIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'page');
  }, [pathname]);

  return (
    <Box
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: 'hidden' }}>
      {children}
    </Box>
  );
}

export { PageLayout };
