import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

// @mui material components
import Card from '@mui/material/Card';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';

import { TYPOGRAPHY_COLORS, TYPOGRAPHY_TRANSFORM, TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { GENDER_TYPE } from 'src/DTO/Gender.enum';

type WorkerProfileInfoCardProps = {
  worker: WorkerProfile;
};

function WorkerProfileInfoCard({ worker }: WorkerProfileInfoCardProps) {
  const { t } = useTranslation();

  return (
    <Card sx={{ height: '100%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <Typography
          variant="h6"
          fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
          textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
          <Trans>workerProfile.info</Trans>
        </Typography>
      </Box>
      <Box p={2}>
        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>profileScreen.userEmail</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{worker.email || t('generic.noData')}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>workerProfile.birthDay</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{worker.birthDay || t('generic.noData')}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>workerProfile.taxNumber</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{worker.taxNumber || t('generic.noData')}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>workerProfile.socialSecurityNumber</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{worker.socialSecurityNumber || t('generic.noData')}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>workerProfile.studentIdNumber</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{worker.studentIdNumber || t('generic.noData')}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>workerProfile.mothersName</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{worker.mothersName || t('generic.noData')}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>workerProfile.nationality</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{worker.nationality || t('generic.noData')}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>workerProfile.address</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{worker.address || t('generic.noData')}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>workerProfile.gender</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;
              {worker.gender === GENDER_TYPE.MALE && t('generic.male')}
              {worker.gender === GENDER_TYPE.FEMALE && t('generic.female')}
              {!worker.gender && t('generic.noData')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export { WorkerProfileInfoCard };
