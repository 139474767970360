import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from 'src/components/Typography';
import { useGetAllJobConfirmations } from 'src/api/hooks/useJob';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useStoreActions, useStoreState } from 'src/stores';
import { TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { DataTable } from 'src/components/DataTable';
import { JobConfirmation } from 'src/DTO/JobConfirmation.type';
import { WorkerApprovedCell } from './components/WorkerApprovedCell';
import { WorkerHoursEditableCell } from './components/WorkerHoursEditableCell';
import { ApproveConfirmationCell } from './components/ApproveConfirmationCell';
import { useAcceptConfirmation } from 'src/api/hooks/useJob';
import { ManagerApprovedCell } from './components/ManagerConfirmedCell';

export const ConfirmationsPage: React.FC = () => {
  const { t } = useTranslation();

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);
  const setConfirmations = useStoreActions(action => action.jobs.setConfirmations);
  const pendingConfirmations = useStoreState(state => state.jobs.confirmations);

  const [acceptJobConfirmation] = useAcceptConfirmation({
    onComplete: res => {
      if (res) {
        getAllJobConfirmations();
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.acceptJobConfirmations.success', { hoursWorked: res.hoursWorked }),
        });
      }
    },
    onError: err => {
      console.log('acceptJobConfirmation', err);
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.acceptJobConfirmations.error'),
      });
    },
  });

  const updateHoursCellValue = (index: number, value: string) => {
    setConfirmations(
      pendingConfirmations.map((conf, i) => {
        if (i === index) {
          conf.hoursWorked = Number(value);
        }

        return conf;
      }),
    );
  };

  const onApprove = (hoursWorked: number, confirmationId: string) => {
    console.log(hoursWorked);

    acceptJobConfirmation({
      variables: { hoursWorked: Number(hoursWorked), confirmationId },
    });
  };

  const columns = [
    { Header: t('confirmationScreen.tableHeader.job'), accessor: 'job.role.name' },
    { Header: t('confirmationScreen.tableHeader.worker'), accessor: 'worker.name' },
    {
      Header: t('confirmationScreen.tableHeader.date'),
      accessor: (conf: JobConfirmation) => `${conf.year}. ${conf.month} ${t('generic.month')}`,
    },
    {
      Header: t('confirmationScreen.tableHeader.workerAccepted'),
      accessor: 'workerConfirmedAt',
      width: '7%',
      Cell: WorkerApprovedCell,
    },
    { Header: t('confirmationScreen.tableHeader.wage'), accessor: 'job.hourlyGrossWage' },
    {
      Header: t('confirmationScreen.tableHeader.hours'),
      accessor: (conf: JobConfirmation, index: number) => (
        <WorkerHoursEditableCell
          value={String(conf.hoursWorked)}
          index={index}
          updateCellValue={(index: number, value: string) => updateHoursCellValue(index, value)}
          disabled={!!conf.managerConfirmedAt}
        />
      ),
    },
    {
      Header: t('confirmationScreen.tableHeader.actions'),
      accessor: (conf: JobConfirmation) =>
        !!conf.managerConfirmedAt ? (
          <ManagerApprovedCell managerConfirmedAt={conf.managerConfirmedAt} />
        ) : (
          <ApproveConfirmationCell onApprove={() => onApprove(conf.hoursWorked, conf.id)} />
        ),
    },
  ];

  const [getAllJobConfirmations] = useGetAllJobConfirmations({
    onComplete: res => {
      if (res) {
        setConfirmations(res);
      }
    },
    onError: err => {
      console.log('getAllJobConfirmations', err);
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.getJobConfirmations.error'),
      });
    },
  });

  useEffect(() => {
    getAllJobConfirmations();
  }, []);

  const shouldShowJobConfirmations = !!pendingConfirmations.length;

  return (
    <Grid container>
      {shouldShowJobConfirmations && (
        <DataTable
          canSearch
          table={{ columns, rows: pendingConfirmations }}
          title={t('confirmationScreen.title')}
        />
      )}
      {!shouldShowJobConfirmations && (
        <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
          <Trans>confirmationScreen.emptyList</Trans>
        </Typography>
      )}
    </Grid>
  );
};
