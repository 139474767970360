export enum GenderTypes {
  Male = 'male',
  Female = 'female',
}

export enum JobTypes {
  LongtermJob = 'LongtermJob',
  ShortTermJob = 'ShortTermJob',
}

export enum DrivingLicenseTypes {
  None = 'none',
  Car = 'car',
  MotorCycle = 'motorcycle',
}

export enum EditModes {
  Full = 'full',
  OfferState = 'offeSstate',
  ActiveJobState = 'activeJobState',
}
