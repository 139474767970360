import React, { useEffect } from 'react';
import { Grid, CircularProgress, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { useCalculateJobWage } from 'src/api/hooks/useJob';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from '../Typography/types';

export interface FinancialDetailsProps {
  hourlyGrossWage: number;
  monthlyHours: number;
}

export const FinancialDetails: React.FC<FinancialDetailsProps> = ({
  hourlyGrossWage,
  monthlyHours,
}) => {
  const { t } = useTranslation();

  const [calculateJobWage, { data }] = useCalculateJobWage();

  useEffect(() => {
    if (!data?.wageOver25) {
      calculateJobWage({
        variables: {
          hourlyGrossWage,
          monthlyHours,
        },
      });
    }
  }, [hourlyGrossWage, monthlyHours]);

  if (!data) {
    return (
      <Grid item xs={12}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  const { hourlyCommissionAmount, hourlyPersonalTaxAmount, hourlyNetAmount, invoicedHourlyAmount } =
    data.wageUnder25;

  const {
    hourlyCommissionAmount: hourlyCommissionAmountOver25,
    hourlyPersonalTaxAmount: hourlyPersonalTaxAmountOver25,
    // hourlyGrossAmount: hourlyGrossAmountOver25,
    hourlyNetAmount: hourlyNetAmountOver25,
    invoicedHourlyAmount: invoicedHourlyAmountOver25,
  } = data.wageOver25;

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="caption">{t('jobPage.createJobForm.under25')}</Typography>

            <Box mt={2}>
              <Typography variant="subtitle2">{t('jobPage.createJobForm.personalTax')}</Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {`${hourlyPersonalTaxAmount} Ft`}
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography variant="subtitle2">{t('jobPage.createJobForm.netWage')}</Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {`${hourlyNetAmount} Ft`}
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography variant="subtitle2">{t('jobPage.createJobForm.comission')}</Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {`${hourlyCommissionAmount} Ft`}
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography variant="subtitle2">{t('jobPage.createJobForm.amountYouPay')}</Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {`${invoicedHourlyAmount} Ft`}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="caption">{t('jobPage.createJobForm.over25')}</Typography>

            <Box mt={2}>
              <Typography variant="subtitle2">{t('jobPage.createJobForm.personalTax')}</Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {`${hourlyPersonalTaxAmountOver25} Ft`}
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography variant="subtitle2">{t('jobPage.createJobForm.netWage')}</Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {`${hourlyNetAmountOver25} Ft`}
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography variant="subtitle2">{t('jobPage.createJobForm.comission')}</Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {`${hourlyCommissionAmountOver25} Ft`}
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography variant="subtitle2">{t('jobPage.createJobForm.amountYouPay')}</Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {`${invoicedHourlyAmountOver25} Ft`}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
