import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const registerFormSchema = (t: TFunction) =>
  Yup.object().shape({
    businessName: Yup.string()
      .required(t('validation.required'))
      .min(3, t('validation.minimum3Char')),
    businessPhoneNumber: Yup.string()
      .required(t('validation.required'))
      .matches(
        /((?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})/g,
        t('validation.invalidPhone'),
      ),
    businessPostCode: Yup.string()
      .required(t('validation.required'))
      .min(4, t('validation.minimum4Char'))
      .matches(/^[0-9]+$/, t('validation.onlyNumbers')),
    businessCity: Yup.string()
      .required(t('validation.required'))
      .min(3, t('validation.minimum3Char')),
    businessAddressLine1: Yup.string()
      .required(t('validation.required'))
      .min(3, t('validation.minimum3Char')),
    businessCountry: Yup.string()
      .min(3, t('validation.minimum3Char'))
      .required(t('validation.required')),
    businessEmail: Yup.string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.required')),
    password: Yup.string()
      .required(t('validation.required'))
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/, t('validation.passwordValidation')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('validation.passwordMatch'))
      .required(t('validation.required')),
    businessVatNumber: Yup.string()
      .matches(/^(?:[0-9]{11}|[0-9]{8}-[0-9]-[0-9]{2})$/, t('validation.onlyNumbersVAT'))
      .required(t('validation.required')),
    businessNumber: Yup.string()
      .matches(/^[0-9]+$/, t('validation.onlyNumbers'))
      .required(t('validation.required'))
      .min(10, t('validation.minimum10Char')),
    businessCeoName: Yup.string()
      .required(t('validation.required'))
      .min(6, t('validation.minimum6Char')),
  });
