export enum BUTTON_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum BUTTON_VARIANT {
  TEXT = 'text',
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  GRADIENT = 'gradient',
}

export enum BUTTON_COLOR {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  LIGHT = 'light',
  DARK = 'dark',
  TEXT = 'text',
  WHITE = 'white',
}

export type ButtonProps = {
  size?: BUTTON_SIZE;
  variant?: BUTTON_VARIANT;
  color?: BUTTON_COLOR;
  circular?: boolean;
  iconOnly?: boolean;
  children: any;
  fullWidth?: boolean;
  onClick?: () => void;
  type?: string;
  disabled?: boolean;
  component?: any;
  href?: string;
  target?: string;
  rel?: string;
  sx?: any;
};
