/**
  The rgba() function helps you to create a rgba color code, it uses the hexToRgb() function
  to convert the hex code into rgb for using it inside the rgba color format.
 */

import hexToRgb from 'src/theme/functions/hexToRgb';

function rgba(color: any, opacity: any) {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
}

export default rgba;
