export enum SNACKBAR_COLOR {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  LIGHT = 'light',
  DARK = 'dark',
}

export type SnackbarProps = {
  icon: any;
  title: string;
  color?: SNACKBAR_COLOR;
  dateTime: string;
  content: any;
  close: () => void;
  bgWhite?: boolean;
  autoHideDuration?: number;
  open: boolean;
};
