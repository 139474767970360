/* eslint-disable indent */
import React from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

import { Button } from 'src/components/Button';
import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';

import typography from 'src/theme/base/typography';

// Images
import whiteCurved from 'src/assets/images/white-curved.jpeg';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';

type HomeCardProps = {
  icon: string;
  title: string;
  action: { label: string; callback: () => void };
};

function HomeCard({ icon, title, action }: HomeCardProps) {
  const { size } = typography;
  const color = 'dark';

  return (
    <Card
      sx={({ functions: { linearGradient, rgba }, palette: { gradients } }: any) => ({
        background: gradients[color]
          ? `${linearGradient(
              rgba(gradients[color].main, 0.9),
              rgba(gradients[color].state, 0.9),
            )}, url(${whiteCurved})`
          : `${linearGradient(
              rgba(gradients.dark.main, 0.9),
              rgba(gradients.dark.state, 0.9),
            )}, url(${whiteCurved})`,
      })}>
      <Box p={2}>
        <Grid container>
          <Grid item xs={7}>
            <Box
              width="3rem"
              height="3rem"
              bgColor="white"
              borderRadius="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color={color}
              shadow="md"
              fontSize={size.xl}>
              <Icon
                sx={{
                  backgroundImage: ({
                    functions: { linearGradient },
                    palette: { gradients },
                  }: any) =>
                    gradients[color]
                      ? linearGradient(gradients[color].main, gradients[color].state)
                      : linearGradient(gradients.dark.main, gradients.dark.state),
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}>
                {icon}
              </Icon>
            </Box>
            <Box mt={2} lineHeight={0}>
              <Typography
                variant="h5"
                fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
                color={TYPOGRAPHY_COLORS.WHITE}>
                {title}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box>
              <Button
                variant={BUTTON_VARIANT.GRADIENT}
                size={BUTTON_SIZE.SMALL}
                color={BUTTON_COLOR.LIGHT}
                onClick={action.callback}>
                {action.label}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export { HomeCard };
