import React from 'react';
import { Trans } from 'react-i18next';

import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { Box } from '../Box';
import { Typography } from '../Typography';

export const JobInfoSection: React.FC<{ label: string; content: React.ReactNode }> = ({
  label,
  content,
}) => (
  <Box mb={2}>
    <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
      <Trans>{label}</Trans>
    </Typography>
    <Typography
      variant="body2"
      fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
      color={TYPOGRAPHY_COLORS.INFO}>
      {content}
    </Typography>
  </Box>
);
