import { makeStyles } from '@mui/styles';

export const useFontStyles = makeStyles(() => ({
  shadow: {
    textShadow: '0px 0px 30px rgba(0, 0, 0, 0.75)',
  },
  titleHighlight: {
    fontSize: '4.5rem',
    lineHeight: '5.2rem',
    fontWeight: 300,
  },
  titleHiglight2: {
    fontSize: '4.1rem',
    lineHeight: '5.2rem',
    letterSpacing: -0.4,
    fontWeight: 300,
  },
  titleStrongHiglight: {
    fontSize: '3.8rem',
    lineHeight: '4.5rem',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  titleQuote: {
    fontSize: '3.5rem',
    lineHeight: '4.5rem',
    fontFamily: ['Playfair Display', 'serif'].join(','),
    fontWeight: 400,
  },
  title: {
    fontSize: '3.2rem',
    lineHeight: '4.6rem',
    fontWeight: 200,
  },
  title2: {
    fontSize: '3.2rem',
    lineHeight: '2.8rem',
    fontWeight: 300,
  },
  title3: {
    fontSize: '3.2rem',
    lineHeight: '3.8rem',
    fontWeight: 400,
  },
  title5: {
    fontSize: '3.2rem',
    lineHeight: '3.8rem',
    fontWeight: 200,
  },
  title6: {
    fontSize: '3.0rem',
    lineHeight: '4.0rem',
    fontWeight: 200,
  },
  title8: {
    fontSize: '3.0rem',
    lineHeight: '3.6rem',
    fontWeight: 300,
  },
  title7: {
    fontSize: '3.0rem',
    lineHeight: '3.4rem',
    fontWeight: 400,
  },
  title9: {
    fontSize: '1.8rem',
    lineHeight: '1.8rem',
    fontWeight: 500,
    textAlign: 'center',
  },
  titleStrong: {
    fontSize: '2.6rem',
    lineHeight: '3.6rem',
    fontWeight: 300,
    textTransform: 'uppercase',
  },
  title4: {
    fontSize: '2.4rem',
    lineHeight: '3.2rem',
    fontWeight: 300,
  },
  text: {
    fontSize: '1.8rem',
    lineHeight: '2.8rem',
    fontWeight: 300,
  },
  button: {
    fontSize: '1.8rem',
    lineHeight: '2.0rem',
    fontWeight: 500,
  },
  text2: {
    fontSize: '1.6rem',
    lineHeight: '2.8rem',
    fontSpacing: 1.2,
    fontWeight: 300,
  },
  text3: {
    fontSize: '1.4rem',
    lineHeight: '2.8rem',
    fontSpacing: 1.2,
    fontWeight: 300,
  },
  text4: {
    fontSize: '1rem',
    lineHeight: '1.4rem',
    fontSpacing: 1.2,
    fontWeight: 300,
  },
  text5: {
    fontSize: '1.2rem',
    fontSpacing: 1.2,
    fontWeight: 300,
  },
  caption: {
    fontSize: '1.4rem',
    lineHeight: '2.1rem',
    fontWeight: 300,
  },
  comment: {
    fontSize: '1.4rem',
    lineHeight: '2.0rem',
    fontWeight: 500,
  },
  note: {
    fontSize: '1.2rem',
    lineHeight: '2.0rem',
    fontWeight: 300,
    fontStyle: 'italic',
  },
  label: {
    fontSize: '1.1rem',
    lineHeight: '1.8rem',
    fontWeight: 500,
  },
}));
