export enum INPUT_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type InputProps = {
  size?: INPUT_SIZE;
  icon?: any;
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  value?: string;
  rows?: number;
  multiline?: boolean;
  inputLabel?: string;
  helperText?: string;
  onBlur?: any;
  InputProps?: any;
};
