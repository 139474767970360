import { useContext } from 'react';
import { LangContext } from 'src/context/LanguageContext';
import { getLanguage } from './getLanguage';
import { NewJobData } from 'src/api/hooks/useJob.types';
import { EditModes } from 'src/pages/Job/Job.types';
import { LocalizedData } from 'src/DTO/Role.type';

interface UseLocalizedData {
  (newJobData?: NewJobData, editMode?: EditModes | boolean): {
    localizedJob?: LocalizedData;
    localizedRole?: LocalizedData;
    enJob?: boolean;
    huJob?: boolean;
    isEngOnly?: boolean;
    shouldLocalize?: boolean;
  };
}

export const useLocalizedData: UseLocalizedData = (newJobData, editMode) => {
  const { selectedLanguages } = useContext(LangContext);

  const localizedJob = newJobData?.localizedData;
  const localizedRole = newJobData?.role?.localizedData?.en;

  const enJob = localizedRole?.selectedLanguages?.en;
  const huJob = localizedRole?.selectedLanguages?.hu;

  const isEngOnly = editMode
    ? getLanguage(newJobData, 'en', 'hu')
    : selectedLanguages.en && !selectedLanguages.hu;

  const shouldLocalize = !!(editMode
    ? !!enJob || (enJob && huJob)
    : selectedLanguages.en || (selectedLanguages.en && selectedLanguages.hu));

  return { localizedJob, localizedRole, enJob, huJob, isEngOnly, shouldLocalize };
};
