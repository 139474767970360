import React from 'react';

// @mui material components
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';

import typography from 'src/theme/base/typography';

import SnackbarIconRoot from './SnackbarIconRoot';
import { SnackbarProps, SNACKBAR_COLOR } from './types';
import { BoxVariants } from '../Box/types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { useTranslation } from 'react-i18next';

function SnackBar({
  color = SNACKBAR_COLOR.INFO,
  icon,
  title,
  dateTime,
  content,
  close,
  bgWhite = false,
  ...rest
}: SnackbarProps) {
  const { t } = useTranslation();
  const { size } = typography;
  let titleColor;
  let dateTimeColor;
  let dividerColor;

  if (bgWhite) {
    titleColor = color;
    dateTimeColor = 'dark';
    dividerColor = false;
  } else if (color === 'light') {
    titleColor = 'dark';
    dateTimeColor = 'text';
    dividerColor = false;
  } else {
    titleColor = 'white';
    dateTimeColor = 'white';
    dividerColor = true;
  }

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      close();
    }, 4000);

    return () => {
      // 👇️ clear timeout when component unmounts
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Snackbar
      TransitionComponent={Fade}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      {...rest}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }>
      <Box
        variant={bgWhite ? BoxVariants.CONTAINED : BoxVariants.GRADIENT}
        bgColor={bgWhite ? 'white' : color}
        minWidth="21.875rem"
        maxWidth="100%"
        shadow="md"
        borderRadius="md"
        p={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center" color="dark" p={1.5}>
          <SnackbarIconRoot fontSize="small" ownerState={{ color, bgWhite }}>
            notifications
          </SnackbarIconRoot>
          <Box display="flex" alignItems="flex-start" lineHeight={0}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
              color={titleColor as TYPOGRAPHY_COLORS}
              textGradient={bgWhite}>
              {title || `Della Manager - ${t('generic.noti')}`}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" lineHeight={0}>
            <Typography variant="caption" color={dateTimeColor as TYPOGRAPHY_COLORS}>
              {dateTime}
            </Typography>
            <CloseIcon
              sx={{
                color: ({ palette: { dark, white } }: any) =>
                  bgWhite || color === 'light' ? dark.main : white.main,
                fontWeight: ({ typography: { fontWeightBold } }) => fontWeightBold,
                cursor: 'pointer',
                marginLeft: 2,
                transform: 'translateY(-1px)',
              }}
              onClick={close}
            />
          </Box>
        </Box>
        <Divider sx={{ margin: 0 }} light={dividerColor} />
        <Box p={1.5} color={bgWhite || color === 'light' ? 'text' : 'white'} fontSize={size.sm}>
          {content}
        </Box>
      </Box>
    </Snackbar>
  );
}

export { SnackBar };
