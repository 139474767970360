import { action, Action, Computed, computed } from 'easy-peasy';
import { Company } from 'src/DTO/Company.type';
import { WORKER_STATUS } from 'src/DTO/WorkerStatus.enum';
export interface UserStoreData {
  id?: string;
  isLogedIn: boolean;
  token?: string;
  tokenExpire?: Date;
  FCMToken?: string;
  userActivityType?: number;
  auth0Id?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  fbUserId?: string | null;
  birthDay?: string;
  phoneNumber?: string;
  email?: string;
  status?: WORKER_STATUS;
  birthName?: string | null;
  mothersName?: string | null;
  socialSecurityNumber?: string | null;
  taxNumber?: string | null;
  studentIdNumber?: string | null;
  hasPaidStudentOrg?: boolean;
  companies?: Company[];
}

export interface FCMTokenUpdatePayload {
  token: string;
}

export interface UserStore {
  data: UserStoreData;
  update: Action<UserStore, UserStoreData>;
  getToken: Computed<UserStore, string>;
  reset: Action<UserStore>;
  updateFCMToken: Action<UserStore, FCMTokenUpdatePayload>;
}

const initialState = {
  id: '',
  token: '',
  FCMToken: '',
  userActivityType: 1,
  isLogedIn: false,
  auth0Id: '',
  name: '',
  firstName: '',
  lastName: '',
  fbUserId: null,
  birthDay: '',
  phoneNumber: '',
  email: '',
  status: WORKER_STATUS.NOT_INVITED,
  birthName: null,
  mothersName: null,
  socialSecurityNumber: null,
  taxNumber: null,
  studentIdNumber: null,
  hasPaidStudentOrg: false,
};

export const userStore: UserStore = {
  data: initialState,
  update: action((state, payload) => {
    state.data = { ...state.data, ...payload };
  }),
  getToken: computed(state => {
    return `Bearer ${state.data.token}`;
  }),
  reset: action(state => {
    state.data = { ...initialState };
  }),
  updateFCMToken: action((state, payload) => {
    state.data.FCMToken = payload.token;
  }),
};
