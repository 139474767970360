import { parsePhoneNumberFromString, isValidPhoneNumber } from 'libphonenumber-js';

export function validatePhone(phone: string): boolean {
  const parsedPhoneNumber = parsePhoneNumberFromString(phone);

  const isPhoneNumberValid = parsedPhoneNumber
    ? isValidPhoneNumber(parsedPhoneNumber.number)
    : false;

  const isValid =
    isPhoneNumberValid ||
    (/^06(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})$/.test(phone) &&
      phone.length >= 8 &&
      phone.length <= 14);

  return isValid;
}
