import React from 'react';
import { forwardRef } from 'react';

// Custom styles for SuiTypography
import TypographyRoot from './TypographyRoot';
import {
  TypographyProps,
  TYPOGRAPHY_COLORS,
  TYPOGRAPHY_TRANSFORM,
  TYPOGRAPHY_VERTICAL_ALIGN,
} from './types';

const Typography = forwardRef(
  (
    {
      color = TYPOGRAPHY_COLORS.DARK,
      fontWeight = false,
      textTransform = TYPOGRAPHY_TRANSFORM.NONE,
      verticalAlign = TYPOGRAPHY_VERTICAL_ALIGN.UNSET,
      textGradient = false,
      opacity = 1,
      children,
      ...rest
    }: TypographyProps,
    ref,
  ) => (
    <TypographyRoot
      {...(rest as any)}
      ref={ref as any}
      ownerState={{ color, textTransform, verticalAlign, fontWeight, opacity, textGradient }}>
      {children}
    </TypographyRoot>
  ),
);

export { Typography };
