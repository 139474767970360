import colors from 'src/theme/base/colors';

const { transparent } = colors;

const iconButton = {
  styleOverrides: {
    root: {
      '&:hover': {
        backgroundColor: transparent.main,
      },
    },
  },
};

export default iconButton;
