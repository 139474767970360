import { makeStyles } from '@mui/styles';
import colors from 'src/theme/base/colors';

export const useModalStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: '8px',
  },
  sectionTitle: {
    color: colors.grey[500],
  },
  paper: {
    minWidth: '50vw',
  },
}));
