import React from 'react';

import { Box } from 'src/components/Box';

import { DataTableHeadCellFilterProps } from './types';
import colors from 'src/theme/base/colors';
import borders from 'src/theme/base/borders';

function DataTableHeadFilterCell({
  children,
  align = 'left',
  ...rest
}: DataTableHeadCellFilterProps) {
  const { light } = colors;
  const { borderWidth } = borders;

  return (
    <Box
      component="th"
      width="auto"
      borderBottom={`${borderWidth[1]} solid ${light.main}`}
      py={1.5}
      px={3}>
      <Box
        {...rest}
        position="relative"
        textAlign={align}
        color="secondary"
        opacity={0.7}
        width="auto">
        {children}
      </Box>
    </Box>
  );
}

export default DataTableHeadFilterCell;
