import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  CircularProgress,
  DialogContentText,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import { Trans, useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';

import { PasswordResetProps } from './types';
import { BUTTON_VARIANT, BUTTON_SIZE, BUTTON_COLOR } from '../Button/types';
import { Button } from '../Button';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { passwordResetSchema } from './validationSchemas';
import { FormInput } from '../FormInputField/FormInput';
import {
  dialogPaperStyles,
  dialogContentStyles,
  dialogActionStyles,
  inputFieldStyles,
  dialogTitleStyle,
  closeIconStyles,
} from './styles';

const PasswordReset: React.FC<PasswordResetProps> = ({
  shouldOpen,
  handleClose,
  passResetLoading,
  handlePasswordReset,
  handlePasswordResetEmail,
  passwordResetEmail,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid item xs={12}>
        <Dialog
          open={shouldOpen}
          onClose={handleClose}
          fullWidth={true}
          keepMounted
          TransitionComponent={Fade}
          transitionDuration={300}
          PaperProps={{ style: dialogPaperStyles }}>
          <DialogTitle sx={dialogTitleStyle}>
            <Box>
              <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
                <Trans>alerts.passwordReset.title</Trans>
              </Typography>
            </Box>
            <IconButton sx={closeIconStyles} onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers sx={dialogContentStyles}>
            <Box mb={3}>
              <DialogContentText>
                <Typography
                  variant="body2"
                  fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                  color={TYPOGRAPHY_COLORS.TEXT}>
                  <Trans>alerts.passwordReset.passwordResetBody</Trans>
                </Typography>
              </DialogContentText>
            </Box>

            <Formik
              initialValues={{ passwordResetEmail: passwordResetEmail || '' }}
              validationSchema={passwordResetSchema(t)}
              validateOnBlur
              onSubmit={() => {
                handlePasswordReset();
              }}>
              {({ values, handleChange }) => (
                <Form>
                  <DialogActions sx={inputFieldStyles}>
                    <Field
                      name="passwordResetEmail"
                      value={values.passwordResetEmail}
                      placeholder={t('alerts.passwordReset.placeholder')}
                      component={FormInput}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        handleChange(e);
                        handlePasswordResetEmail(e);
                      }}
                    />
                  </DialogActions>
                  <DialogActions sx={dialogActionStyles}>
                    <Button
                      type="submit"
                      variant={BUTTON_VARIANT.GRADIENT}
                      size={BUTTON_SIZE.SMALL}
                      color={BUTTON_COLOR.INFO}
                      disabled={passResetLoading}>
                      {passResetLoading ? (
                        <CircularProgress size={12} color="inherit" />
                      ) : (
                        <Trans>alerts.passwordReset.askPasswordReset</Trans>
                      )}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </Grid>
    </Box>
  );
};

export default PasswordReset;
