import { action, Action, Computed, computed } from 'easy-peasy';
import { ReactElement } from 'react';

export const CONFIRM_DIALOG_INITIAL_STATE: ConfirmDialogState = {
  isOpen: false,
  title: '',
  body: null,
  onCancel: () => true,
  onConfirm: () => true,
};

const initialState = {
  confirmDialogState: CONFIRM_DIALOG_INITIAL_STATE,
};

export interface ConfirmDialogOpenRequestProps {
  title: string;
  body?: ReactElement | null;
  onCancel?: () => void;
  onConfirm?: () => void;
}

export interface ConfirmDialogState extends ConfirmDialogOpenRequestProps {
  isOpen: boolean;
}

export interface AppContextStore {
  confirmDialogState: ConfirmDialogState;
  openConfirmDialog: Action<AppContextStore, ConfirmDialogOpenRequestProps>;
  closeConfirmDialog: Action<AppContextStore>;
  getConfirmDialogOpenState: Computed<AppContextStore, boolean>;
  resetConfirmDialogState: Action<AppContextStore>;
}

export const appContextStore: AppContextStore = {
  ...initialState,
  openConfirmDialog: action((state, payload) => {
    state.confirmDialogState = { ...CONFIRM_DIALOG_INITIAL_STATE, ...payload, isOpen: true };
  }),
  closeConfirmDialog: action(state => {
    state.confirmDialogState = {
      ...state.confirmDialogState,
      isOpen: false,
    };
  }),
  getConfirmDialogOpenState: computed(state => state.confirmDialogState.isOpen),
  resetConfirmDialogState: action(state => {
    state.confirmDialogState = CONFIRM_DIALOG_INITIAL_STATE;
  }),
};
