import React from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'src/components/Badge';
import { POOL_STATUS } from 'src/DTO/PoolStatus.enum';

interface WorkerStatusIndicatorProps {
  poolStatus?: POOL_STATUS;
}

export const WorkerStatusCell: React.FC<WorkerStatusIndicatorProps> = ({ poolStatus }) => {
  const { t } = useTranslation();

  switch (poolStatus) {
    case POOL_STATUS.PENDING_OFFER:
      return <Badge size="xs" color="dark" badgeContent={t('workerCard.jobOfferSent')} container />;

    case POOL_STATUS.ACTIVE:
      return <Badge size="xs" color="success" badgeContent={t('workerCard.active')} container />;

    case POOL_STATUS.INACTIVE:
      return <Badge size="xs" color="error" badgeContent={t('workerCard.inActive')} container />;

    case POOL_STATUS.NEW_APPLICATION:
      return <Badge size="xs" color="info" badgeContent={t('workerCard.newApplicant')} container />;

    case POOL_STATUS.WORKER_UNDER_REVIW:
      return (
        <Badge size="xs" color="warning" badgeContent={t('workerCard.documentReview')} container />
      );

    default:
      return <Badge size="xs" color="error" badgeContent={t('workerCard.inActive')} container />;
  }
};
