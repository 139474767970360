import React, { ReactNode } from 'react';

import { Box } from 'src/components/Box';

function DashboardLayout({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={({ breakpoints, transitions }: any) => ({
        p: 3,
        position: 'relative',
        pt: 0,

        [breakpoints.up('xl')]: {
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}>
      {children}
    </Box>
  );
}

export { DashboardLayout };
