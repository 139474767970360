import { Container } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';
import { Typography } from 'src/components/Typography';
import { Box } from 'src/components/Box';
import { useStoreState } from 'src/stores';

function PageNotFound() {
  const history = useHistory();
  const isLoggedIn = useStoreState(state => state.user.data.isLogedIn);

  function handleClick() {
    isLoggedIn ? history.push('/') : history.push('/auth');
  }

  return (
    <Container
      sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box>
        <Typography align="center" variant="h2" component="h2" mb={3}>
          <Trans>generic.notFound</Trans>
        </Typography>
        <Button
          onClick={handleClick}
          variant={BUTTON_VARIANT.GRADIENT}
          color={BUTTON_COLOR.INFO}
          size={BUTTON_SIZE.LARGE}
          fullWidth>
          {isLoggedIn ? <Trans>generic.backToHome</Trans> : <Trans>generic.backToLogin</Trans>}
        </Button>
      </Box>
    </Container>
  );
}

export { PageNotFound };
