import React from 'react';
import { Icon } from '@mui/material';

export function WorkerApprovedCell({ value }: { value?: Date }) {
  if (!!value) {
    return <Icon color="success">done</Icon>;
  }

  return <Icon color="error">close</Icon>;
}
