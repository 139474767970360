import { action, Action } from 'easy-peasy';
import { Role } from 'src/DTO/Role.type';
import { Job } from 'src/DTO/Job.type';
import { JobConfirmation } from 'src/DTO/JobConfirmation.type';

const initialState = {
  roles: [],
  jobs: [],
  confirmations: [],
};

export interface JobStore {
  roles: Role[];
  jobs: Job[];
  confirmations: JobConfirmation[];
  setRoles: Action<JobStore, Role[]>;
  setRole: Action<JobStore, Role>;
  setJobs: Action<JobStore, Job[]>;
  setJob: Action<JobStore, Job>;
  setConfirmations: Action<JobStore, JobConfirmation[]>;
  setConfirmation: Action<JobStore, JobConfirmation>;
  reset: Action<JobStore>;
}

export const jobStore: JobStore = {
  ...initialState,
  setRoles: action((state, payload) => {
    state.roles = [...payload];
  }),
  setRole: action((state, payload) => {
    const remainingRoles = state.roles.filter(role => role.id !== payload.id);

    state.roles = [payload, ...remainingRoles];
  }),
  setJobs: action((state, payload) => {
    state.jobs = [...payload];
  }),
  setJob: action((state, payload) => {
    const remainingJobs = state.jobs.filter(job => job.id !== payload.id);

    state.jobs = [payload, ...remainingJobs];
  }),
  setConfirmations: action((state, payload) => {
    state.confirmations = payload;
  }),
  setConfirmation: action((state, payload) => {
    state.confirmations = state.confirmations.filter(({ id }) => id !== payload.id).concat(payload);
  }),
  reset: action(() => ({
    ...initialState,
  })),
};
