/**
  The boxShadow() function helps you to create a box shadow for an element
 */

// Soft UI Dashboard PRO React helper functions
import rgba from 'src/theme/functions/rgba';
import pxToRem from 'src/theme/functions/pxToRem';

function boxShadow(
  offset = [] as number[],
  radius = [] as any,
  color: any,
  opacity: any,
  inset = '',
) {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${rgba(
    color,
    opacity,
  )}`;
}

export default boxShadow;
