import typography from 'src/theme/base/typography';

import pxToRem from 'src/theme/functions/pxToRem';

const { size } = typography;

const dialogTitle = {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: size.xl,
    },
  },
};

export default dialogTitle;
