import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Trans } from 'react-i18next';
import CountUp from 'react-countup';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import {
  useCompanyAccount,
  useCompanyTransactions,
  useCompanyAllTransactions,
} from 'src/api/hooks/useFinance';
import { useStoreActions, useStoreState } from 'src/stores';

import { TransactionRow } from './components/TransactionRow';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import colors from 'src/theme/base/colors';
import borders from 'src/theme/base/borders';
import {
  TYPOGRAPHY_COLORS,
  TYPOGRAPHY_TRANSFORM,
  TYPOGRAPHY_WEIGHT,
} from 'src/components/Typography/types';

const DEFAULT_GET_TRANSACTIONS_PARAMS = { variables: { skip: 0, limit: 3 } };

export const FinancePage: React.FC = () => {
  const setCompamyAccountData = useStoreActions(action => action.finance.updateAccountData);
  const setCompanyTransactions = useStoreActions(action => action.finance.updateTransactions);

  const companyAccountData = useStoreState(state => state.finance.accountData);
  const companyTransactions = useStoreState(state => state.finance.transactions);

  const { secondary } = colors;
  const { borderWidth } = borders;

  const [show, setShow] = useState(false);

  const [getCompanyAccount] = useCompanyAccount({
    onComplete: res => {
      if (res) {
        setCompamyAccountData(res);
      }
    },
    onError: err => {
      console.log('getCompanyAccount', err);
    },
  });

  const [getCompanyTransactions] = useCompanyTransactions({
    onComplete: res => {
      if (res) {
        setCompanyTransactions(res);
      }
    },
    onError: err => {
      console.log('getTransactions', err);
    },
  });

  const [getCompanyAllTransactions] = useCompanyAllTransactions({
    onComplete: res => {
      if (res) {
        setCompanyTransactions(res);
      }
    },
    onError: err => {
      console.log('getTransactions', err);
    },
  });

  useEffect(() => {
    getCompanyAccount();
    getCompanyTransactions(DEFAULT_GET_TRANSACTIONS_PARAMS);
  }, []);

  const shouldShowMoreButton = companyAccountData.transactionCount >= companyTransactions.length;

  return (
    <Grid container>
      <Box mb={2} width="100%">
        <Box
          borderRadius="md"
          border={`${borderWidth[1]} dashed ${secondary.main}`}
          textAlign="center"
          py={2}>
          <Typography
            variant="h6"
            color={TYPOGRAPHY_COLORS.INFO}
            fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
            textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
            <Trans>financeScreen.account</Trans>
          </Typography>
          <Typography variant="h4" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
            <Typography component="span" variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
              HUF
            </Typography>
            <Box display="inline-block" mx={0.5}>
              <CountUp end={companyAccountData.total} duration={1} separator="," />
            </Box>
          </Typography>
        </Box>
      </Box>
      <Grid container>
        {shouldShowMoreButton && (
          <Box>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.INFO}
              disabled={companyTransactions.length === 0}
              onClick={() => {
                show
                  ? getCompanyTransactions(DEFAULT_GET_TRANSACTIONS_PARAMS)
                  : getCompanyAllTransactions();
                setShow(!show);
              }}>
              {show ? (
                <Trans>financeScreen.balanceShowLess</Trans>
              ) : (
                <Trans>financeScreen.balanceAllHistory</Trans>
              )}
            </Button>
          </Box>
        )}
        <Box mt={2} width="100%">
          <Grid item xs={6}>
            <Box display="flex" justifyContent={'flex-start'} mb={1}>
              <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                {show ? (
                  <Trans>financeScreen.balanceAllHistory</Trans>
                ) : (
                  <Trans>financeScreen.transactionList</Trans>
                )}
              </Typography>
            </Box>
          </Grid>
          {companyTransactions && companyTransactions.length > 0 ? (
            companyTransactions.map(({ id, createdAt, metadata, description, amount }) => (
              <Box mb={1} key={id}>
                <TransactionRow
                  createdAt={createdAt}
                  metaData={metadata}
                  description={description}
                  amount={amount}
                  color={Number(amount) > 0 ? TYPOGRAPHY_COLORS.SUCCESS : TYPOGRAPHY_COLORS.ERROR}
                  icon={Number(amount) > 0 ? 'arrow_upward' : 'arrow_downward'}
                />
              </Box>
            ))
          ) : (
            <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
              <Trans>financeScreen.youHaveNoTransaction</Trans>
            </Typography>
          )}
        </Box>
      </Grid>
      <Box m={1}>
        <Typography variant="caption" display="block">
          <Trans>financeScreen.taxAlert</Trans>
        </Typography>
      </Box>
    </Grid>
  );
};
