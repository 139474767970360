import { ReactNode } from 'react';

export enum AVATAR_SIZE {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
}

export enum AVATAR_SHADOW {
  NONE = 'none',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
  INSET = 'inset',
}

export enum AVATAR_BG_COLOR {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  LIGHT = 'light',
  DARK = 'dark',
  TRANSPARENT = 'transparent',
}

export enum AVATAR_VARIANT {
  SQUARE = 'square',
  ROUNDED = 'rounded',
  CIRCULAR = 'circular',
}

export type AvatarProps = {
  bgColor?: AVATAR_BG_COLOR;
  size?: AVATAR_SIZE;
  shadow?: AVATAR_SHADOW;
  src?: string;
  alt: string;
  variant?: AVATAR_VARIANT;
  sx?: any;
  children?: ReactNode;
  onClick?: () => void;
};
