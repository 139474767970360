import React, { useEffect, useState } from 'react';

// react-router-dom components
import { useLocation, NavLink } from 'react-router-dom';

// @mui material components
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';

import SidenavCollapse from './SideNavCollapse';
import SidenavList from './SideNavList';
import SidenavItem from './SideNavItem';
import SidenavCard from './SideNavCard';

// Custom styles for the Sidenav
import SidenavRoot from './SideNavRoot';
import sidenavLogoLabel from './styles/sideNav';

import { UIController, setMiniSidenav } from 'src/context';
import {
  TYPOGRAPHY_COLORS,
  TYPOGRAPHY_TRANSFORM,
  TYPOGRAPHY_WEIGHT,
} from 'src/components/Typography/types';
import { SideNavProps } from './types';
import { Collapse } from 'src/router/routes';

import { ReactComponent as Logo } from 'src/assets/logo_d.svg';
import pxToRem from 'src/theme/functions/pxToRem';

function Sidenav({ brandName, routes, ...rest }: SideNavProps) {
  const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<string | boolean>(false);
  const [controller, dispatch]: any = UIController();
  const { miniSidenav, transparentSidenav } = controller;

  const location = useLocation();

  const { pathname } = location;
  const collapseName = pathname.split('/').slice(1)[0];
  const itemName = pathname.split('/').slice(1)[1];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse: Collapse[]) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: 'none' }}>
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} style={{ textDecoration: 'none' }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      ),
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses: Collapse[]) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={key === itemName}
            open={openNestedCollapse === name}
            onClick={() =>
              openNestedCollapse === name
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(name)
            }>
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: 'none' }}>
            <SidenavItem name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} style={{ textDecoration: 'none' }}>
            <SidenavItem name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;

      if (type === 'collapse') {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: 'none' }}>
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}>
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() =>
                openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
              }>
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === 'title') {
        returnValue = (
          <Typography
            key={key}
            display="block"
            variant="caption"
            fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
            textTransform={TYPOGRAPHY_TRANSFORM.UPPER_CASE}
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}>
            {title}
          </Typography>
        );
      } else if (type === 'divider') {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    },
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <Box pt={3} pb={1} px={4} textAlign="center">
        <Box
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}>
          <Typography variant="h6" color={TYPOGRAPHY_COLORS.SECONDARY}>
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </Typography>
        </Box>
        <Box component={NavLink} to="/" display="flex" alignItems="center">
          <Logo style={{ height: pxToRem(20), width: pxToRem(20), marginRight: pxToRem(10) }} />
          <Box
            width={(!brandName && '100%') || undefined}
            sx={(theme: any) => sidenavLogoLabel(theme, { miniSidenav })}>
            <Typography component="h6" variant="button" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
              {brandName}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <List>{renderRoutes}</List>

      <Box pt={2} my={2} mx={2}>
        <SidenavCard />
      </Box>
    </SidenavRoot>
  );
}

export default Sidenav;
