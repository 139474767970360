import { action, Action } from 'easy-peasy';

export enum PopUpEnum {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export interface PopUp {
  isOpen: boolean;
  type: PopUpEnum;
  message: string;
  title?: string;
}

const initialState = {
  popUpData: [],
};

export interface PopUpStore {
  popUpData: PopUp[];
  setPopUp: Action<PopUpStore, PopUp>;
  emptyPopUpData: Action<PopUpStore>;
  reset: Action<PopUpStore>;
}

export const popUpStore: PopUpStore = {
  ...initialState,
  setPopUp: action((state, payload) => {
    state.popUpData = state.popUpData.concat(payload);
  }),
  emptyPopUpData: action(state => {
    state.popUpData = [];
  }),
  reset: action(() => ({
    ...initialState,
  })),
};
