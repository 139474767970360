import { Role } from 'src/DTO/Role.type';
import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import { CreateRolePayload, TerminateRolePayload, TerminateRoleResponse } from './useRole.types';

export const useCreateRole: BaseHookRequest<Role, CreateRolePayload> = params => {
  const [{ postRequest }, state] = useApi<Role>(params);

  const createRole: BaseRequest<CreateRolePayload> = details => {
    postRequest<CreateRolePayload>('/manager/role/save', details?.variables);
  };

  return [createRole, state];
};

export const useActiveRoles: BaseHookRequest<Role[], null> = params => {
  const [{ getRequest }, state] = useApi<Role[]>(params);
  const getActiveRoles = () => {
    getRequest('/manager/role/active');
  };
  return [getActiveRoles, state];
};

export const useTerminateRole: BaseHookRequest<TerminateRoleResponse, TerminateRolePayload> =
  params => {
    const [{ postRequest }, state] = useApi<TerminateRoleResponse>(params);

    const terminateRole: BaseRequest<TerminateRolePayload> = details => {
      postRequest<TerminateRolePayload>('/manager/role/terminate', details?.variables);
    };

    return [terminateRole, state];
  };
