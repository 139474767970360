import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import {
  SealtedContractResponse,
  SealtedContractPayload,
  ProduceContractResponse,
  GetPregenrationContractPayload,
  ProduceContractPayload,
  GetPregeneratedContractResponse,
} from './useContract.types';

export const useSealedContract: BaseHookRequest<SealtedContractResponse, SealtedContractPayload> =
  params => {
    const [{ getRequest }, state] = useApi<SealtedContractResponse>(params);
    const getAllSealedContracts: BaseRequest<SealtedContractResponse> = () =>
      getRequest<SealtedContractPayload>('manager/contract/sealed');

    return [getAllSealedContracts, state];
  };

export const usePregenerateContract: BaseHookRequest<
  GetPregeneratedContractResponse,
  GetPregenrationContractPayload
> = params => {
  const [{ getRequest }, state] = useApi<GetPregeneratedContractResponse>(params);
  const pregenerateContract: BaseRequest<GetPregenrationContractPayload> = details =>
    getRequest<GetPregenrationContractPayload>(
      `manager/contract/pregenerate/${details?.variables.type}`,
      details?.variables,
    );

  return [pregenerateContract, state];
};

export const useProduceContract: BaseHookRequest<ProduceContractResponse, ProduceContractPayload> =
  params => {
    const [{ getRequest }, state] = useApi<ProduceContractResponse>(params);
    const produceContract: BaseRequest<ProduceContractPayload> = details =>
      getRequest<ProduceContractPayload>(
        `manager/contract/sign/${details?.variables.type}/${details?.variables.ipAddress}`,
        details?.variables,
      );

    return [produceContract, state];
  };
