import { action, Action } from 'easy-peasy';
import { Transaction } from 'src/DTO/Transaction.type';

const initialState = {
  accountData: { total: 0, transactionCount: 0 },
  transactions: [],
};

export interface CompanyAccountData {
  total: number;
  transactionCount: number;
}

export interface FinanceStore {
  accountData: CompanyAccountData;
  transactions: Transaction[];
  updateAccountData: Action<FinanceStore, CompanyAccountData>;
  updateTransactions: Action<FinanceStore, Transaction[]>;
  reset: Action<FinanceStore>;
}

export const financeStore: FinanceStore = {
  ...initialState,
  updateAccountData: action((state, payload) => {
    state.accountData = payload;
  }),
  updateTransactions: action((state, payload) => {
    state.transactions = payload;
  }),
  reset: action(() => ({
    ...initialState,
  })),
};
