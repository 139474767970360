/* eslint-disable indent */
import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Fade,
  Tabs,
  Tab,
  Card,
  CardContent,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { FinancialDetails } from '../FinancialDetails';
import { JobDetailData } from 'src/pages/Job/components/JobDetailData';
import { JobDetailsModalProps } from './types';
import colors from 'src/theme/base/colors';
import rgba from 'src/theme/functions/rgba';
import { Box } from '../Box';
import { Typography } from '../Typography';
import { JobInfoSection } from '../JobInfoSection';

export const JobDetailsModal: React.FC<JobDetailsModalProps> = ({
  shouldOpen,
  handleClose,
  job,
}) => {
  const { t } = useTranslation();

  const { info } = colors;

  const [tabValue, setTabValue] = useState<number>(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const isEnglishTabSelected = tabValue === 1;

  return (
    <Dialog
      open={shouldOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      keepMounted
      TransitionComponent={Fade}
      transitionDuration={400}
      PaperProps={{
        style: {
          maxWidth: '1100px',
          padding: 2,
        },
      }}>
      <DialogTitle>
        <Box sx={{ padding: 1.5 }}>
          <Typography
            variant="h5"
            fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
            sx={{ textAlign: 'center' }}>
            <Trans>jobPage.jobDetailsModal.title</Trans>
          </Typography>
        </Box>
        {job?.role?.localizedData?.en?.selectedLanguages?.hu &&
          job?.role?.localizedData?.en?.selectedLanguages?.en && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 1,
                  borderColor: 'divider',
                  '& .MuiTabs-indicator': {
                    backgroundColor: `${rgba(info.main, 1)}`,
                    height: '5px',
                    opacity: 0.8,
                  },
                }}>
                <Tab
                  label={t('jobPage.jobDetailsModal.hungarianTab')}
                  sx={{ padding: 1, fontWeight: TYPOGRAPHY_WEIGHT.BOLD }}
                />
                <Tab
                  label={t('jobPage.jobDetailsModal.englishTab')}
                  sx={{ padding: 1, fontWeight: TYPOGRAPHY_WEIGHT.BOLD }}
                />
              </Tabs>
            </Box>
          )}
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <JobInfoSection
                  label={t('jobPage.createRoleForm.jobDescription')}
                  content={
                    isEnglishTabSelected
                      ? job?.role?.localizedData?.en?.description ||
                        t('jobPage.createJobModal.notProvided')
                      : job?.role?.description || t('jobPage.createJobModal.notProvided')
                  }
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <JobInfoSection
                  label={`${t('jobPage.financialDetails')}:`}
                  content={
                    <FinancialDetails
                      hourlyGrossWage={job.wage.hourlyGrossAmount}
                      monthlyHours={job.monthlyHours}
                    />
                  }
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <JobInfoSection
                      label={`${t('jobPage.createJobModal.data')}:`}
                      content={<JobDetailData job={job} englishJob={isEnglishTabSelected} />}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
