/** 
  All of the routes for Della Manager are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import Office from 'src/assets/icons/Office';
import Home from 'src/assets/icons/Home';
import Settings from 'src/assets/icons/Settings';

export type Collapse = {
  name: string;
  key: string;
  component?: JSX.Element;
  route: string;
  href?: string;
  collapse?: Collapse[];
};

export type Route = {
  type: string;
  name: string;
  key: string;
  icon: JSX.Element;
  noCollapse?: boolean;
  route?: string;
  href?: string;
  collapse?: Collapse[];
  title?: string;
};

const routes = (): Route[] => {
  const { t } = useTranslation();

  return [
    {
      type: 'collapse',
      name: t('menu.home'),
      key: 'home',
      icon: <Home size="12px" />,
      noCollapse: true,
      route: '/',
    },
    {
      type: 'collapse',
      name: t('menu.management'),
      key: 'management',
      icon: <Settings size="12px" />,
      collapse: [
        {
          name: t('homeScreen.tabs.workersTabTitle'),
          key: 'workers',
          route: '/workers',
        },
      ],
    },
    {
      type: 'collapse',
      name: t('menu.jobs'),
      key: 'jobs',
      icon: <Office size="12px" />,
      noCollapse: true,
      route: '/job',
    },
  ];
};

export { routes };
