import React from 'react';

import { Trans } from 'react-i18next';

// @mui material components
import Card from '@mui/material/Card';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';

import { TYPOGRAPHY_COLORS, TYPOGRAPHY_TRANSFORM, TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { UserStoreData } from 'src/stores/UserStore';

type ProfileInfoCardProps = {
  user: UserStoreData;
};

function ProfileInfoCard({ user }: ProfileInfoCardProps) {
  if (!user.companies) {
    return null;
  }

  const company = user.companies[0];

  return (
    <Card sx={{ height: '100%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <Typography
          variant="h6"
          fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
          textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
          <Trans>profileScreen.info</Trans>
        </Typography>
      </Box>
      <Box p={2}>
        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>profileScreen.userEmail</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{user.email}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>profileScreen.userPhoneNumber</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{user.phoneNumber}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>profileScreen.companyPostCode</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{company.postCode}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>profileScreen.companyCity</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{company.city}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>profileScreen.companyAddressLine1</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{company.addressLine1}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>profileScreen.companyCountry</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{company.country}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>profileScreen.companyEmail</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{company.email}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>profileScreen.companyVatNumber</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{company.vatNumber}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" py={1} pr={2}>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              <Trans>profileScreen.companyNumber</Trans>
            </Typography>
            <Typography
              variant="button"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              &nbsp;{company.number}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export { ProfileInfoCard };
