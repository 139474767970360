import React, { forwardRef, createContext, useContext, useMemo } from 'react';

import { Box } from 'src/components/Box';

import PaginationRoot from './PaginationRoot';
import { PaginationRootProps } from './types';

// The Pagination main context
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore next-line
const Context = createContext();

const Pagination = forwardRef(
  (
    {
      item = false,
      variant = 'gradient',
      color = 'info',
      size = 'medium',
      active = false,
      children,
      ...rest
    }: PaginationRootProps,
    ref,
  ) => {
    const context: any = useContext(Context);
    const paginationSize = context ? context.size : null;
    const value = useMemo(() => ({ variant, color, size }), [variant, color, size]);

    return (
      <Context.Provider value={value as any}>
        {item ? (
          <PaginationRoot
            {...rest}
            ref={ref}
            variant={active ? context.variant : 'outlined'}
            color={active ? context.color : 'secondary'}
            iconOnly
            circular
            ownerState={{ variant, active, paginationSize }}>
            {children}
          </PaginationRoot>
        ) : (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ listStyle: 'none' }}>
            {children}
          </Box>
        )}
      </Context.Provider>
    );
  },
);

export { Pagination };
