import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { AppRouter } from './router/AppRouter';
import { PopUp } from 'src/components/PopUp/PopUp';
import theme from 'src/theme';
import './i18next';
import { ConfirmDialog } from './components/ConfirmDialog';
import { useStoreActions } from './stores';
import { UIControllerProvider } from 'src/context';

export const AppContext: React.FC = () => {
  const clearConfirm = useStoreActions(actions => actions.context.resetConfirmDialogState);

  useEffect(() => {
    clearConfirm();
  }, []);

  return (
    <UIControllerProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRouter />
        <PopUp />
        <ConfirmDialog />
      </ThemeProvider>
    </UIControllerProvider>
  );
};
