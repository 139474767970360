import React, { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Input } from 'src/components/Input';
import { Typography } from 'src/components/Typography';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { NewJobData } from 'src/api/hooks/useJob.types';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { EditModes } from '../Job.types';
import { LangContext } from 'src/context/LanguageContext';
import { useLocalizedData } from 'src/utils/useLocalizeData';

export interface CreateJobStep5Props {
  handlePreviousButtonClick: () => void;
  handleNextButtonClick: (jobData: NewJobData) => void;
  newJobData: NewJobData;
  editMode?: EditModes;
}

export const CreateJobStep5: React.FC<CreateJobStep5Props> = ({
  handlePreviousButtonClick,
  handleNextButtonClick,
  newJobData,
  editMode,
}) => {
  const { t } = useTranslation();
  const { selectedLanguages } = useContext(LangContext);

  const { localizedJob, localizedRole, enJob, huJob, shouldLocalize } = useLocalizedData(
    newJobData,
    editMode,
  );

  // LOCAL STATE
  const [arriveInfo, setArriveInfo] = useState<string>(newJobData?.arriveInfo || '');
  const [arriveInfoEn, setArriveInfoEn] = useState<string>(
    editMode && !localizedJob?.arriveInfo
      ? localizedRole?.arriveInfo || ''
      : localizedJob?.arriveInfo || '',
  );

  const [equipmentInfo, setEquipmentInfo] = useState<string>(newJobData?.equipmentInfo || '');
  const [equipmentInfoEn, setEquipmentInfoEn] = useState<string>(
    editMode && !localizedJob?.equipmentInfo
      ? localizedRole?.equipmentInfo || ''
      : localizedJob?.equipmentInfo || '',
  );

  const [arriveInfoError, setArriveInfoError] = useState<boolean>(false);
  const [arriveInfoEnError, setArriveInfoEnError] = useState<boolean>(false);

  const [equipmentInfoError, setEquipmentInfoError] = useState<boolean>(false);
  const [equipmentInfoEnError, setEquipmentInfoEnError] = useState<boolean>(false);

  const handleEquipmentInfo = (event: React.FormEvent<HTMLInputElement>) => {
    setEquipmentInfo(event.currentTarget.value);
    setEquipmentInfoError(event.currentTarget.value.trim().length < 15);
  };

  const handleEquipmentInfoEn = (event: React.FormEvent<HTMLInputElement>) => {
    setEquipmentInfoEn(event.currentTarget.value);
    setEquipmentInfoEnError(event.currentTarget.value.trim().length < 15);
  };

  const handleArriveInfo = (event: React.FormEvent<HTMLInputElement>) => {
    setArriveInfo(event.currentTarget.value);
    setArriveInfoError(event.currentTarget.value.trim().length < 15);
  };

  const handleArriveInfoEn = (event: React.FormEvent<HTMLInputElement>) => {
    setArriveInfoEn(event.currentTarget.value);
    setArriveInfoEnError(event.currentTarget.value.trim().length < 15);
  };

  const isFormValid = (): boolean => {
    let isValid = true;

    if (equipmentInfo && equipmentInfo.trim().length < 15) {
      setEquipmentInfoError(true);
      isValid = false;
    } else {
      setEquipmentInfoError(false);
    }

    if (equipmentInfoEn && equipmentInfoEn.trim().length < 15) {
      setEquipmentInfoEnError(true);
      isValid = false;
    } else {
      setEquipmentInfoEnError(false);
    }

    if (arriveInfo && arriveInfo.trim().length < 15) {
      setArriveInfoError(true);
      isValid = false;
    } else {
      setArriveInfoError(false);
    }

    if (arriveInfoEn && arriveInfoEn.trim().length < 15) {
      setArriveInfoEnError(true);
      isValid = false;
    } else {
      setArriveInfoEnError(false);
    }

    return isValid;
  };

  const onSubmit = () => {
    const localizedData = {
      ...localizedJob,
      selectedLanguages: !editMode ? selectedLanguages : localizedRole?.selectedLanguages,
      arriveInfo: arriveInfoEn,
      equipmentInfo: equipmentInfoEn,
    };
    if (isFormValid()) {
      handleNextButtonClick({
        ...newJobData,
        arriveInfo,
        equipmentInfo,
        ...(shouldLocalize && { localizedData }),
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box width="80%" textAlign="center" mx="auto" mb={4}>
          <Box mb={1}>
            <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
              <Trans>jobPage.createJobModal.instructions</Trans>
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        {!editMode ? (
          <>
            {selectedLanguages.hu && (
              <Box mb={4}>
                <Input
                  rows={5}
                  multiline
                  inputLabel={t('jobPage.createJobForm.equipmentInfo')}
                  placeholder={t('jobPage.createJobForm.equimentInfoPlaceHolder')}
                  value={equipmentInfo}
                  onChange={handleEquipmentInfo}
                  error={equipmentInfoError}
                  helperText={
                    equipmentInfoError ? t('validation.minLength', { number: 15 }) : undefined
                  }
                  onBlur={isFormValid}
                />
              </Box>
            )}
            {selectedLanguages.en && (
              <Box mb={4}>
                <Input
                  rows={5}
                  multiline
                  inputLabel={t('jobPage.createJobForm.equipmentInfoEn')}
                  placeholder={t('jobPage.createJobForm.equimentInfoPlaceHolder', { lng: 'en' })}
                  value={equipmentInfoEn}
                  onChange={handleEquipmentInfoEn}
                  error={equipmentInfoEnError}
                  helperText={
                    equipmentInfoEnError ? t('validation.minLength', { number: 15 }) : undefined
                  }
                  onBlur={isFormValid}
                />
              </Box>
            )}
          </>
        ) : (
          <>
            {(!localizedRole || huJob) && (
              <Box mb={4}>
                <Input
                  rows={5}
                  multiline
                  inputLabel={t('jobPage.createJobForm.equipmentInfo')}
                  placeholder={t('jobPage.createJobForm.equimentInfoPlaceHolder')}
                  value={equipmentInfo}
                  onChange={handleEquipmentInfo}
                  error={equipmentInfoError}
                  helperText={
                    equipmentInfoError ? t('validation.minLength', { number: 15 }) : undefined
                  }
                  onBlur={isFormValid}
                />
              </Box>
            )}
            {enJob && (
              <Box mb={4}>
                <Input
                  rows={5}
                  multiline
                  inputLabel={t('jobPage.createJobForm.equipmentInfoEn')}
                  placeholder={t('jobPage.createJobForm.equimentInfoPlaceHolder', { lng: 'en' })}
                  value={equipmentInfoEn}
                  onChange={handleEquipmentInfoEn}
                  error={equipmentInfoEnError}
                  helperText={
                    equipmentInfoEnError ? t('validation.minLength', { number: 15 }) : undefined
                  }
                  onBlur={isFormValid}
                />
              </Box>
            )}
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        {!editMode ? (
          <>
            {selectedLanguages.hu && (
              <Box mb={4}>
                <Input
                  rows={5}
                  multiline
                  inputLabel={t('jobPage.createJobForm.arriveInfo')}
                  placeholder={t('jobPage.createJobForm.arrivePlaceholder')}
                  value={arriveInfo}
                  onChange={handleArriveInfo}
                  error={arriveInfoError}
                  helperText={
                    arriveInfoError ? t('validation.minLength', { number: 15 }) : undefined
                  }
                  onBlur={isFormValid}
                />
              </Box>
            )}
            {selectedLanguages.en && (
              <Box mb={4}>
                <Input
                  rows={5}
                  multiline
                  inputLabel={t('jobPage.createJobForm.arriveInfoEn')}
                  placeholder={t('jobPage.createJobForm.arrivePlaceholder', { lng: 'en' })}
                  value={arriveInfoEn}
                  onChange={handleArriveInfoEn}
                  error={arriveInfoEnError}
                  helperText={
                    arriveInfoEnError ? t('validation.minLength', { number: 15 }) : undefined
                  }
                  onBlur={isFormValid}
                />
              </Box>
            )}
          </>
        ) : (
          <>
            {(!localizedRole || huJob) && (
              <Box mb={4}>
                <Input
                  rows={5}
                  multiline
                  inputLabel={t('jobPage.createJobForm.arriveInfo')}
                  placeholder={t('jobPage.createJobForm.arrivePlaceholder')}
                  value={arriveInfo}
                  onChange={handleArriveInfo}
                  error={arriveInfoError}
                  helperText={
                    arriveInfoError ? t('validation.minLength', { number: 15 }) : undefined
                  }
                  onBlur={isFormValid}
                />
              </Box>
            )}
            {enJob && (
              <Box mb={4}>
                <Input
                  rows={5}
                  multiline
                  inputLabel={t('jobPage.createJobForm.arriveInfoEn')}
                  placeholder={t('jobPage.createJobForm.arrivePlaceholder', { lng: 'en' })}
                  value={arriveInfoEn}
                  onChange={handleArriveInfoEn}
                  error={arriveInfoEnError}
                  helperText={
                    arriveInfoEnError ? t('validation.minLength', { number: 15 }) : undefined
                  }
                  onBlur={isFormValid}
                />
              </Box>
            )}
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box mr={3}>
            <Button onClick={handlePreviousButtonClick}>
              <Trans>generic.back</Trans>
            </Button>
          </Box>
          <Box ml={3}>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              type="submit"
              onClick={onSubmit}>
              <Trans>jobPage.createRoleForm.submitButtonLabel</Trans>
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
