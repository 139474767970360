import React from 'react';
import { Icon } from '@mui/material';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { TYPOGRAPHY_COLORS } from 'src/components/Typography/types';

export function ManagerApprovedCell({ managerConfirmedAt }: { managerConfirmedAt: Date }) {
  return (
    <Box display="flex">
      <Box mr={1}>
        <Icon color="success">done</Icon>
      </Box>
      <Typography variant="caption" color={TYPOGRAPHY_COLORS.TEXT}>
        <Trans>confirmationScreen.approved</Trans>:{' '}
        {format(new Date(managerConfirmedAt), 'yyyy-MM-dd')}
      </Typography>
    </Box>
  );
}
