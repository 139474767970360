import React, { useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Dialog,
  DialogContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Trans, useTranslation } from 'react-i18next';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { useTerminateWorkers } from 'src/api/hooks/useJob';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';

export const useStyles = makeStyles(() => ({
  list: {
    paddingLeft: '15px',
    marginTop: '10px',
    maxHeight: 500,
    overflow: 'scroll',
    '& .MuiButtonBase-root:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiListItem-gutters': {
      paddingLeft: 2,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
    },
  },
}));

export interface RemoveWorkersFromJobDialogProps {
  isOpen: boolean;
  handleCloseDialog: () => void;
  selectableWorkers: WorkerProfile[];
  jobId: string;
}

export const RemoveWorkersFromJobDialog: React.FC<RemoveWorkersFromJobDialogProps> = ({
  isOpen,
  selectableWorkers,
  handleCloseDialog,
  jobId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedWorkers, setSelectedWorkers] = useState<WorkerProfile[]>([]);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const handleToggle = (worker: WorkerProfile) => () => {
    const currentIndex = selectedWorkers.indexOf(worker);
    const newSelectedWorkers = [...selectedWorkers];

    if (currentIndex === -1) {
      newSelectedWorkers.push(worker);
    } else {
      newSelectedWorkers.splice(currentIndex, 1);
    }

    setSelectedWorkers(newSelectedWorkers);
  };

  const [terminateWorkers, { loading }] = useTerminateWorkers({
    onComplete: () => {
      window.location.reload();
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('jobPage.removeWorkersFromJobDialog.teminateWorkerFailed'),
      });
    },
  });

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Box mb={2} mt={2}>
              <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                <Trans>jobPage.removeWorkersFromJobDialog.title</Trans>
              </Typography>
              <Typography
                variant="button"
                color={TYPOGRAPHY_COLORS.TEXT}
                fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                <Trans>jobPage.removeWorkersFromJobDialog.helperText</Trans>
              </Typography>
            </Box>

            <Typography variant="h6">
              <Trans>
                {t('jobPage.removeWorkersFromJobDialog.selectedWorkers', {
                  number: selectedWorkers.length,
                })}
              </Trans>
            </Typography>
            <List className={classes.list}>
              {selectableWorkers.map(worker => {
                const labelId = `checkbox-list-label-${worker.id}`;

                return (
                  <ListItem
                    key={worker.id}
                    dense
                    button
                    onClick={handleToggle(worker)}
                    sx={{ marginBottom: '5px' }}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedWorkers.indexOf(worker) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={worker.name} />
                  </ListItem>
                );
              })}
            </List>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
              <Box mr={3}>
                <Button onClick={handleCloseDialog}>
                  <Trans>generic.cancel</Trans>
                </Button>
              </Box>
              <Box ml={3}>
                <Button
                  variant={BUTTON_VARIANT.GRADIENT}
                  color={BUTTON_COLOR.DARK}
                  disabled={loading}
                  type="submit"
                  onClick={() => {
                    terminateWorkers({
                      variables: {
                        processList: selectedWorkers.map(worker => ({
                          workerId: worker.id,
                          jobId,
                        })),
                      },
                    });
                  }}>
                  {t('jobPage.removeWorkersFromJobDialog.quitWorkerFromJob', {
                    number: selectedWorkers.length,
                  })}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
