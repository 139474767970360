import React from 'react';
import { Icon } from '@mui/material';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';

import { Typography } from 'src/components/Typography';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';

export interface TransactionMetaData {
  workerName: string;
  jobConfirmationYear: number;
  jobConfirmationMonth: number;
  jobConfirmationHoursWorked: number;
  jobConfirmationInvoiced: number;
}

export interface TransactionRowProps {
  description: string;
  amount: string;
  metaData: TransactionMetaData;
  createdAt: Date;
  color: BUTTON_COLOR | TYPOGRAPHY_COLORS;
  icon?: string;
}

export const TransactionRow: React.FC<TransactionRowProps> = ({
  description,
  amount,
  metaData,
  createdAt,
  color,
  icon,
}) => {
  const filledDescription = () => {
    if (metaData) {
      description = description.replace(
        /{{jobConfirmationYear}}/gm,
        String(metaData.jobConfirmationYear),
      );
      description = description.replace(
        /{{jobConfirmationMonth}}/gm,
        String(metaData.jobConfirmationMonth),
      );
      description = description.replace(/{{workerName}}/gm, String(metaData.workerName));
      description = description.replace(
        /{{jobConfirmationHoursWorked}}/gm,
        `${metaData.jobConfirmationHoursWorked}`,
      );
      description = description.replace(
        /{{jobConfirmationInvoiced}}/gm,
        ` ${metaData.jobConfirmationInvoiced} HUF`,
      );
    }
    return description;
  };

  return (
    <Box key={String(createdAt)} py={1} pr={2} mb={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Button
              variant={BUTTON_VARIANT.OUTLINED}
              color={color as BUTTON_COLOR}
              size={BUTTON_SIZE.SMALL}
              iconOnly
              circular>
              <Icon sx={{ fontWeight: 'bold' }}>{icon}</Icon>
            </Button>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="button" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM} gutterBottom>
              {filledDescription()}
            </Typography>
            <Typography variant="caption" color={TYPOGRAPHY_COLORS.TEXT}>
              <Trans i18nKey="financeScreen.transactionDate">
                <Typography>{{ date: format(new Date(createdAt), 'yyyy.MM.dd') }}</Typography>
              </Trans>
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="button"
          color={color as TYPOGRAPHY_COLORS}
          fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
          textGradient>
          {Number(amount).toLocaleString('hu-Hu')} HUF
        </Typography>
      </Box>
    </Box>
  );
};
