import React from 'react';
import { FormHelperText } from '@mui/material';
import { FieldProps, getIn } from 'formik';

import { Input } from 'src/components/Input';
import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { TYPOGRAPHY_WEIGHT, TYPOGRAPHY_TRANSFORM } from '../Typography/types';

export const FormTextArea: React.FC<FieldProps> = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const { label } = props as any;

  return (
    <Box mb={1}>
      {!!label && (
        <Box mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <Typography
            component="label"
            variant="caption"
            fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
            textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
            {label}
          </Typography>
        </Box>
      )}
      <Input rows={5} multiline {...field} value={field.value || ''} {...props} />
      <FormHelperText error>
        {getIn(touched, field.name) && getIn(errors, field.name)}
      </FormHelperText>
    </Box>
  );
};
