import React, { useState, useEffect, useCallback } from 'react';

// react-router components
import { Link } from 'react-router-dom';

// @mui core components
import { default as MUIAppBar } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Icon from '@mui/material/Icon';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';

import { NotificationItem } from 'src/components/NotificationItem';

// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarRow, navbarIconButton, navbarMobileMenu } from './styles';

import { UIController, setTransparentNavbar, setMiniSidenav } from 'src/context';

import { AppBarProps } from './types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { Trans } from 'react-i18next';
import { useLogout } from 'src/hooks/useLogout';
import { useNotifications } from 'src/api/hooks/useNotifications';
import { format } from 'date-fns';

function AppBar({ absolute = false, light = false, isMini = false }: AppBarProps) {
  const [navbarType, setNavbarType] = useState<string>();
  const [controller, dispatch]: any = UIController();
  const { transparentNavbar, fixedNavbar, miniSidenav } = controller;
  const [openMenu, setOpenMenu] = useState(false);

  const [getNotifications, { data: notifications }] = useNotifications();

  const shouldDisplayNotifications = notifications && notifications.length > 0;

  const { logout } = useLogout();

  useEffect(
    useCallback(() => {
      getNotifications();
    }, []),
    [],
  );

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event: any) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => {
    if (!shouldDisplayNotifications) {
      return;
    }

    return (
      <Menu
        anchorEl={openMenu as any}
        anchorReference={null as any}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}>
        {notifications?.map(({ summary, created, id }) => (
          <NotificationItem
            key={id}
            title={summary}
            date={format(new Date(created), 'yyyy-MM-dd hh:mm')}
          />
        ))}
      </Menu>
    );
  };

  return (
    <MUIAppBar
      position={(absolute ? 'absolute' : navbarType) as any}
      elevation={0}
      color="inherit"
      sx={theme => navbar(theme, { transparentNavbar, absolute, light })}>
      <Toolbar sx={theme => navbarContainer(theme) as any}>
        {isMini ? null : (
          <Box sx={(theme: any) => navbarRow(theme, { isMini })}>
            <Box color={light ? 'white' : 'inherit'}>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}>
                <Icon className={light ? 'text-white' : 'text-dark'}>
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              <Link to="/profile">
                <IconButton sx={navbarIconButton} size="small">
                  <AccountCircleIcon
                    sx={({ palette: { dark, white } }: any) => ({
                      color: light ? white.main : dark.main,
                    })}>
                    account_circle
                  </AccountCircleIcon>
                </IconButton>
              </Link>
              {shouldDisplayNotifications && (
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  onClick={handleOpenMenu}>
                  <NotificationsIcon className={light ? 'text-white' : 'text-dark'}>
                    notifications
                  </NotificationsIcon>
                </IconButton>
              )}
              <IconButton
                sx={(theme: any) => ({
                  ...navbarIconButton(theme),
                })}
                size="small"
                onClick={() => logout()}>
                <Typography
                  sx={{ fontSize: '1rem' }}
                  fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
                  color={light ? TYPOGRAPHY_COLORS.LIGHT : TYPOGRAPHY_COLORS.DARK}>
                  <Trans>generic.logout</Trans>
                </Typography>
              </IconButton>
              {renderMenu()}
            </Box>
          </Box>
        )}
      </Toolbar>
    </MUIAppBar>
  );
}

export { AppBar };
