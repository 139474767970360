import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  CircularProgress,
  Card,
  CardContent,
  FormHelperText,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation, Trans } from 'react-i18next';

import { Input } from 'src/components/Input';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import { useCalculateJobWage } from 'src/api/hooks/useJob';
import { JobWageResponse, NewJobData } from 'src/api/hooks/useJob.types';
import { useDebounce } from 'src/utils/useDebounce';
import { JobWage } from 'src/DTO/Job.type';
import { getMonthlyHours } from 'src/utils/getMonthlyHours';
import { EditModes } from '../Job.types';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { LangContext } from 'src/context/LanguageContext';
import { useLocalizedData } from 'src/utils/useLocalizeData';

const WAGE_CONSTANTS = {
  MIN_WAGE: 1534,
  MAX_WAGE: 10000,
};
const HOUR_CONSTANTS = {
  MIN_HOUR: 1,
  MAX_HOUR: 48,
};
const DEFAULT_WAGE = {
  hourlyCommissionAmount: 0,
  hourlyGrossAmount: 0,
  hourlyNetAmount: 0,
  hourlyPersonalTaxAmount: 0,
  invoicedHourlyAmount: 0,
  rake: 0,
  tax: 0,
  totalCommissionAmount: 0,
  totalGrossAmount: 0,
  totalInvoicedAmount: 0,
  totalNetAmount: 0,
  totalPersonalTaxAmount: 0,
};
const INIT_WAGE_DATA = {
  wageUnder25: {
    ...DEFAULT_WAGE,
  },

  wageOver25: {
    ...DEFAULT_WAGE,
  },
};
const { MIN_WAGE, MAX_WAGE } = WAGE_CONSTANTS;
const { MIN_HOUR, MAX_HOUR } = HOUR_CONSTANTS;

export interface CreateJobProps {
  handleNextButtonClick: (jobData: NewJobData, wage: JobWage) => void;
  handlePreviousButtonClick: () => void;
  newJobData?: NewJobData;
  editMode?: EditModes;
}

export const useStyles = makeStyles(() => ({
  slider: {
    paddingBottom: 10,
  },
}));

export const CreateJob: React.FC<CreateJobProps> = ({
  handleNextButtonClick,
  handlePreviousButtonClick,
  newJobData,
  editMode,
}) => {
  const { t } = useTranslation();

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [calculateJobWage, { loading }] = useCalculateJobWage({
    onComplete: res => {
      res && setData(res);
      setWageError(false);
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('jobPage.createJobForm.incorrectWage'),
      });
      setData(INIT_WAGE_DATA);
      setWageError(true);
    },
  });

  const defaultWeeklyHours = newJobData?.weeklyHours || MIN_HOUR;
  const defaultHourlyWage = newJobData?.hourlyGrossWage || MIN_WAGE;
  const { selectedLanguages } = useContext(LangContext);
  const { localizedJob, localizedRole, enJob, huJob, shouldLocalize } = useLocalizedData(
    newJobData,
    editMode,
  );

  /* LOCAL STATE */
  const [weeklyHours, setWeeklyHours] = useState<number>(defaultWeeklyHours);
  const [hourlyWage, setHourlyWage] = useState<number>(defaultHourlyWage);
  const [bonus, setBonus] = useState<string>(newJobData?.bonus || '');
  const [bonusEn, setBonusEn] = useState<string>(
    editMode && !localizedJob?.bonus ? localizedRole?.bonus || '' : localizedJob?.bonus || '',
  );
  const [data, setData] = useState<JobWageResponse>(INIT_WAGE_DATA);
  const [wageError, setWageError] = useState<boolean>(true);

  const [isMonthly, setIsMonthly] = useState<boolean>(false);
  const [hoursErrorState, setHoursErrorState] = useState<boolean>(false);

  const debouncedHourlyWage = useDebounce(hourlyWage, 1000);

  useEffect(() => {
    if (hourlyWage < MIN_WAGE || hourlyWage > MAX_WAGE) return setWageError(true);
    if (debouncedHourlyWage) {
      calculateJobWage({
        variables: {
          hourlyGrossWage: Number(debouncedHourlyWage),
          monthlyHours: isMonthly ? getMonthlyHours({ weeklyHours }) : weeklyHours,
        },
      });
    }
  }, [debouncedHourlyWage, isMonthly]);

  if (loading) {
    return (
      <Grid item xs={12}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  const {
    hourlyCommissionAmount,
    hourlyPersonalTaxAmount,
    totalNetAmount,
    totalCommissionAmount,
    totalPersonalTaxAmount,
    totalGrossAmount,
    hourlyNetAmount,
    invoicedHourlyAmount,
    totalInvoicedAmount,
  } = data.wageUnder25;

  const {
    hourlyCommissionAmount: hourlyCommissionAmountOver25,
    hourlyPersonalTaxAmount: hourlyPersonalTaxAmountOver25,
    totalNetAmount: totalNetAmountOver25,
    totalCommissionAmount: totalCommissionAmountOver25,
    totalPersonalTaxAmount: totalPersonalTaxAmountOver25,
    // hourlyGrossAmount: hourlyGrossAmountOver25,
    hourlyNetAmount: hourlyNetAmountOver25,
    invoicedHourlyAmount: invoicedHourlyAmountOver25,
    totalInvoicedAmount: totalInvoicedAmountOver25,
  } = data.wageOver25;

  const handleHoursChange = (event: React.FormEvent<HTMLInputElement>) => {
    let newHours = Number(event.currentTarget.value);

    if (isNaN(newHours)) return;

    const isWithinRange = newHours >= MIN_HOUR && newHours <= MAX_HOUR;

    setHoursErrorState(!isWithinRange);

    if (newHours > MAX_HOUR) {
      newHours = MAX_HOUR;
      event.currentTarget.value = String(newHours);
    }

    setWeeklyHours(newHours);
  };

  const handleHourlyNetWageChange = (event: React.FormEvent<HTMLInputElement>) => {
    let newValue = Number(event.currentTarget.value);

    if (isNaN(newValue)) return;

    const isWithinRange = newValue >= MIN_WAGE;

    setWageError(!isWithinRange);

    if (!isWithinRange) {
      setData(INIT_WAGE_DATA);
    }

    if (newValue > MAX_WAGE) {
      newValue = MAX_WAGE;
      event.currentTarget.value = String(newValue);
    }

    setHourlyWage(newValue);
  };

  const handleNavigateNext = () => {
    if (weeklyHours < MIN_HOUR) {
      setHoursErrorState(true);

      return;
    }

    const localizedData = {
      ...localizedJob,
      selectedLanguages: !editMode ? selectedLanguages : localizedRole?.selectedLanguages,
      bonus: bonusEn,
    };

    /* eslint-disable indent */
    const submitData = !!editMode
      ? {
          ...newJobData,
          hourlyGrossWage: hourlyWage,
          monthlyHours: getMonthlyHours({ weeklyHours }),
          weeklyHours,
          bonus,
          ...(shouldLocalize && { localizedData }),
        }
      : {
          ...newJobData,
          hourlyGrossWage: hourlyWage,
          monthlyHours: getMonthlyHours({ weeklyHours }),
          startDate: new Date().toISOString(),
          weeklyHours,
          bonus,
          ...(shouldLocalize && { localizedData }),
        };

    handleNextButtonClick(submitData, data.wageUnder25);
  };
  /* eslint-enable indent */

  const handleSetIsMonthly = (event: React.MouseEvent<HTMLElement>): void => {
    const { value } = event.currentTarget as HTMLInputElement;
    setIsMonthly(value === 'true' ? true : false);
  };

  const handleBonus = (event: React.FormEvent<HTMLInputElement>) => {
    setBonus(event.currentTarget.value);
  };

  const handleBonusEn = (event: React.FormEvent<HTMLInputElement>) => {
    setBonusEn(event.currentTarget.value);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Box mb={4}>
          <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
            <Trans>jobPage.createJobModal.expectedWorkingHours</Trans>
          </Typography>
          <Typography
            variant="body2"
            fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
            color={TYPOGRAPHY_COLORS.TEXT}>
            <Trans>jobPage.createJobModal.helperText1</Trans>
          </Typography>
        </Box>

        <Box mb={4}>
          <Input
            InputProps={{ inputProps: { min: MIN_HOUR, max: MAX_HOUR } }}
            onChange={handleHoursChange}
            value={weeklyHours.toString()}
            type="text"
          />
          {hoursErrorState && (
            <FormHelperText>
              <Trans>jobPage.createJobModal.helperText4</Trans>
            </FormHelperText>
          )}
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <ToggleButtonGroup value={isMonthly} exclusive onChange={handleSetIsMonthly} color="info">
            <ToggleButton value={false}>
              <Trans>generic.hourly</Trans>
            </ToggleButton>
            <ToggleButton value={true}>
              <Trans>generic.monthly</Trans>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {isMonthly && (
          <Box mb={2}>
            <Typography
              variant="body2"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              <Trans>jobPage.createJobModal.helperText2</Trans>
            </Typography>
          </Box>
        )}

        <Input
          error={hourlyWage < MIN_WAGE || hourlyWage > MAX_WAGE}
          inputLabel={t('jobPage.createJobForm.grossWage')}
          disabled={isMonthly}
          value={isMonthly ? totalGrossAmount.toString() : Number(hourlyWage).toString()}
          onChange={handleHourlyNetWageChange}
          type="text"
          InputProps={{ inputProps: { min: MIN_WAGE, max: MAX_WAGE } }}
          helperText={`Min ${MIN_WAGE} HUF, Max. ${MAX_WAGE} HUF`}
        />
        {wageError && (
          <Typography color={TYPOGRAPHY_COLORS.ERROR}>
            <Trans>jobPage.createJobForm.incorrectWage</Trans>
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        {!editMode ? (
          <>
            {selectedLanguages.hu && (
              <Box>
                <Input
                  rows={5}
                  multiline
                  value={bonus}
                  onChange={handleBonus}
                  inputLabel={t('jobPage.createJobForm.bonus')}
                  placeholder={t('jobPage.createJobForm.bonusPlaceholder')}
                />
              </Box>
            )}
            {selectedLanguages.en && (
              <Box>
                <Input
                  rows={5}
                  multiline
                  value={bonusEn}
                  onChange={handleBonusEn}
                  inputLabel={t('jobPage.createJobForm.bonusEn')}
                  placeholder={t('jobPage.createJobForm.bonusPlaceholder', { lng: 'en' })}
                />
              </Box>
            )}
          </>
        ) : (
          <>
            {(!localizedRole || huJob) && (
              <Box>
                <Input
                  rows={5}
                  multiline
                  value={bonus}
                  onChange={handleBonus}
                  inputLabel={t('jobPage.createJobForm.bonus')}
                  placeholder={t('jobPage.createJobForm.bonusPlaceholder')}
                />
              </Box>
            )}
            {enJob && (
              <Box>
                <Input
                  rows={5}
                  multiline
                  value={bonusEn}
                  onChange={handleBonusEn}
                  inputLabel={t('jobPage.createJobForm.bonusEn')}
                  placeholder={t('jobPage.createJobForm.bonusPlaceholder', { lng: 'en' })}
                />
              </Box>
            )}
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Typography variant="caption">{t('jobPage.createJobForm.under25')}</Typography>

                <Box mt={2}>
                  <Typography variant="subtitle2">
                    {t('jobPage.createJobForm.personalTax')}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {`${isMonthly ? totalPersonalTaxAmount : hourlyPersonalTaxAmount} Ft`}
                  </Typography>
                </Box>

                <Box mt={2}>
                  <Typography variant="subtitle2">{t('jobPage.createJobForm.netWage')}</Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {`${isMonthly ? totalNetAmount : hourlyNetAmount} Ft`}
                  </Typography>
                </Box>

                <Box mt={2}>
                  <Typography variant="subtitle2">
                    {t('jobPage.createJobForm.comission')}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {`${isMonthly ? totalCommissionAmount : hourlyCommissionAmount} Ft`}
                  </Typography>
                </Box>

                <Box mt={2}>
                  <Typography variant="subtitle2">
                    {t('jobPage.createJobForm.amountYouPay')}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {`${isMonthly ? totalInvoicedAmount : invoicedHourlyAmount} Ft`}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Typography variant="caption">{t('jobPage.createJobForm.over25')}</Typography>

                <Box mt={2}>
                  <Typography variant="subtitle2">
                    {t('jobPage.createJobForm.personalTax')}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {`${
                      isMonthly ? totalPersonalTaxAmountOver25 : hourlyPersonalTaxAmountOver25
                    } Ft`}
                  </Typography>
                </Box>

                <Box mt={2}>
                  <Typography variant="subtitle2">{t('jobPage.createJobForm.netWage')}</Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {`${isMonthly ? totalNetAmountOver25 : hourlyNetAmountOver25} Ft`}
                  </Typography>
                </Box>

                <Box mt={2}>
                  <Typography variant="subtitle2">
                    {t('jobPage.createJobForm.comission')}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {`${isMonthly ? totalCommissionAmountOver25 : hourlyCommissionAmountOver25} Ft`}
                  </Typography>
                </Box>

                <Box mt={2}>
                  <Typography variant="subtitle2">
                    {t('jobPage.createJobForm.amountYouPay')}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {`${isMonthly ? totalInvoicedAmountOver25 : invoicedHourlyAmountOver25} Ft`}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box mr={3}>
            <Button onClick={handlePreviousButtonClick}>
              <Trans>generic.previous</Trans>
            </Button>
          </Box>
          <Box ml={3}>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              type="submit"
              disabled={wageError}
              onClick={handleNavigateNext}>
              <Trans>jobPage.createRoleForm.submitButtonLabel</Trans>
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
