import { useHistory } from 'react-router-dom';
import { useStoreActions } from 'src/stores';

export const useLogout = () => {
  const history = useHistory();

  const resetAuthStore = useStoreActions(actions => actions.auth.reset);
  const resetUserStore = useStoreActions(actions => actions.user.reset);
  const resetWorkerStore = useStoreActions(actions => actions.workers.reset);
  const resetNotificationStore = useStoreActions(actions => actions.popUp.reset);
  const resetFinanceStore = useStoreActions(actions => actions.finance.reset);
  const resetJobStore = useStoreActions(actions => actions.jobs.reset);
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();

    resetAuthStore();
    resetUserStore();
    resetWorkerStore();
    resetNotificationStore();
    resetFinanceStore();
    resetJobStore();

    history.push('/auth');
  };

  return { logout };
};
