import React from 'react';

// @mui material components
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';

import { Box } from 'src/components/Box';

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from './styles/sideNavCollapse';

import { UIController } from 'src/context';
import { SideNavCollapseProps } from './types';

function SidenavCollapse({
  icon,
  name,
  children = false,
  active = false,
  noCollapse = false,
  open = false,
  ...rest
}: SideNavCollapseProps) {
  const [controller]: any = UIController();
  const { miniSidenav, transparentSidenav, sidenavColor } = controller;

  return (
    <>
      <ListItem component="li">
        <Box {...rest} sx={(theme: any) => collapseItem(theme, { active, transparentSidenav })}>
          <ListItemIcon
            sx={theme => collapseIconBox(theme, { active, transparentSidenav, sidenavColor })}>
            {typeof icon === 'string' ? (
              <Icon sx={theme => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={theme => collapseText(theme, { miniSidenav, transparentSidenav, active })}
          />

          <Icon
            sx={theme =>
              collapseArrow(theme, { noCollapse, transparentSidenav, miniSidenav, open })
            }>
            expand_less
          </Icon>
        </Box>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

export default SidenavCollapse;
