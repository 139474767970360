import React from 'react';
import { useStoreState } from '../../stores';
import { Grid } from '@mui/material';

import { DashboardLayout } from 'src/components/layouts/LayoutContainers/DashboardLayout';
import { Box } from 'src/components/Box';
import { Header } from 'src/pages/Profile/components/Header';
import { Footer } from 'src/components/Footer';
import { ProfileInfoCard } from 'src/components/Cards/ProfileInfoCard';

export const ProfilePage: React.FC = () => {
  const user = useStoreState(data => data.user.data);

  return (
    <DashboardLayout>
      <Header title={user.name || ''} subTitle={(user.companies && user.companies[0].name) || ''} />
      <Box mt={5} mb={3} sx={() => ({ marginLeft: 0 })}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ProfileInfoCard user={user} />
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </DashboardLayout>
  );
};
