import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ProtectedRoute } from './ProtectedRoute';

import { SignIn } from 'src/pages/SignIn/';
import { SignUp } from 'src/pages/SignUp';
import { Home } from '../pages/Home';
import { JobPage, JobDetailPage } from 'src/pages/Job';
import { ProfilePage, WorkerProfilePage } from 'src/pages/Profile';
import { NotInvited } from 'src/pages/Auth/NotInvited';
import { TokenAuth } from 'src/pages/Auth/TokenAuth';
import { FinancePage } from 'src/pages/Finance';
import { ConfirmationsPage } from 'src/pages/Confirmations';
import { WorkersPage } from 'src/pages/Workers';
import { PageNotFound } from 'src/pages/PageNotFound/PageNotFound';
import { WorkerDocuments } from 'src/components/WorkerDocuments/WorkerDocuments';

export const AppRouter: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route component={SignIn} path="/auth" />
        <Route component={SignUp} path="/register" />
        <Route component={NotInvited} path="/not-invited" />
        <Route component={TokenAuth} path="/tokenAuth/:token" />
        <ProtectedRoute component={JobDetailPage} path="/job/:jobId" />
        <ProtectedRoute component={JobPage} path="/job" />
        <ProtectedRoute component={ProfilePage} path="/profile" />
        <ProtectedRoute component={WorkerDocuments} path="/worker/:id/documents" />
        <ProtectedRoute component={WorkerProfilePage} path="/worker/:id" />
        <ProtectedRoute component={FinancePage} path="/finance" />
        <ProtectedRoute component={ConfirmationsPage} path="/confirmations" />
        <ProtectedRoute component={WorkersPage} path="/workers" />
        <ProtectedRoute component={Home} exact path="/" />
        <Route component={PageNotFound} path="*" />
      </Switch>
    </Router>
  );
};
