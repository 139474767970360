import React, { useEffect, useRef } from 'react';

// threejs components
import * as THREE from 'three';
import { OrbitControls } from '@three-ts/orbit-controls';

import { Box } from 'src/components/Box';

type GlobeProps = {
  canvasStyle?: any;
  display?: any;
  position?: any;
  top?: any;
  right?: any;
  mt?: any;
  mr?: any;
};

function Globe({ canvasStyle = {}, ...rest }: GlobeProps) {
  const globeRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    function createGlobe() {
      const container = globeRef.current;
      const canvas = canvasRef.current;

      const globeRadius = 100;
      const globeWidth = 4098 / 2;
      const globeHeight = 1968 / 2;

      function convertFlatCoordsToSphereCoords(x: number, y: number) {
        let latitude = ((x - globeWidth) / globeWidth) * -180;
        let longitude = ((y - globeHeight) / globeHeight) * -90;
        latitude = (latitude * Math.PI) / 180;
        longitude = (longitude * Math.PI) / 180;
        const radius = Math.cos(longitude) * globeRadius;

        return {
          x: Math.cos(latitude) * radius,
          y: Math.sin(longitude) * globeRadius,
          z: Math.sin(latitude) * radius,
        };
      }

      function makeMagic(points: any) {
        if (!container) {
          return;
        }

        const { width, height } = (container as any).getBoundingClientRect();

        // 1. Setup scene
        const scene = new THREE.Scene();
        // 2. Setup camera
        const camera = new THREE.PerspectiveCamera(45, width / height);
        // 3. Setup renderer
        const renderer = new THREE.WebGLRenderer({
          canvas: canvas as any,
          antialias: true,
        });

        renderer.setSize(width, height);
        // 4. Add points to canvas
        // - Single geometry to contain all points.
        const mergedGeometry = new (THREE as any).Geometry();
        // - Material that the dots will be made of.
        const pointGeometry = new THREE.SphereGeometry(0.5, 1, 1);
        const pointMaterial = new THREE.MeshBasicMaterial({
          color: '#989db5',
        });

        // eslint-disable-next-line no-restricted-syntax
        for (const point of points) {
          const { x, y, z } = convertFlatCoordsToSphereCoords(point.x, point.y);

          if (x && y && z) {
            pointGeometry.translate(x, y, z);
            mergedGeometry.merge(pointGeometry);
            pointGeometry.translate(-x, -y, -z);
          }
        }

        const globeShape = new THREE.Mesh(mergedGeometry, pointMaterial);
        scene.add(globeShape);

        (container as any).classList.add('peekaboo');

        // Setup orbital controls
        (camera as any).orbitControls = new OrbitControls(camera, canvas as any);
        (camera as any).orbitControls.enableKeys = false;
        (camera as any).orbitControls.enablePan = false;
        (camera as any).orbitControls.enableZoom = false;
        (camera as any).orbitControls.enableDamping = false;
        (camera as any).orbitControls.enableRotate = true;
        (camera as any).orbitControls.autoRotate = true;
        camera.position.z = -265;

        function animate() {
          // orbitControls.autoRotate is enabled so orbitControls.update
          // must be called inside animation loop.
          (camera as any).orbitControls.update();
          requestAnimationFrame(animate);
          renderer.render(scene, camera);
        }
        animate();
      }

      function hasWebGL() {
        const gl =
          (canvas as any).getContext('webgl') || (canvas as any).getContext('experimental-webgl');
        if (gl && gl instanceof WebGLRenderingContext) {
          return true;
        }
        return false;
      }

      function init() {
        if (hasWebGL()) {
          window
            .fetch(
              'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard-pro/assets/js/points.json',
            )
            .then(response => response.json())
            .then(data => makeMagic(data.points));
        }
      }

      return navigator.onLine && init();
    }

    createGlobe();
  }, []);

  return (
    <Box ref={globeRef} {...rest}>
      <canvas
        ref={canvasRef}
        width="700"
        height="600"
        style={{ outline: 'none', ...canvasStyle }}
      />
    </Box>
  );
}

export { Globe };
