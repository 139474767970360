export enum BoxVariants {
  CONTAINED = 'contained',
  GRADIENT = 'gradient',
}

export type BoxRootProps = {
  variant?: BoxVariants;
  bgColor?: string;
  color?: string;
  opacity?: number;
  borderRadius?: string;
  shadow?: string;
  children?: any;
  p?: number;
  mb?: any;
  textAlign?: string;
  component?: any;
  role?: string;
  display?: any;
  mt?: any;
  alignItems?: any;
  flexDirection?: any;
  justifyContent?: string;
  height?: string;
  pt?: number;
  width?: string;
  position?: string;
  m?: number;
  px?: number;
  sx?: any;
  src?: any;
  alt?: string;
  maxWidth?: string;
  topl?: number;
  left?: number | string;
  minHeight?: string;
  minWidth?: string;
  lineHeight?: number;
  fontSize?: number | string;
  pb?: number;
  mr?: number;
  ml?: any;
  pl?: number;
  pr?: number;
  top?: number;
  right?: number | string;
  to?: string;
  onClick?: () => void;
  my?: number;
  mx?: number | string;
  py?: any;
  flexWrap?: string;
  border?: string;
  borderBottom?: string;
  dangerouslySetInnerHTML?: { __html: string };
};
