export enum CONTRACT_TYPE {
  STUDENT_ORG_WITH_COMPANY = 'StudentOrgWithCompany',
}

export interface SealtedContract {
  id: string;
  contractType: CONTRACT_TYPE;
  userSealedAt: Date;
  dellaSealedAt: Date;
  contractText: string;
  expires?: Date;
  createdAt: Date;
  updatedAt: Date;
}

export type SealtedContractResponse = SealtedContract[] | undefined;

export type SealtedContractPayload = [];

export interface GetPregeneratedContractResponse {
  html: string;
}

export interface GetPregenrationContractPayload {
  type: CONTRACT_TYPE;
}

export interface ProduceContractPayload {
  type: CONTRACT_TYPE;
  ipAddress: string;
}

export interface ProduceContractResponse {
  isSuccess: boolean;
}
