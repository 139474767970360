import React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import { FieldProps, getIn } from 'formik';

import { Input } from 'src/components/Input';

export const FormInput: React.FC<FieldProps> = ({ field, form: { touched, errors }, ...props }) => (
  <>
    <Input
      error={Boolean(getIn(touched, field.name) && getIn(errors, field.name))}
      {...field}
      value={field.value || ''}
      {...props}
    />
    <FormHelperText error>{getIn(touched, field.name) && getIn(errors, field.name)}</FormHelperText>
  </>
);
