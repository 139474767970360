import { Transaction } from 'src/DTO/Transaction.type';
import { useApi } from './useApi';
import { BaseHookRequest, BaseRequest } from './useApi.types';
import { GetCompanyAccountResponse, TransactionsPayload } from './useFinance.types';

export const useCompanyAccount: BaseHookRequest<GetCompanyAccountResponse, null> = params => {
  const [{ getRequest }, state] = useApi<GetCompanyAccountResponse>(params);
  const getCompanyAccount = () => getRequest('manager/company/transfer/total');
  return [getCompanyAccount, state];
};

export const useCompanyTransactions: BaseHookRequest<Transaction[], TransactionsPayload> =
  params => {
    const [{ getRequest }, state] = useApi<Transaction[]>(params);
    const getCompanyTransactions: BaseRequest<TransactionsPayload> = details =>
      getRequest<TransactionsPayload>(
        `manager/company/transfer/last/${details?.variables.skip}/${details?.variables.limit}`,
      );
    return [getCompanyTransactions, state];
  };

export const useCompanyAllTransactions: BaseHookRequest<Transaction[], TransactionsPayload> =
  params => {
    const [{ getRequest }, state] = useApi<Transaction[]>(params);
    const getCompanyAllTransactions: BaseRequest<TransactionsPayload> = () =>
      getRequest<TransactionsPayload>('manager/company/transfer/all');
    return [getCompanyAllTransactions, state];
  };
