import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { MainLayout } from '../components/MainLayout';
import { StudentOrgContractDialog } from 'src/components/StudentOrgContractDialog';
import { useSealedContract } from 'src/api/hooks/useContract';
import { useMe } from 'src/api/hooks/useAuth';
import { useLogout } from 'src/hooks/useLogout';
import { useStoreActions, useStoreState } from 'src/stores';

const ProtectedRoute = ({ component: Component, ...routerProps }: any) => {
  const [contractModalOpen, setContractModalOpen] = useState(false);

  const updateUser = useStoreActions(actions => actions.user.update);

  const isLoggedIn = useStoreState(state => state.user.data.isLogedIn);

  const { logout } = useLogout();

  const [getMe] = useMe({
    onComplete: res => {
      if (!res?.managerProfile) {
        logout();

        return;
      }

      updateUser({
        ...res.managerProfile,
        isLogedIn: true,
      });
    },
    onError: () => {
      logout();

      return;
    },
  });

  const [getAllSealedContracts] = useSealedContract({
    onComplete: contracts => {
      if (!contracts || contracts.length === 0) {
        setContractModalOpen(true);
      }
    },
  });

  useEffect(() => {
    getMe();
    getAllSealedContracts();
  }, []);

  return (
    <>
      <MainLayout>
        <Route
          {...routerProps}
          render={props => (isLoggedIn ? <Component {...props} /> : <Redirect to="/auth" />)}
        />
      </MainLayout>
      <StudentOrgContractDialog open={contractModalOpen} setOpen={setContractModalOpen} />
    </>
  );
};

export { ProtectedRoute };
