import { NewJobData } from 'src/api/hooks/useJob.types';
import { Job } from 'src/DTO/Job.type';

interface GetLanguage {
  (
    jobData: Job | NewJobData | undefined,
    defaultLanguage: string,
    excludeLanguage: string,
  ): boolean;
}

export const getLanguage: GetLanguage = (jobData, defaultLanguage, excludeLanguage) => {
  const localizedData = jobData?.role?.localizedData?.[defaultLanguage];
  return !!localizedData && !localizedData?.selectedLanguages?.[excludeLanguage];
};
