import React, { useContext, useState } from 'react';
import { Grid, FormGroup, FormControlLabel, Switch } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Trans, useTranslation } from 'react-i18next';
import { Boy, Girl } from '@mui/icons-material';

import { Input } from 'src/components/Input';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import { EditModes, GenderTypes, JobTypes } from '../Job.types';
import { NewJobData } from 'src/api/hooks/useJob.types';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import customButtonStyles from 'src/theme/components/customButtonStyles';
import { LangContext } from 'src/context/LanguageContext';
import { useLocalizedData } from 'src/utils/useLocalizeData';

const DEFAULT_REQUIRED_GENDERS = [GenderTypes.Male, GenderTypes.Female];
const maxLength = 4;

export interface CreateJobStep1Props {
  handleNextButtonClick: (jobData: NewJobData) => void;
  handlePreviousButtonClick: () => void;
  newJobData: NewJobData;
  editMode?: EditModes;
}

const getDefaultGenderTypes = (genderTypes?: string | GenderTypes[]): GenderTypes[] => {
  if (!genderTypes) {
    return DEFAULT_REQUIRED_GENDERS;
  }

  if (typeof genderTypes === 'string' || genderTypes instanceof String) {
    return genderTypes.split(',') as GenderTypes[];
  }

  return genderTypes;
};

export const CreateJobStep1: React.FC<CreateJobStep1Props> = ({
  handleNextButtonClick,
  handlePreviousButtonClick,
  newJobData,
  editMode,
}) => {
  const { t } = useTranslation();

  const { selectedLanguages } = useContext(LangContext);

  const { localizedJob, localizedRole, enJob, huJob, shouldLocalize } = useLocalizedData(
    newJobData,
    editMode,
  );

  const [jobType, setJobType] = useState<JobTypes>(newJobData?.jobType || JobTypes.LongtermJob);
  const [timeSchedule, setTimeSchedule] = useState<string>(newJobData?.timeSchedule || '');
  const [timeScheduleEn, setTimeScheduleEn] = useState<string>(
    editMode && !localizedJob?.timeSchedule
      ? localizedRole?.timeSchedule || ''
      : localizedJob?.timeSchedule || '',
  );

  const [workerCount, setWorkerCount] = useState<string>(newJobData?.workerCount || '');
  const [startNow, setStartNow] = useState<boolean>(newJobData?.startNow || true);
  const [genderTypes, setGenderTypes] = useState<GenderTypes[]>(
    getDefaultGenderTypes(newJobData?.genderTypes),
  );
  const [timeScheduleError, setTimeScheduleError] = useState<boolean>(false);
  const [timeScheduleEnError, setTimeSchedulEnError] = useState<boolean>(false);
  const [workerCountError, setWorkerCountError] = useState<boolean>(false);

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newJobType: JobTypes) => {
    setJobType(newJobType);
  };

  const handleTimeScheduleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setTimeSchedule(event.currentTarget.value);
    setTimeScheduleError(
      event.currentTarget.value.length > 0 && event.currentTarget.value.length < 15,
    );
  };

  const handleTimeScheduleEnChange = (event: React.FormEvent<HTMLInputElement>) => {
    setTimeScheduleEn(event.currentTarget.value);
    setTimeSchedulEnError(
      event.currentTarget.value.length > 0 && event.currentTarget.value.length < 15,
    );
  };

  const handleSetStart = (event: React.FormEvent<HTMLInputElement>) => {
    setStartNow(event.currentTarget.checked);
  };

  const handleWorkerCount = (event: React.FormEvent<HTMLInputElement>) => {
    const onlyNumbers = event.currentTarget.value.replace(/[^0-9]/g, '');

    let workerCount = onlyNumbers.replace(/^0+/, '');

    if (workerCount.length > maxLength) {
      workerCount = workerCount.slice(0, maxLength);
    } else {
      setWorkerCount(workerCount);
      setWorkerCountError(workerCount.length === 0);
    }
  };

  const handleToggleGenderMale = () => {
    handleToggleGenderTypes(GenderTypes.Male);
  };

  const handleToggleGenderFemale = () => {
    handleToggleGenderTypes(GenderTypes.Female);
  };

  const onSubmit = () => {
    if (!timeScheduleError && !workerCountError) {
      const localizedData = {
        ...localizedJob,
        selectedLanguages: !editMode ? selectedLanguages : localizedRole?.selectedLanguages,
        timeSchedule: timeScheduleEn,
      };

      handleNextButtonClick({
        ...newJobData,
        jobType,
        timeSchedule,
        workerCount,
        startNow,
        genderTypes,
        ...(shouldLocalize && { localizedData }),
      });
    }
  };

  const handleToggleGenderTypes = (genderType: GenderTypes) => {
    const alreadyActive = genderTypes.includes(genderType);

    if (alreadyActive) {
      const newGenderTypes = genderTypes.filter(type => type !== genderType);

      if (newGenderTypes.length === 0) {
        return;
      }

      setGenderTypes(newGenderTypes);
    } else {
      const newGenderTypes = [...genderTypes, genderType];

      setGenderTypes(newGenderTypes);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Box mb={4} display="flex" justifyContent="center">
          <ToggleButtonGroup value={jobType} exclusive onChange={handleChange} color="info">
            <ToggleButton value={JobTypes.LongtermJob}>
              <Trans>jobPage.createJobForm.longTermJob</Trans>
            </ToggleButton>
            <ToggleButton value={JobTypes.ShortTermJob}>
              <Trans>jobPage.createJobForm.shortTermJob</Trans>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box mb={2}>
          <FormGroup>
            <FormControlLabel
              control={<Switch color="secondary" checked={startNow} onChange={handleSetStart} />}
              label={t('jobPage.createJobForm.startNow') as string}
              sx={{ paddingLeft: 2 }}
            />
          </FormGroup>
        </Box>
        {!editMode ? (
          <>
            {selectedLanguages.hu && (
              <Box mb={4}>
                <Input
                  error={timeScheduleError}
                  inputLabel={t('jobPage.createJobForm.timeSchedule')}
                  value={timeSchedule}
                  onChange={handleTimeScheduleChange}
                  helperText={t('validation.minLength', { number: 15 })}
                />
              </Box>
            )}
            {selectedLanguages.en && (
              <Box mb={4}>
                <Input
                  error={timeScheduleEnError}
                  inputLabel={t('jobPage.createJobForm.timeScheduleEn')}
                  value={timeScheduleEn}
                  onChange={handleTimeScheduleEnChange}
                  helperText={t('validation.minLength', { number: 15 })}
                />
              </Box>
            )}
          </>
        ) : (
          <>
            {(!localizedRole || huJob) && (
              <Box mb={4}>
                <Input
                  error={timeScheduleError}
                  inputLabel={t('jobPage.createJobForm.timeSchedule')}
                  value={timeSchedule}
                  onChange={handleTimeScheduleChange}
                  helperText={t('validation.minLength', { number: 15 })}
                />
              </Box>
            )}
            {enJob && (
              <Box mb={4}>
                <Input
                  inputLabel={t('jobPage.createJobForm.timeScheduleEn')}
                  value={timeScheduleEn}
                  onChange={handleTimeScheduleEnChange}
                  helperText={t('validation.minLength', { number: 15 })}
                />
              </Box>
            )}
          </>
        )}

        <Box mb={6}>
          <Input
            error={workerCountError}
            inputLabel={t('jobPage.createJobForm.workerCount')}
            value={workerCount}
            onChange={handleWorkerCount}
            helperText={t('validation.minAndMaxlength', { min: 1, max: 4 })}
            type="text"
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box textAlign="center">
            <Button
              color={BUTTON_COLOR.SECONDARY}
              variant={
                !genderTypes.includes(GenderTypes.Female)
                  ? BUTTON_VARIANT.OUTLINED
                  : BUTTON_VARIANT.CONTAINED
              }
              onClick={handleToggleGenderFemale}
              sx={customButtonStyles}>
              <Boy />
            </Button>
          </Box>
          <Box textAlign="center">
            <Button
              color={BUTTON_COLOR.SECONDARY}
              variant={
                !genderTypes.includes(GenderTypes.Male)
                  ? BUTTON_VARIANT.OUTLINED
                  : BUTTON_VARIANT.CONTAINED
              }
              onClick={handleToggleGenderMale}
              sx={customButtonStyles}>
              <Girl />
            </Button>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" mb={4} mt={2}>
          <Typography variant="h6">
            {genderTypes.length === 2 && <Trans>jobPage.createJobForm.girlsAndBoys</Trans>}
            {!genderTypes.includes(GenderTypes.Male) && <Trans>jobPage.createJobForm.girls</Trans>}
            {!genderTypes.includes(GenderTypes.Female) && <Trans>jobPage.createJobForm.boys</Trans>}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box>
          <Typography variant="h6">
            <Trans>jobPage.createJobForm.breakAlertTitle</Trans>
          </Typography>
          <Trans>jobPage.createJobForm.breakAlert</Trans>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box mr={3}>
            <Button onClick={handlePreviousButtonClick}>
              <Trans>generic.back</Trans>
            </Button>
          </Box>
          <Box ml={3}>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              type="submit"
              onClick={() => onSubmit()}>
              <Trans>jobPage.createRoleForm.submitButtonLabel</Trans>
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
