import React from 'react';
/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo } from 'react';

const UI = createContext(null);

// Setting custom name for the context which is visible on react dev tools
UI.displayName = 'DellaUIContext';

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'MINI_SIDENAV': {
      return { ...state, miniSidenav: action.value };
    }
    case 'TRANSPARENT_SIDENAV': {
      return { ...state, transparentSidenav: action.value };
    }
    case 'SIDENAV_COLOR': {
      return { ...state, sidenavColor: action.value };
    }
    case 'TRANSPARENT_NAVBAR': {
      return { ...state, transparentNavbar: action.value };
    }
    case 'FIXED_NAVBAR': {
      return { ...state, fixedNavbar: action.value };
    }
    case 'OPEN_CONFIGURATOR': {
      return { ...state, openConfigurator: action.value };
    }
    case 'DIRECTION': {
      return { ...state, direction: action.value };
    }
    case 'LAYOUT': {
      return { ...state, layout: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UIControllerProvider({ children }: any) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: true,
    sidenavColor: 'info',
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: 'ltr',
    layout: 'dashboard',
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <UI.Provider value={value as any}>{children}</UI.Provider>;
}

// Soft UI Dashboard PRO React custom hook for using context
function UIController() {
  const context = useContext(UI);

  if (!context) {
    throw new Error('UIController should be used inside the UIControllerProvider.');
  }

  return context;
}

// Context module functions
const setMiniSidenav = (dispatch: any, value: any) => dispatch({ type: 'MINI_SIDENAV', value });
const setTransparentSidenav = (dispatch: any, value: any) =>
  dispatch({ type: 'TRANSPARENT_SIDENAV', value });
const setSidenavColor = (dispatch: any, value: any) => dispatch({ type: 'SIDENAV_COLOR', value });
const setTransparentNavbar = (dispatch: any, value: any) =>
  dispatch({ type: 'TRANSPARENT_NAVBAR', value });
const setFixedNavbar = (dispatch: any, value: any) => dispatch({ type: 'FIXED_NAVBAR', value });
const setOpenConfigurator = (dispatch: any, value: any) =>
  dispatch({ type: 'OPEN_CONFIGURATOR', value });
const setDirection = (dispatch: any, value: any) => dispatch({ type: 'DIRECTION', value });
const setLayout = (dispatch: any, value: any) => dispatch({ type: 'LAYOUT', value });

export {
  UIControllerProvider,
  UIController,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
};
