import React from 'react';

import { SnackBar } from 'src/components/SnackBar';
import { useStoreActions, useStoreState } from 'src/stores';

export const PopUp: React.FC = () => {
  const emptyPopUpData = useStoreActions(action => action.popUp.emptyPopUpData);
  const popUpData = useStoreState(state => state.popUp.popUpData);

  const handleOnClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    emptyPopUpData();
  };

  console.log(popUpData);

  return (
    <>
      {popUpData?.map((notification, index) => (
        <SnackBar
          key={index}
          color={notification.type as any}
          icon="notifications"
          title={notification.title || ''}
          content={notification.message}
          close={handleOnClose}
          autoHideDuration={3000}
          dateTime={''}
          open={notification.isOpen}
        />
      ))}
    </>
  );
};
