import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Box } from 'src/components/Box';

import { POOL_STATUS } from 'src/DTO/PoolStatus.enum';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useStoreActions } from 'src/stores';
import {
  useArchiveWorker,
  useRemoveWorkerRequest,
  useResendInviteRequest,
} from 'src/api/hooks/useWorker';
import {
  useAcceptJobApplication,
  useCancelJobApplication,
  useTerminateWorker,
} from 'src/api/hooks/useJob';
import { WorkerActionsCellProps } from './types';
import { ORG_TYPE } from 'src/DTO/OrganizationType.enum';
import { WorkerActionsButton } from 'src/components/WorkerActionsButton';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';

export const WorkerActionsCell: React.FC<WorkerActionsCellProps> = ({
  worker,
  organizationType,
}) => {
  const { t } = useTranslation();
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const jobId = worker?.jobs?.[0]?.id;
  const applicationId = worker?.applications?.[0]?.id;

  const [archiveWorker, { loading: archiveWorkerLoading }] = useArchiveWorker({
    onComplete: () => window.location.reload(),
    onError: () =>
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersPage.actions.archiveWorker.error'),
      }),
  });

  const [resendWorkerInvite, { loading: resendWorkerInviteLoading }] = useResendInviteRequest({
    onComplete: () => window.location.reload(),
    onError: () =>
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersPage.actions.resendInvite.error'),
      }),
  });

  const [removeWorkerFromCompany, { loading: removeWorkerFromCompanyLoading }] =
    useRemoveWorkerRequest({
      onComplete: () => window.location.reload(),
      onError: () =>
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.error,
          message: t('workersPage.actions.removeWorkerFromCompany.error'),
        }),
    });

  const [terminateWorker, { loading: terminateWorkersLoading }] = useTerminateWorker({
    onComplete: () => window.location.reload(),
    onError: () =>
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersPage.actions.terminateWorker.error'),
      }),
  });

  const [declineApplication, { loading: cancelApplicationLoading }] = useCancelJobApplication({
    onComplete: () => window.location.reload(),
    onError: () =>
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersPage.actions.declineApplication.error'),
      }),
  });

  const [acceptApplication, { loading: acceptApplicationLoading }] = useAcceptJobApplication({
    onComplete: () => window.location.reload(),
    onError: () =>
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersPage.actions.acceptApplication.error'),
      }),
  });

  const isLoading =
    archiveWorkerLoading ||
    resendWorkerInviteLoading ||
    removeWorkerFromCompanyLoading ||
    terminateWorkersLoading ||
    cancelApplicationLoading ||
    acceptApplicationLoading;

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box display="flex" flexDirection="row">
        {worker.poolStatus === POOL_STATUS.PENDING_OFFER && (
          <Stack spacing={2} direction="column" mb={2}>
            <WorkerActionsButton
              label={t('workersPage.actions.resendInvite.title')}
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.INFO}
              onClick={() => resendWorkerInvite({ variables: { workerId: worker.id } })}
            />
            <WorkerActionsButton
              label={t('workersPage.actions.removeWorkerFromCompany.title')}
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.ERROR}
              onClick={() => removeWorkerFromCompany({ variables: { workerId: worker.id } })}
            />
          </Stack>
        )}
        {worker.poolStatus === POOL_STATUS.NEW_APPLICATION && (
          <Stack spacing={2} direction="column" mb={2}>
            <WorkerActionsButton
              label={t('workersPage.actions.acceptApplication.title')}
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.INFO}
              onClick={() => applicationId && acceptApplication({ variables: { applicationId } })}
            />
            <WorkerActionsButton
              label={t('workersPage.actions.declineApplication.title')}
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.ERROR}
              onClick={() => applicationId && declineApplication({ variables: { applicationId } })}
            />
          </Stack>
        )}
        {worker.poolStatus === POOL_STATUS.ACTIVE && (
          <Stack spacing={2} direction="column" mb={2}>
            <WorkerActionsButton
              label={t('workersPage.actions.terminateWorker.title')}
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.ERROR}
              disabled={isLoading}
              onClick={() =>
                jobId && terminateWorker({ variables: { workerId: worker.id, jobId: jobId } })
              }
            />
          </Stack>
        )}
      </Box>
      {organizationType === ORG_TYPE.STUDENT_ORG && (
        <Box>
          <WorkerActionsButton
            label={t('workersPage.actions.archiveWorker.title')}
            variant={BUTTON_VARIANT.GRADIENT}
            color={BUTTON_COLOR.SECONDARY}
            onClick={() => archiveWorker({ variables: { workerId: worker.id } })}
          />
        </Box>
      )}
    </Box>
  );
};
