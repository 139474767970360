import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardMedia,
  Button,
} from '@mui/material';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useGetWorkerDocuments, useGetWorkerContracts } from 'src/api/hooks/useWorker';
import { Contract, Document, DocumentType } from 'src/DTO/Document.type';
import { useFontStyles } from 'src/utils/useFontStyles';
import { Documents } from '../Documents/Documents';
import { useMe } from 'src/api/hooks/useAuth';
import { ManagerProfile } from 'src/DTO/ManagerProfile.type';
import { ORG_TYPE } from 'src/DTO/OrganizationType.enum';
import { LocationState } from './types';
import { TYPOGRAPHY_COLORS } from '../Typography/types';
import { BUTTON_VARIANT } from '../Button/types';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';

export const WorkerDocuments: React.FC = () => {
  const { t } = useTranslation();

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const location = useLocation();
  const font = useFontStyles();

  const { workerId } = (location.state as LocationState) || {};

  const [managerProfile, setManagerProfile] = useState<ManagerProfile | undefined>(undefined);
  const [documents, setDocuments] = useState<Document[] | undefined>(undefined);
  const [contracts, setContracts] = useState<Contract[] | undefined>(undefined);

  const [getMe] = useMe({
    onComplete: res => {
      if (res && res.managerProfile) {
        setManagerProfile(res.managerProfile);
      }
    },
  });

  const [getDocuments, { loading: getDocumentsLoading }] = useGetWorkerDocuments({
    onComplete: res => {
      setDocuments(res?.documents || []);
    },
    onError: () =>
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workerProfile.documents.documents.error'),
      }),
  });

  const [getContracts, { loading: getContractsLoading }] = useGetWorkerContracts({
    onComplete: res => {
      if (!contracts || contracts.length === 0) {
        setContracts(res?.workerContracts || []);
      }
    },
    onError: () =>
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workerProfile.documents.contracts.error'),
      }),
  });

  useEffect(() => {
    getMe();
    getDocuments({
      variables: {
        id: workerId,
      },
    });
    getContracts({
      variables: {
        id: workerId,
      },
    });

    return () => {
      setContracts([]);
    };
  }, []);

  const companyOrgType = managerProfile?.companies?.[0]?.organizationType;

  const isLoading = getDocumentsLoading || getContractsLoading;

  return (
    <Grid container spacing={4} style={{ padding: '20px' }}>
      {isLoading && (
        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="secondary" />
        </Grid>
      )}

      {!isLoading && !contracts?.length && !documents?.length && (
        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          <Typography className={font.caption}>
            <Trans>workerDocumentsScreen.noContent</Trans>
          </Typography>
        </Grid>
      )}

      {contracts && companyOrgType === ORG_TYPE.STUDENT_ORG && contracts.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            <Trans>workerProfile.documents.contracts.title</Trans>
            {':'}
          </Typography>
          <Grid container spacing={2}>
            {contracts.map(contract => (
              <Grid item xs={12} sm={6} md={4} key={contract.url}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      {contract.title}
                    </Typography>
                    <Typography variant="body2" color={TYPOGRAPHY_COLORS.SECONDARY}>
                      <Trans>workerProfile.documents.generationDate</Trans>
                      {': '}
                      {moment(contract.createdAt).format('YYYY-MM-DD HH:mm')}
                    </Typography>
                    <Button
                      variant={BUTTON_VARIANT.CONTAINED}
                      color="primary"
                      href={contract.url}
                      target="_blank"
                      style={{ marginTop: 10 }}>
                      <Trans>workerProfile.documents.view</Trans>
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      {documents && documents.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            <Trans>workerProfile.documents.documents.title</Trans>
            {':'}
          </Typography>
          <Grid container spacing={2}>
            {documents.map((document, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card variant="outlined">
                  <CardMedia>
                    <Typography>
                      {document.type === DocumentType.StudentId ? (
                        <Trans>workerProfile.documents.studentId</Trans>
                      ) : document.type === DocumentType.StudentStatusVerification ? (
                        <Trans>workerProfile.documents.studentStatus</Trans>
                      ) : (
                        <Trans>workerProfile.documents.doctorsPaper</Trans>
                      )}
                    </Typography>
                    <Documents size={150} imageUrls={document.documentUrl} />
                  </CardMedia>
                  <CardContent>
                    <Typography variant="body2" color={TYPOGRAPHY_COLORS.SECONDARY}>
                      <Trans>workerProfile.documents.uploadDate</Trans>
                      {': '}
                      {moment(document.createdAt).format('YYYY-MM-DD HH:mm')}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
