import React from 'react';
import { forwardRef } from 'react';
import FormHelperText from '@mui/material/FormHelperText';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';

import InputRoot from './InputRoot';
import InputWithIconRoot from './InputWithIconRoot';
import InputIconBoxRoot from './InputIconBoxRoot';
import InputIconRoot from './InputIconBoxRoot';

import { UIController } from 'src/context';
import { InputProps, INPUT_SIZE } from './types';
import { TYPOGRAPHY_TRANSFORM, TYPOGRAPHY_WEIGHT } from '../Typography/types';

const Input = forwardRef(
  (
    {
      size = INPUT_SIZE.MEDIUM,
      icon = { component: false, direction: 'none' },
      error = false,
      success = false,
      disabled = false,
      inputLabel,
      helperText,
      ...rest
    }: InputProps,
    ref,
  ) => {
    let template;
    const [controller]: any = UIController();
    const { direction } = controller;
    const iconDirection = icon.direction;

    if (icon.component && icon.direction === 'left') {
      template = (
        <InputWithIconRoot ownerState={{ error, success, disabled }}>
          <InputIconBoxRoot ownerState={{ size }}>
            <InputIconRoot ownerState={{ size }}>{icon.component}</InputIconRoot>
          </InputIconBoxRoot>
          <InputRoot
            {...(rest as any)}
            ownerState={{ size, error, success, iconDirection, direction, disabled }}
          />
        </InputWithIconRoot>
      );
    } else if (icon.component && icon.direction === 'right') {
      template = (
        <InputWithIconRoot ownerState={{ error, success, disabled }}>
          <InputRoot
            {...(rest as any)}
            ownerState={{ size, error, success, iconDirection, direction, disabled }}
          />
          <InputIconBoxRoot ownerState={{ size }} sx={{ padding: 0 }}>
            <InputIconRoot ownerState={{ size }}>{icon.component}</InputIconRoot>
          </InputIconBoxRoot>
        </InputWithIconRoot>
      );
    } else {
      template = (
        <InputRoot {...(rest as any)} ref={ref} ownerState={{ size, error, success, disabled }} />
      );
    }

    if (!!inputLabel) {
      return (
        <Box mb={1}>
          <Box mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <Typography
              component="label"
              variant="caption"
              fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
              textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
              {inputLabel}
            </Typography>
          </Box>
          {template}
          {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
      );
    }

    return template;
  },
);

export { Input };
