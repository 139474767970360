import React, { forwardRef } from 'react';

import BadgeRoot from './BadgeRoot';
import { BadgeProps } from './types';

const Badge = forwardRef(
  (
    {
      color = 'info',
      variant = 'gradient',
      size = 'sm',
      circular = false,
      indicator = false,
      border = false,
      container = false,
      children,
      ...rest
    }: BadgeProps,
    ref,
  ) => (
    <BadgeRoot
      {...rest}
      ownerState={{ color, variant, size, circular, indicator, border, container, children }}
      ref={ref as any}
      color="default">
      {children}
    </BadgeRoot>
  ),
);

export { Badge };
