import React, { forwardRef } from 'react';
import { makeStyles } from '@mui/styles';

import AvatarRoot from './AvatarRoot';
import { AvatarProps, AVATAR_BG_COLOR, AVATAR_SHADOW, AVATAR_SIZE } from './types';

export const useStyles = makeStyles(() => ({
  img: {
    height: '100%!important',
  },
}));

const Avatar = forwardRef(
  (
    {
      bgColor = AVATAR_BG_COLOR.TRANSPARENT,
      size = AVATAR_SIZE.MD,
      shadow = AVATAR_SHADOW.NONE,
      ...rest
    }: AvatarProps,
    ref,
  ) => {
    const classes = useStyles();

    return (
      <AvatarRoot
        ref={ref as any}
        ownerState={{ shadow, bgColor, size }}
        {...rest}
        classes={{ img: classes.img }}
      />
    );
  },
);

export { Avatar };
