import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/Button';

import { useMe } from 'src/api/hooks/useAuth';
import { useStoreActions } from 'src/stores';
import { TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';

export const NotInvited: React.FC = () => {
  const history = useHistory();

  const updateUser = useStoreActions(actions => actions.user.update);

  const [getMe] = useMe({
    onComplete: res => {
      if (res && res.managerProfile) {
        const isInvited = !!res.invites.find(invite => invite.email === res.managerProfile.email);

        updateUser({
          ...res.managerProfile,
          isLogedIn: isInvited,
        });

        if (isInvited) {
          history.push('/');
        }
      }
    },
  });

  useEffect(() => {
    getMe();
  }, []);

  return (
    <Grid item xs={12}>
      <Box
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        pl={10}
        pr={10}>
        <Typography variant="h3" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
          <Trans>notInvitedScreen.helperText</Trans>
        </Typography>
        <Box mt={2} textAlign="center">
          <Typography variant="h5">
            <Trans>notInvitedScreen.description</Trans>
          </Typography>
        </Box>
        <Box mt={5}>
          <Button
            variant={BUTTON_VARIANT.GRADIENT}
            color={BUTTON_COLOR.INFO}
            onClick={() => history.push('/auth')}
            size={BUTTON_SIZE.LARGE}>
            <Trans>generic.backToLogin</Trans>
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};
