import React, { useEffect, useState } from 'react';

import { Input } from 'src/components/Input';

type WorkerHoursEditableCell = {
  value: string;
  index: number;
  updateCellValue: (index: number, value: string) => void;
  disabled?: boolean;
};

export const WorkerHoursEditableCell = ({
  value: initialValue,
  index,
  disabled,
  updateCellValue, // This is a custom function that we supplied to our table instance
}: // eslint-disable-next-line @typescript-eslint/no-unused-vars
WorkerHoursEditableCell) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateCellValue(index, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <Input value={value} onChange={onChange} onBlur={onBlur} disabled={disabled} />;
};
