export enum TYPOGRAPHY_COLORS {
  INHERIT = 'inherit',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  LIGHT = 'light',
  DARK = 'dark',
  TEXT = 'text',
  WHITE = 'white',
}

export enum TYPOGRAPHY_WEIGHT {
  LIGHT = 'light',
  REGULAR = 'regular',
  MEDIUM = 'medium',
  BOLD = 'bold',
}

export enum TYPOGRAPHY_TRANSFORM {
  NONE = 'none',
  CAPITALIZE = 'capitalize',
  UPPER_CASE = 'uppercase',
  LOWER_CASE = 'lowercase',
}

export enum TYPOGRAPHY_VERTICAL_ALIGN {
  UNSET = 'unset',
  BASELINE = 'baseline',
  SUB = 'sub',
  SUPER = 'super',
  TEXT_TOP = 'text-top',
  TEXT_BOTTOM = 'text-bottom',
  MIDDLE = 'middle',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export type TypographyProps = {
  color?: TYPOGRAPHY_COLORS;
  fontWeight?: boolean | TYPOGRAPHY_WEIGHT;
  textTransform?: TYPOGRAPHY_TRANSFORM;
  textGradient?: boolean;
  children?: any;
  opacity?: number;
  verticalAlign?: TYPOGRAPHY_VERTICAL_ALIGN;
  variant?: string;
  onClick?: () => void;
  sx?: any;
  component?: any;
  to?: string;
  dangerouslySetInnerHTML?: any;
  rel?: string;
  href?: string;
  target?: string;
  display?: string;
  pl?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  gutterBottom?: boolean;
  align?: string;
};
