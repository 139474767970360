import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from 'src/components/Typography';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { NewJobData } from 'src/api/hooks/useJob.types';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { validateEmail } from 'src/utils/validateEmail';
import { validatePhone } from 'src/utils/validatePhone';

export interface CreateJobStep4Props {
  handlePreviousButtonClick: () => void;
  handleNextButtonClick: (jobData: NewJobData) => void;
  newJobData: NewJobData;
}

export const CreateJobStep4: React.FC<CreateJobStep4Props> = ({
  handlePreviousButtonClick,
  handleNextButtonClick,
  newJobData,
}) => {
  const { t } = useTranslation();

  // LOCAL STATE
  const [contactName, setContactName] = useState<string>(newJobData?.contactName || '');
  const [contactPhone, setContactPhone] = useState<string>(newJobData?.contactPhone || '');
  const [contactEmail, setContactEmail] = useState<string>(newJobData?.contactEmail || '');

  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const handleContactName = (event: React.FormEvent<HTMLInputElement>) => {
    setContactName(event.currentTarget.value);
  };

  const handleContactPhone = (event: React.FormEvent<HTMLInputElement>) => {
    const onlyNumbers = event.currentTarget.value.replace(/[^0-9+]/g, '');

    const finalPhoneNumber = onlyNumbers.replace(
      /^(\+?)(.*)/,
      (_, plusSign, phoneNumber) => plusSign + phoneNumber.replace(/\+/g, ''),
    );

    setContactPhone(finalPhoneNumber);
    setPhoneError(!validatePhone(finalPhoneNumber));
  };

  const handleContactEmail = (event: React.FormEvent<HTMLInputElement>) => {
    setContactEmail(event.currentTarget.value);
    setEmailError(!validateEmail(event.currentTarget.value));
  };

  const isFormValid = (): boolean => {
    let isValid = true;

    if (contactEmail && !validateEmail(contactEmail)) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }

    if (contactPhone && !validatePhone(contactPhone)) {
      setPhoneError(true);
      isValid = false;
    } else {
      setPhoneError(false);
    }

    return isValid;
  };

  const onSubmit = () => {
    if (isFormValid()) {
      handleNextButtonClick({
        ...newJobData,
        contactName,
        contactPhone,
        contactEmail,
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box width="80%" textAlign="center" mx="auto" mb={4}>
          <Box mb={1}>
            <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
              <Trans>jobPage.createJobModal.contactPerson</Trans>
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mb={4}>
          <Input
            inputLabel={t('jobPage.createJobForm.contactPerson')}
            value={contactName}
            onChange={handleContactName}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mb={4}>
          <Input
            error={phoneError}
            inputLabel={t('jobPage.createJobForm.contactPhone')}
            value={contactPhone}
            onChange={handleContactPhone}
            helperText={phoneError ? t('validation.invalidPhone') : undefined}
            onBlur={isFormValid}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mb={4}>
          <Input
            error={emailError}
            inputLabel={t('jobPage.createJobForm.contactEmail')}
            value={contactEmail}
            onChange={handleContactEmail}
            helperText={emailError ? t('validation.invalidEmail') : undefined}
            onBlur={isFormValid}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box mr={3}>
            <Button onClick={handlePreviousButtonClick}>
              <Trans>generic.back</Trans>
            </Button>
          </Box>
          <Box ml={3}>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              type="submit"
              onClick={onSubmit}>
              <Trans>jobPage.createRoleForm.submitButtonLabel</Trans>
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
