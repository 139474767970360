import React from 'react';
import { Trans } from 'react-i18next';
import { DialogActions, DialogTitle, DialogContent, Dialog } from '@mui/material';

import { Button } from 'src/components/Button';
import { useStoreActions, useStoreState } from 'src/stores';
import { isFunction } from 'lodash';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from '../Button/types';

export const ConfirmDialog: React.FC = () => {
  const isOpen = useStoreState(actions => actions.context.getConfirmDialogOpenState);
  const dialogState = useStoreState(actions => actions.context.confirmDialogState);
  const close = useStoreActions(actions => actions.context.closeConfirmDialog) as () => void;

  const handleOnclose = () => {
    if (isFunction(dialogState.onCancel)) {
      dialogState.onCancel();
    }

    close();
  };

  return (
    <Dialog disableEscapeKeyDown aria-labelledby="confirmation-dialog-title" open={isOpen}>
      {dialogState.title && (
        <DialogTitle id="confirmation-dialog-title">{dialogState.title}</DialogTitle>
      )}
      {dialogState.body && <DialogContent>{dialogState.body}</DialogContent>}
      <DialogActions>
        <Button onClick={handleOnclose} size={BUTTON_SIZE.SMALL}>
          <Trans>generic.cancel</Trans>
        </Button>
        {dialogState.onConfirm && (
          <Button
            variant={BUTTON_VARIANT.GRADIENT}
            size={BUTTON_SIZE.SMALL}
            color={BUTTON_COLOR.INFO}
            onClick={() => {
              if (dialogState.onConfirm) {
                dialogState.onConfirm();
              }

              handleOnclose();
            }}>
            <Trans>generic.save</Trans>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
