import packageJson from '../../package.json';

export enum ENVIROMENT {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
  LOCALHOST = 'localhost',
}

export interface EnviromentUrlValues {
  [ENVIROMENT.PRODUCTION]: string;
  [ENVIROMENT.STAGING]: string;
  [ENVIROMENT.DEVELOPMENT]: string;
  [ENVIROMENT.LOCALHOST]: string;
}

export interface Config {
  url: EnviromentUrlValues;
  version: string;
}

export interface ConfigValue {
  url: string;
  version: string;
}

const config: Config = {
  url: {
    [ENVIROMENT.PRODUCTION]: 'https://backend.della.hu',
    [ENVIROMENT.STAGING]: 'https://backend.staging.della.hu',
    [ENVIROMENT.DEVELOPMENT]: 'https://backend.dev.della.hu',
    [ENVIROMENT.LOCALHOST]: 'http://localhost:4000',
  },
  version: packageJson.version || 'no-version-found',
};

export const getConfig = (): ConfigValue => {
  return {
    url: config.url[getEnviroment()],
    version: config.version,
  };
};

export const getEnviroment = (): ENVIROMENT =>
  (process.env.REACT_APP_STAGE as ENVIROMENT) || ENVIROMENT.LOCALHOST;
