import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Avatar } from 'src/components/Avatar';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import { ApplicationModalProps } from './CreateJobForm.types';
import { calcAge } from 'src/utils/calcAge';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { AVATAR_SIZE } from 'src/components/Avatar/types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';

export const ApplicationModal: React.FC<ApplicationModalProps> = ({
  isOpen,
  handleClose,
  worker,
  hashAttributes,
  handleOpenWorkerProfile,
  loading,
  handleAcceptApplication,
  handleCancelApplication,
  applicationId,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{`${worker.name} (${calcAge(new Date(worker.birthDay))})`}</DialogTitle>
        <DialogContent>
          <>
            {worker.interviewId && (
              <iframe
                width="400px"
                height="250px"
                allowFullScreen
                src={`https://embed.myinterview.com/player.v3.html?apiKey=xkqnejs448zhbgzqnuqljpm5&hashTimestamp=${hashAttributes?.timestamp}&hash=${hashAttributes?.hash}&video=${worker.interviewId}&fs=0`}></iframe>
            )}
            {!worker.interviewId && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                pl={4}
                pr={4}>
                {!worker.interviewId && (
                  <Avatar
                    size={AVATAR_SIZE.XXL}
                    alt={worker.name}
                    src={worker.imageUrls[worker.imageUrls.length - 1]}></Avatar>
                )}
              </Box>
            )}

            <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={2}>
              <Button
                variant={BUTTON_VARIANT.GRADIENT}
                color={BUTTON_COLOR.INFO}
                onClick={() => {
                  handleClose();
                  handleOpenWorkerProfile(worker);
                }}>
                {t('workerCard.profile')}
              </Button>
            </Box>

            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>profileScreen.userEmail</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {worker.email || t('generic.noData')}
              </Typography>
            </Box>

            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>profileScreen.userPhoneNumber</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {worker.phoneNumber || t('generic.noData')}
              </Typography>
            </Box>

            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>workerProfile.nationality</Trans>:
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {worker.nationality || t('generic.noData')}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between" mt={5} mb={5}>
              {loading && (
                <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                  <CircularProgress />
                </Box>
              )}

              {!loading && (
                <>
                  <Button
                    variant={BUTTON_VARIANT.CONTAINED}
                    color={BUTTON_COLOR.ERROR}
                    onClick={() => handleCancelApplication(applicationId)}>
                    <Trans>generic.decline</Trans>
                  </Button>
                  <Button
                    variant={BUTTON_VARIANT.CONTAINED}
                    color={BUTTON_COLOR.SUCCESS}
                    onClick={() => handleAcceptApplication(applicationId)}>
                    <Trans>generic.accept</Trans>
                  </Button>
                </>
              )}
            </Box>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            disabled={loading}>
            <Trans>generic.cancel</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
