import React from 'react';
import { StoreProvider } from 'easy-peasy';
import store from './stores';
import './i18next';
import { AppContext } from './AppContext';

function App() {
  return (
    <StoreProvider store={store}>
      <AppContext />
    </StoreProvider>
  );
}

export default App;
