import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  ListItemText,
  Icon,
  SelectChangeEvent,
} from '@mui/material';
import { Input } from 'src/components/Input';
import { LanguageRemovalProps } from './types';
import { LanguageKeys } from '../LanguageSelectorContext/types';
import { getSelectedLangKeys, getLangNames, names } from 'src/utils/getSelectedLanguages';

const LanguageRemoval: React.FC<LanguageRemovalProps> = ({
  selectedLanguages,
  setSelectedLanguages,
}) => {
  const selectedLangKeys = getSelectedLangKeys(selectedLanguages);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as LanguageKeys[];
    const newSelectedLanguages: Record<LanguageKeys, boolean> = { hu: false, en: false };

    if (value.length > 0) {
      newSelectedLanguages[value[0] as LanguageKeys] = true;
    }

    setSelectedLanguages(newSelectedLanguages);
  };

  return (
    <Box mb={5}>
      <FormControl variant="filled" sx={{ width: '100%' }}>
        <Select
          input={
            <Input
              icon={{
                component: <Icon>expand_more</Icon>,
                direction: 'right',
              }}
            />
          }
          onChange={handleChange}
          value={selectedLangKeys}
          multiple
          renderValue={selected => getLangNames(selected as LanguageKeys[]).join(', ')}>
          {Object.keys(selectedLanguages).map(language => (
            <MenuItem key={language} value={language} sx={{ marginBottom: 1 }}>
              <Checkbox checked={selectedLanguages[language as LanguageKeys]} />
              <ListItemText
                disableTypography={true}
                inset={true}
                primary={names[language as LanguageKeys]}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageRemoval;
