/* eslint-disable react/react-in-jsx-scope */
import { useContext } from 'react';
import { Box, Checkbox, FormControl, MenuItem, Select, ListItemText, Icon } from '@mui/material';
import { LangContext } from 'src/context/LanguageContext';
import { Input } from 'src/components/Input';
import { LanguageKeys, LanguageSelectorContextProps } from './types';
import { getSelectedLangKeys, getLangNames, names } from 'src/utils/getSelectedLanguages';
import { useTranslation } from 'react-i18next';

const LanguageSelectorContext: React.FC<LanguageSelectorContextProps> = ({ onClick }) => {
  const { selectedLanguages, onChange } = useContext(LangContext);
  const { t } = useTranslation();

  const selectedLangKeys = getSelectedLangKeys(selectedLanguages);

  return (
    <Box mb={5} onClick={onClick}>
      <FormControl variant="filled" sx={{ width: '100%' }}>
        <Select
          input={
            <Input
              inputLabel={t('jobPage.createJobForm.selectLanguage')}
              icon={{
                component: <Icon>expand_more</Icon>,
                direction: 'right',
              }}
            />
          }
          onChange={onChange}
          value={selectedLangKeys}
          multiple
          renderValue={selected => getLangNames(selected as LanguageKeys[]).join(', ')}>
          {Object.keys(selectedLanguages).map(language => (
            <MenuItem key={language} value={language} sx={{ marginBottom: 1 }}>
              <Checkbox checked={selectedLanguages[language as LanguageKeys]} />
              <ListItemText
                disableTypography={true}
                inset={true}
                primary={names[language as LanguageKeys]}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageSelectorContext;
