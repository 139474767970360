import { NotificationPayload, NotificationResponse } from './useNotifications.types';

import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';

export const useNotifications: BaseHookRequest<NotificationResponse[], NotificationPayload[]> =
  params => {
    const [{ getRequest }, state] = useApi<NotificationResponse[]>(params);
    const getNotifications: BaseRequest<NotificationResponse[]> = () =>
      getRequest<NotificationPayload>('/manager/notification/all');

    return [getNotifications, state];
  };
