import React, { useCallback, useState } from 'react';
import { Box, Grid, Modal, Typography } from '@mui/material';
import ImageViewer from 'react-simple-image-viewer';

import { DocumentProps } from './types';

export const Documents: React.FC<DocumentProps> = ({ imageUrls, size = 500 }) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Grid container>
      {imageUrls ? (
        imageUrls.map((imageUrl, index) => (
          <Grid style={{ margin: 10 }} key={`${index}_${Math.random()}`}>
            <Box
              component="img"
              src={imageUrl}
              width={size}
              height={size}
              onClick={() => openImageViewer(index)}
              alt="document_image"
              sx={{
                cursor: 'pointer',
                userSelect: 'none',
                transition: 'transform 0.4s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            />
          </Grid>
        ))
      ) : (
        <Grid style={{ margin: 10 }}>
          <Typography>workerProfile.documents.notAvadilable</Typography>
        </Grid>
      )}

      {isViewerOpen && (
        <Modal
          open={isViewerOpen}
          onClose={() => {
            setIsViewerOpen(false);
          }}>
          <Box sx={{ width: '100vh', height: '100vh' }}>
            <ImageViewer
              src={imageUrls || []}
              currentIndex={currentImage}
              disableScroll={false}
              backgroundStyle={{ position: 'absolute', width: '100vh', height: '100vh' }}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          </Box>
        </Modal>
      )}
    </Grid>
  );
};
