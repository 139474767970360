import * as Yup from 'yup';
import { TFunction } from 'i18next';

const PHONE_NUMBER_MIN_LENGTH = 6;

export const inviteWorkerSchema = (t: TFunction) =>
  Yup.object().shape({
    phoneNumber: Yup.string()
      .required(t('validation.required'))
      .min(PHONE_NUMBER_MIN_LENGTH, t('validation.minLength', { number: PHONE_NUMBER_MIN_LENGTH }))
      .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g, t('validation.invalidPhone')),
    name: Yup.string().required(t('validation.required')),
  });
